.resp-table{
    width:100%;
    overflow-x: auto;
    margin-bottom: 10px;
}

table {
    // &.tsb-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    thead {
        td {
            text-align: left;
            border: 0px;
            border-right: 1px solid #dbdfe9;
            border-bottom: 1px solid #6699cc;
            padding: 15px 10px;
            background: $cornflower;
            color: $white;
            font-weight: $font-weight--bold;
            @include respond-to(small){
                font-size:12px;
                word-wrap: break-word;
            }
            &:first-child {
                border-radius: 4px 0px 0px 0px;
            }
            &:last-child {
                border-radius: 0px 4px 0px 0px;
                border-right: 0px;
            }
        }
    }
    tbody {
        td {
            border: 1px solid #dbdfe9;
            padding: 10px;
            @include respond-to(small){
                font-size:14px;
                word-wrap: break-word;
            }
        }
        tr {
            &:nth-child(even) {
                background: #f8f9fb;
            }
        }
    }
    // }
}

.detail-table {
    @extend %clearfix;
    border-color: palette(grey, xx-light);
    border-width: 1px 0;
    border-style: solid;
    display: table;
    div {
        padding: 20px;
        display: table-cell;
        height: 100%;
    }
    &__rates {
        width: 40%;
        background: $white;
           border-color: palette(grey, xx-light);
    border-width: 0 1px;
    border-style: solid;
        table {
                margin-top: 10px;
            margin-bottom: 0;
            font-size: 14px;
            td {
                border: 0;
                padding: 5px 0;
                &:nth-child(even) {
                    color: palette(grey, x-dark);
                    white-space: nowrap;
                }
            }
            tr:nth-child(even) {
                background-color: transparent;
            }
        }
    }
    &__terms {
        width: 60%;
        li {
            margin-bottom: 10px;
        }
    }
}