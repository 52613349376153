.branch-finder-map--mobile {
    .facility {
        position: relative;
        box-shadow: none;
        border: 2px solid palette(orange);
        button {
            width: 50%;
        }
    }
}

.branch-finder-list--mobile {
    h4 {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
        line-height: 1;
    }
    h5 {
        margin-bottom: 15px;
        color: palette(grey);
        font-size: 16px;
    }
    h6 {
        display: none;
    }
    .mob-expand {
        //padding: 10px 20px !important;
        position:relative;

    }
    .branch-finder-list__address {
        td {
            line-height: 1.3;
            font-size: 16px;
            font-weight: 700 !important;
        }
    }
}

//mobile branch finder

@include breakpoint(1024) {
    .branch-finder-list__branch{
        position:relative;
        @include breakpoint(480){
            position:static;
            .branch-finder-list__address{
                position:relative;
            }
        }
    }
    .mobile-finder {
        .branch-finder-list-nav {
            display: none;
        }

        .branch-finder-listView {
            display: block !important;
        }

        .branch-finder-map {

            .facility {
                position: relative;
                margin: 0px;
                margin-bottom: 20px;
                box-shadow: none;
                border: 2px solid palette(orange);
                overflow: hidden;

                button {
                    width: 50%;
                    border-radius: 0px !important;
                    outline: none;
                    padding:15px;
                    padding-top:13px;
                    svg {
                        position: relative;
                        top: 5px;
                        left: auto;
                    }
                }
            }
        }
    }
    .hide-map{
        padding:10px 15px 10px 0px;
        color:palette(orange);
        font-size:14px;
        font-weight: $font-weight--bold;
        position:relative;
        &:after{
            font-family:FontAwesome;
            content:"\f0d7";
            position:absolute;
            right:0px;
        }
        &.toggletext{
            border-bottom:1px solid rgba($black, 0.2);
            &:after{
                content:"\f0d8";
            }
        }
    }
    .circle-marker{
        border-radius:50%;
        font-size:35px;
        height:50px; width:50px;
        text-align:center;
        i{
            position:relative;
            top: -11px;
            left: -5px;
        }
    }
    .marker{
        position:absolute;
        right:0px; top:-10px;
        @include breakpoint(480){
            top:auto;
            bottom:0px;
        }
        &:after{
            content:"On Map";
            display:block;
            font-size:12px;
            text-transform:uppercase;
            text-align:center;
            width:100%;
            font-weight:$font-weight--bold;
        }
    }
    .branch-finder-list__address{
        //position:relative;
        table{
            width:85%;
        }
    }
}

.slide_wrap .marker{
    &:after{
            display:none;
        }
}

