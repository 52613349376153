.fa-percent:before {
  content: "%";
  font-family: "Open Sans";
  font-weight: 900;
}

.ptsb-calc .currency:after,
.ptsb-calc .btn-group:after,
.ptsb-calc .chart__row:after {
  content: "";
  display: table;
  clear: both;
}

.ptsb-calc .currency--EUR:before,
.ptsb-calc .currency--STG:before,
.ptsb-calc .currency--PCT:before {
  background: #b3c3d3;
  border-radius: 3px 0 0 3px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  padding: 13px 0 0;
  position: relative;
  height: 55px;
  width: 20%;
  text-align: center;
}

.ptsb-calc .chart__bar--val-1,
.ptsb-calc .chart__bar--val-2,
.ptsb-calc .chart__bar--val-3,
.ptsb-calc .chart__bar--val-4,
.ptsb-calc .chart__bar--val-5,
.ptsb-calc .chart__bar--val-6,
.ptsb-calc .chart__bar--val-7,
.ptsb-calc .chart__bar--val-8,
.ptsb-calc .chart__bar--val-9,
.ptsb-calc .chart__bar--val-10,
.ptsb-calc .chart__bar--val-11,
.ptsb-calc .chart__bar--val-12,
.ptsb-calc .chart__bar--val-13,
.ptsb-calc .chart__bar--val-14,
.ptsb-calc .chart__bar--val-15,
.ptsb-calc .chart__bar--val-16,
.ptsb-calc .chart__bar--val-17,
.ptsb-calc .chart__bar--val-18,
.ptsb-calc .chart__bar--val-19,
.ptsb-calc .chart__bar--val-20,
.ptsb-calc .chart__bar--val-21,
.ptsb-calc .chart__bar--val-22,
.ptsb-calc .chart__bar--val-23,
.ptsb-calc .chart__bar--val-24,
.ptsb-calc .chart__bar--val-25,
.ptsb-calc .chart__bar--val-26,
.ptsb-calc .chart__bar--val-27,
.ptsb-calc .chart__bar--val-28,
.ptsb-calc .chart__bar--val-29,
.ptsb-calc .chart__bar--val-30,
.ptsb-calc .chart__bar--val-31,
.ptsb-calc .chart__bar--val-32,
.ptsb-calc .chart__bar--val-33,
.ptsb-calc .chart__bar--val-34,
.ptsb-calc .chart__bar--val-35,
.ptsb-calc .chart__bar--val-36,
.ptsb-calc .chart__bar--val-37,
.ptsb-calc .chart__bar--val-38,
.ptsb-calc .chart__bar--val-39,
.ptsb-calc .chart__bar--val-40,
.ptsb-calc .chart__bar--val-41,
.ptsb-calc .chart__bar--val-42,
.ptsb-calc .chart__bar--val-43,
.ptsb-calc .chart__bar--val-44,
.ptsb-calc .chart__bar--val-45,
.ptsb-calc .chart__bar--val-46,
.ptsb-calc .chart__bar--val-47,
.ptsb-calc .chart__bar--val-48,
.ptsb-calc .chart__bar--val-49,
.ptsb-calc .chart__bar--val-50,
.ptsb-calc .chart__bar--val-51,
.ptsb-calc .chart__bar--val-52,
.ptsb-calc .chart__bar--val-53,
.ptsb-calc .chart__bar--val-54,
.ptsb-calc .chart__bar--val-55,
.ptsb-calc .chart__bar--val-56,
.ptsb-calc .chart__bar--val-57,
.ptsb-calc .chart__bar--val-58,
.ptsb-calc .chart__bar--val-59,
.ptsb-calc .chart__bar--val-60,
.ptsb-calc .chart__bar--val-61,
.ptsb-calc .chart__bar--val-62,
.ptsb-calc .chart__bar--val-63,
.ptsb-calc .chart__bar--val-64,
.ptsb-calc .chart__bar--val-65,
.ptsb-calc .chart__bar--val-66,
.ptsb-calc .chart__bar--val-67,
.ptsb-calc .chart__bar--val-68,
.ptsb-calc .chart__bar--val-69,
.ptsb-calc .chart__bar--val-70,
.ptsb-calc .chart__bar--val-71,
.ptsb-calc .chart__bar--val-72,
.ptsb-calc .chart__bar--val-73,
.ptsb-calc .chart__bar--val-74,
.ptsb-calc .chart__bar--val-75,
.ptsb-calc .chart__bar--val-76,
.ptsb-calc .chart__bar--val-77,
.ptsb-calc .chart__bar--val-78,
.ptsb-calc .chart__bar--val-79,
.ptsb-calc .chart__bar--val-80,
.ptsb-calc .chart__bar--val-81,
.ptsb-calc .chart__bar--val-82,
.ptsb-calc .chart__bar--val-83,
.ptsb-calc .chart__bar--val-84,
.ptsb-calc .chart__bar--val-85,
.ptsb-calc .chart__bar--val-86,
.ptsb-calc .chart__bar--val-87,
.ptsb-calc .chart__bar--val-88,
.ptsb-calc .chart__bar--val-89,
.ptsb-calc .chart__bar--val-90,
.ptsb-calc .chart__bar--val-91,
.ptsb-calc .chart__bar--val-92,
.ptsb-calc .chart__bar--val-93,
.ptsb-calc .chart__bar--val-94,
.ptsb-calc .chart__bar--val-95,
.ptsb-calc .chart__bar--val-96,
.ptsb-calc .chart__bar--val-97,
.ptsb-calc .chart__bar--val-98,
.ptsb-calc .chart__bar--val-99,
.ptsb-calc .chart__bar--val-100 {
  min-height: 40px;
  background: #6699cc;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg5YjVlMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2NjlhY2MiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #89b5e2 0%, #669acc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #89b5e2), color-stop(100%, #669acc));
  background: -webkit-linear-gradient(top, #89b5e2 0%, #669acc 100%);
  background: -o-linear-gradient(top, #89b5e2 0%, #669acc 100%);
  background: -ms-linear-gradient(top, #89b5e2 0%, #669acc 100%);
  background: linear-gradient(to bottom, #89b5e2 0%, #669acc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#89b5e2', endColorstr='#669acc', GradientType=0);
}

.ptsb-calc {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  color: #6f6f6f;
  font-weight: 100;
}

.ptsb-calc h1,
.ptsb-calc h2,
.ptsb-calc h3,
.ptsb-calc h4,
.ptsb-calc h5,
.ptsb-calc h6 {
  font-family: "museo-sans-rounded", sans-serif;
  color: #0E2B69;
}

.ptsb-calc a {
  text-decoration: none;
  color: #0E2B69;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}

.ptsb-calc a:hover {
  color: #68a2da;
}

.ptsb-calc h1 {
  font-size: 2.4375em;
}

.ptsb-calc h2 {
  font-size: 2.125em;
  margin: 0;
}

.ptsb-calc h3 {
  font-size: 1.6875em;
  line-height: 1.65;
  margin: 0;
}

.ptsb-calc h4 {
  font-size: 1.4375em;
  margin: 0;
}

.ptsb-calc h5 {
  font-size: 1.1875em;
  margin: 0;
}

.ptsb-calc h6 {
  font-size: 1em;
  margin: 0;
}

.ptsb-calc p {
  font-size: 0.9375em;
  line-height: 1.5;
  margin: 0.5em 0 1em;
}

.ptsb-calc img {
  border: 0;
}

.ptsb-calc ul {
  list-style: none;
  padding-left: 0.625em;
  font-weight: 300;
}

.ptsb-calc ul li {
  padding-left: 0.9375em;
  position: relative;
  font-size: 0.875em;
  margin-bottom: 0.9375em;
}

.ptsb-calc ul li li:before {
  content: "";
  background-color: #e7ecf1;
  width: 6px;
  display: block;
  height: 6px;
  position: absolute;
  left: -8px;
  top: 10px;
  border-radius: 50%;
}

.ptsb-calc ul li:before {
  content: "";
  background-color: #EC7403;
  width: 8px;
  display: block;
  height: 8px;
  position: absolute;
  left: -10px;
  top: 6px;
  border-radius: 50%;
}

.ptsb-calc ul ul {
  margin: 5px 0 20px;
}

.ptsb-calc hr {
  border-color: transparent;
  background-color: #ffffff;
  height: 0px;
  display: block;
  margin: 20px 0;
  border-top: 0;
}

.ptsb-calc {
  border: 1px solid #c2d6eb;
  border-radius: 3px;
}

.ptsb-calc section {
  padding: 1.25em;
  position: relative;
  float: none;
}

.ptsb-calc section:last-of-type {
  border-bottom: 0;
}

.ptsb-calc__results {
  border-radius: 3px;
  text-align: center;
  margin: 10px 0;
}

.ptsb-calc__results p {
  margin: 0;
}

.ptsb-calc__results h2 {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  color: #fefefe;
}

.ptsb-calc__results h2 small {
  display: block;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
  font-weight: 500;
}

.ptsb-calc__results--white {
  background: #ffffff;
  padding: 0.9375em 0 0.625em;
}

.ptsb-calc__results--white h2,
.ptsb-calc__results--white h3,
.ptsb-calc__results--white h4 {
  color: #EC7403;
}

.ptsb-calc__calculate {
  background-color: #e7ecf1;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-image: url(/Calculators/img/calc-blue-strip.png);
  background-image: linear-gradient(bottom, #6699cc 50%, transparent 50%);
  background-image: -o-linear-gradient(bottom, #6699cc 50%, transparent 50%);
  background-image: -moz-linear-gradient(bottom, #6699cc 50%, transparent 50%);
  background-image: -webkit-linear-gradient(bottom, #6699cc 50%, transparent 50%);
  background-image: -ms-linear-gradient(bottom, #6699cc 50%, transparent 50%);
}

.ptsb-calc .terms {
  color: #0E2B69;
  font-weight: 700;
}

.ptsb-calc .terms p {
  font-size: 0.875em;
}

.ptsb-calc .bkg-col--orange {
  background-color: #EC7403;
}

.ptsb-calc .bkg-col--red {
  background-color: #dc3135;
}

.ptsb-calc .bkg-col--lt-blue {
  background-color: #89b6e2;
}

.ptsb-calc .bkg-col--dk-blue {
  background-color: #6699cc;
}

.ptsb-calc .bkg-col--deep-blue {
  background-color: #0E2B69;
}

.ptsb-calc .bkg-col--steel {
  background-color: #9ca4b6;
}

.ptsb-calc .bkg-col--gull {
  background-color: #e7ecf1;
}

.ptsb-calc .bkg-col--grey {
  background-color: #6f6f6f;
}

.ptsb-calc .bkg-col--mystic {
  background-color: #e7ecf1;
}

.ptsb-calc .bkg-col--blue-grey {
  background-color: #e7ecf1;
}

.ptsb-calc .bkg-col--white {
  background-color: #fefefe;
}

.ptsb-calc .bkg-col--black {
  background-color: #0b0b0b;
}

.ptsb-calc .bkg-col--dk-blue p {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__info p {
  text-align: left;
  font-size: 15px;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__info p a {
  color: #f3c392;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__info p a:hover {
  color: #fdb36c;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__info li {
  color: #ffffff;
  font-size: 15px;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__results--white p {
  color: #6f6f6f;
  padding: 0 10px;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__results--white p.txt--sml {
  font-size: 15px;
  margin: 10px 0;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__results--white p.txt--deep-blue {
  color: #0E2B69;
}

.ptsb-calc .bkg-col--dk-blue .ptsb-calc__results--white p.txt--orange {
  color: #EC7403;
}

.ptsb-calc .bkg-col--dk-blue label {
  color: #ffffff;
  display: block;
  margin: 5px 0;
}

.ptsb-calc .bkg-col--mystic hr {
  background-color: #cdd8e2;
}

.ptsb-calc * {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.ptsb-calc label {
  font-size: 0.875em;
}

.ptsb-calc label a {
  margin-top: 20px;
  border-bottom: 1px solid #85add6;
  color: #ffffff;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: block;
  font-weight: 300;
}

.ptsb-calc label a:hover {
  color: #ffffff;
}

.ptsb-calc label .toggle-btn {
  background: url("/Calculators/img/icon-down-arrow-white.svg") no-repeat 100% 8px transparent;
  background-size: 13px;
}

.ptsb-calc label .toggle-open {
  background: url("/Calculators/img/icon-up-arrow-white.svg") no-repeat 100% 8px transparent;
  background-size: 13px;
  border-bottom: none;
}

.ptsb-calc label.input-validation-error {
  color: #dc3135 !important;
}

.ptsb-calc input {
  border: 1px solid #c2d6eb;
  color: #6699cc;
  font-size: 1.125em;
  padding: 0.9375em 0 0.75em 0.9375em;
  background: #ffffff;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 10px;
  height: 55px;
}

.ptsb-calc input.input-validation-error {
  border-color: #dc3135 !important;
}

.ptsb-calc select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  width: 100%;
}

.ptsb-calc select.input-validation-error {
  border-color: #dc3135 !important;
}

.ptsb-calc .dropdown-leg {
  position: relative;
  display: block;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.ptsb-calc .dropdown-leg::after {
  content: ' ';
  position: absolute;
  right: 1px;
  top: 0;
  width: 40px;
  height: 40px;
  display: block;
  background: url("/Calculators/img/icon-down-arrow.svg") no-repeat 4px 17px #fff;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-down-arrow.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-down-arrow.svg', sizingMethod='scale')";
  background-size: 15px 9px;
  pointer-events: none;
  margin-top: 30px;
}

.ptsb-calc .dropdown-leg select {
  border: 1px solid #c2d6eb;
  background: white;
  border-radius: 3px;
  padding: 0.9375em 0 0.75em 0.9375em;
  width: 100%;
  color: #6699cc;
  font-size: 1.125em;
  cursor: pointer;
}

.ptsb-calc .dropdown-leg select::-ms-expand {
  display: none;
}

.ptsb-calc .dropdown-leg:-moz-any(select) {
  width: 110%;
}

.ptsb-calc .dropdown-leg.ie9 select {
  width: 110%;
}

.ptsb-calc .dropdown-leg select:focus {
  outline: 0;
}

.ptsb-calc .dropdown {
  height: 55px;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c2d6eb;
  background-color: #ffffff;
  position: relative;
  background-image: url("/Calculators/img/icon-down-arrow.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 95% center;
  cursor: pointer;
}

.ptsb-calc .dropdown select {
  padding: 17px 35px 17px 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;
  outline: none;
  width: 110%;
  color: #6699cc;
  font-size: 18px;
}

.ptsb-calc .dropdown select::-ms-expand {
  display: none;
}


/*.ptsb-calc .dropdown {
  position: relative;
  display: block;
  overflow: hidden;
  cursor: pointer;
  width: 100%; }
.ptsb-calc .dropdown::after {
  content: ' ';
  position: absolute;
  right: 2px;
  bottom: 7px;
  width: 40px;
  height: 40px;
  display: block;
  background: url("/Calculators/img/icon-down-arrow.svg") no-repeat 4px 17px #fff;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Calculators/img/icons/icon-down-arrow.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icons/icon-down-arrow.svg', sizingMethod='scale')";
  background-size: 15px 9px;
  pointer-events: none;
  margin-top: 30px; }
.ptsb-calc .dropdown select {
  border: 1px solid #c2d6eb;
  background: white;
  border-radius: 3px;
  padding: 14px 14px 11px;
  width: 100%;
  color: #6699cc;
  font-size: 18px;
  cursor: pointer;
  height: 55px; }
.ptsb-calc .dropdown select::-ms-expand {
  display: none; }
.ptsb-calc .dropdown:-moz-any(select) {
  width: 110%; }
.ptsb-calc .dropdown.ie9 select {
  width: 110%; }
.ptsb-calc .dropdown select:focus {
  outline: 0; }*/
.ptsb-calc__calculate {
  width: 100%;
  text-align: center;
}

.ptsb-calc__calculate a {
  display: inline-block;
}

.ptsb-calc__calculate a img {
  width: 65px;
  height: 65px;
  display: block;
  margin: 0 auto;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
}

.ptsb-calc__calculate a img:hover {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.ptsb-calc p {
  font-size: 18px;
  color: #0E2B69;
  line-height: 1.25;
  font-weight: 500;
  position: relative;
}

.ptsb-calc p.question {
  padding-right: 25px;
  background: url(/Calculators/img/icon-help.svg) no-repeat right center transparent;
  background-size: 18px;
  cursor: pointer;
}

.ptsb-calc .radio-btns,
.ptsb-calc .checkboxes {
  border: 1px solid transparent;
}

.ptsb-calc .radio-btns label,
.ptsb-calc .checkboxes label {
  color: #6f6f6f;
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0.75em;
  font-weight: 300;
  display: block;
  margin-left: 45px;
}

.ptsb-calc .currency--EUR:before {
  content: "\20AC";
  float: left;
}

.ptsb-calc .currency--EUR input {
  border-radius: 0 3px 3px 0;
  float: left;
  min-height: 46px;
  width: 80%;
}

.ptsb-calc .currency--STG:before {
  content: "\00a3";
  float: left;
}

.ptsb-calc .currency--STG input {
  border-radius: 0 3px 3px 0;
  float: left;
  min-height: 46px;
  width: 80%;
}

.ptsb-calc .currency--PCT:before {
  content: "%";
  font-family: 'open sans';
  float: left;
}

.ptsb-calc .currency--PCT input {
  border-radius: 0 3px 3px 0;
  float: left;
  min-height: 46px;
  width: 80%;
}

.ptsb-calc .input-split {
  margin-bottom: 10px;
  display: table;
  height: 100%;
}

.ptsb-calc .input-split--centered input {
  text-align: center;
  padding-left: 0;
}

.ptsb-calc .input-split .text {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 1.25;
  color: #0E2B69;
  font-weight: 500;
  padding-left: 10px;
}

.ptsb-calc .input-split>input {
  display: table-cell;
  margin-bottom: 0;
}

.ptsb-calc .input-split .currency {
  display: table-cell;
}

.ptsb-calc .input-split .currency--STG:before,
.ptsb-calc .input-split .currency--PCT:before,
.ptsb-calc .input-split .currency--EUR:before {
  width: 25%;
}

.ptsb-calc .input-split .currency--STG input,
.ptsb-calc .input-split .currency--PCT input,
.ptsb-calc .input-split .currency--EUR input {
  width: 75%;
  margin-bottom: 0;
}

.ptsb-calc .input-split--half>input,
.ptsb-calc .input-split--half .currency {
  width: 100%;
}

.ptsb-calc .input-split--half .text {
  width: 50%;
}

.ptsb-calc .input-split--three-qtr>input,
.ptsb-calc .input-split--three-qtr .currency {
  width: 65%;
}

.ptsb-calc .input-split--three-qtr .text {
  width: 35%;
}

.ie9 .ptsb-calc .dropdown {
  background-image: none;
}

.ptsb-calc .btn,
.ptsb-calc .btn-sml {
  border-bottom: 3px solid #8c8c8c;
  color: #ffffff;
  background-color: #d6d6d6;
  box-shadow: inset 0 0 0 0 #d6d6d6;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  text-align: left;
  -webkit-transition: all ease 0.8s;
  -moz-transition: all ease 0.8s;
  transition: all ease 0.8s;
  margin-right: 16px;
  font-family: "museo-sans-rounded", sans-serif;
  font-weight: 700;
}

.ptsb-calc .btn:hover,
.ptsb-calc .btn-sml:hover {
  color: #ffffff;
}

.ptsb-calc .btn:hover,
.ptsb-calc .btn-sml:hover {
  box-shadow: inset 0 -100px 0 0 #8c8c8c;
}

.ptsb-calc .btn:after,
.ptsb-calc .btn-sml:after {
  font-family: FontAwesome;
  float: right;
  content: "\f105";
  margin-left: 10px;
  margin-top: -1px;
}

.ptsb-calc .btn:last-child,
.ptsb-calc .btn-sml:last-child {
  margin-right: 0;
}

.ptsb-calc .btn.btn-raised,
.ptsb-calc .btn-sml.btn-raised {
  margin-bottom: 20px;
}

.ptsb-calc .btn .info,
.ptsb-calc .btn-sml .info {
  font-size: 0.8125em;
  font-weight: 100;
}

.ptsb-calc .btn {
  padding: 15px 20px 9px;
  font-size: 16px;
}

.ptsb-calc .btn.btn-ghost--frameless {
  padding-bottom: 12px;
  box-shadow: none;
}

.ptsb-calc .btn-right {
  float: right;
}

.ptsb-calc .btn-left {
  float: left;
}

.ptsb-calc .btn.btn-ghost--highlight {
  padding-top: 12px;
  padding-bottom: 11px;
}

.ptsb-calc .btn-sml {
  padding: 11px 18px 8px;
  font-size: 14px;
}

.ptsb-calc .btn-sml.btn-ghost--frameless {
  padding-bottom: 11px;
  box-shadow: none;
}

.ptsb-calc .btn-sml.btn-ghost--highlight {
  padding-top: 8px;
  padding-bottom: 10px;
}

.ptsb-calc .btn-form {
  padding: 0 10px;
  height: 2.1875em;
}

.ptsb-calc .btn-ghost {
  background: none;
  border: 2px solid #d6d6d6;
  color: #d6d6d6;
  box-shadow: inset 0 0 0 0 transparent;
  padding: 13px 20px 9px;
}

.ptsb-calc .btn-ghost--frameless {
  border-width: 0;
}

.ptsb-calc .btn-ghost:hover {
  color: #ffffff;
  box-shadow: inset 0 -100px 0 0 #d6d6d6;
}

.ptsb-calc .btn-nochev {
  text-align: center;
}

.ptsb-calc .btn-nochev:after {
  content: '';
}

.ptsb-calc .btn-revchev:after {
  float: left;
  content: "\f104";
  margin-right: 10px;
  margin-left: 0;
  margin-top: -1px;
}

.ptsb-calc .btn-half-width {
  width: 50%;
}

.ptsb-calc .btn-group {
  margin: 0.9375em 0;
}

.ptsb-calc .btn-orange {
  background-color: #EC7403;
  border-color: #b45a06;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #EC7403;
}

.ptsb-calc .btn-orange:hover {
  box-shadow: inset 0 -100px 0 0 #b45a06;
}

.ptsb-calc .btn-orange.btn-ghost {
  background: transparent;
  border-color: #EC7403;
  color: #EC7403;
}

.ptsb-calc .btn-orange.btn-ghost:hover {
  box-shadow: inset 0 -100px 0 0 #EC7403;
  color: #ffffff;
}

.ptsb-calc .btn-orange.btn-ghost.btn-sml {
  padding: 9px 18px 7px;
}

.ptsb-calc .btn-orange.btn-txt-link {
  background-color: transparent;
  border-color: transparent;
  color: #EC7403;
}

.ptsb-calc .btn-orange.btn-txt-link:hover {
  text-decoration: underline;
  box-shadow: none;
  border-color: transparent;
  color: #EC7403;
}

.ptsb-calc .btn-lt-blue {
  background-color: #89b6e2;
  border-color: #6699cc;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #89b6e2;
}

.ptsb-calc .btn-lt-blue:hover {
  box-shadow: inset 0 -100px 0 0 #6699cc;
}

.ptsb-calc .btn-lt-blue.btn-ghost {
  background: transparent;
  border-color: #89b6e2;
  color: #89b6e2;
}

.ptsb-calc .btn-lt-blue.btn-ghost:hover {
  box-shadow: inset 0 -100px 0 0 #89b6e2;
  color: #ffffff;
}

.ptsb-calc .btn-lt-blue.btn-ghost.btn-sml {
  padding: 9px 18px 7px;
}

.ptsb-calc .btn-lt-blue.btn-txt-link {
  background-color: transparent;
  border-color: transparent;
  color: #89b6e2;
}

.ptsb-calc .btn-lt-blue.btn-txt-link:hover {
  text-decoration: underline;
  box-shadow: none;
  border-color: transparent;
  color: #89b6e2;
}

.ptsb-calc .btn-dk-blue {
  background-color: #6699cc;
  border-color: #4e79a4;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #6699cc;
}

.ptsb-calc .btn-dk-blue:hover {
  box-shadow: inset 0 -100px 0 0 #4e79a4;
}

.ptsb-calc .btn-dk-blue.btn-ghost {
  background: transparent;
  border-color: #6699cc;
  color: #6699cc;
}

.ptsb-calc .btn-dk-blue.btn-ghost:hover {
  box-shadow: inset 0 -100px 0 0 #6699cc;
  color: #ffffff;
}

.ptsb-calc .btn-dk-blue.btn-ghost.btn-sml {
  padding: 9px 18px 7px;
}

.ptsb-calc .btn-dk-blue.btn-txt-link {
  background-color: transparent;
  border-color: transparent;
  color: #6699cc;
}

.ptsb-calc .btn-dk-blue.btn-txt-link:hover {
  text-decoration: underline;
  box-shadow: none;
  border-color: transparent;
  color: #6699cc;
}

.ptsb-calc .btn-deep-blue {
  background-color: #0E2B69;
  border-color: #091c45;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #0E2B69;
}

.ptsb-calc .btn-deep-blue:hover {
  box-shadow: inset 0 -100px 0 0 #091c45;
}

.ptsb-calc .btn-deep-blue.btn-ghost {
  background: transparent;
  border-color: #0E2B69;
  color: #0E2B69;
}

.ptsb-calc .btn-deep-blue.btn-ghost:hover {
  box-shadow: inset 0 -100px 0 0 #0E2B69;
  color: #ffffff;
}

.ptsb-calc .btn-deep-blue.btn-ghost.btn-sml {
  padding: 9px 18px 7px;
}

.ptsb-calc .btn-deep-blue.btn-txt-link {
  background-color: transparent;
  border-color: transparent;
  color: #0E2B69;
}

.ptsb-calc .btn-deep-blue.btn-txt-link:hover {
  text-decoration: underline;
  box-shadow: none;
  border-color: transparent;
  color: #0E2B69;
}

.ptsb-calc .btn-grey-blue {
  background-color: #e7ecf1;
  border-color: #737b8d;
  color: #ffffff;
  box-shadow: inset 0 0 0 0 #e7ecf1;
}

.ptsb-calc .btn-grey-blue:hover {
  box-shadow: inset 0 -100px 0 0 #737b8d;
}

.ptsb-calc .btn-grey-blue.btn-ghost {
  background: transparent;
  border-color: #e7ecf1;
  color: #e7ecf1;
}

.ptsb-calc .btn-grey-blue.btn-ghost:hover {
  box-shadow: inset 0 -100px 0 0 #e7ecf1;
  color: #ffffff;
}

.ptsb-calc .btn-grey-blue.btn-ghost.btn-sml {
  padding: 9px 18px 7px;
}

.ptsb-calc .btn-grey-blue.btn-txt-link {
  background-color: transparent;
  border-color: transparent;
  color: #e7ecf1;
}

.ptsb-calc .btn-grey-blue.btn-txt-link:hover {
  text-decoration: underline;
  box-shadow: none;
  border-color: transparent;
  color: #e7ecf1;
}

.ptsb-calc .btn-white {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #EC7403;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.ptsb-calc .btn-white:hover {
  box-shadow: inset 0 -100px 0 0 #cccccc;
}

.ptsb-calc .btn-white.btn-ghost {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
}

.ptsb-calc .btn-white.btn-ghost:hover {
  box-shadow: inset 0 -100px 0 0 #ffffff;
  color: #EC7403;
}

.ptsb-calc .btn-white.btn-ghost.btn-sml {
  padding: 9px 18px 7px;
}

.ptsb-calc .btn-white.btn-txt-link {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
}

.ptsb-calc .btn-white.btn-txt-link:hover {
  text-decoration: underline;
  box-shadow: none;
  border-color: transparent;
  color: #ffffff;
}

.ptsb-calc .load_btn img {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  background: transparent;
}

.ptsb-calc .load_btn img.loading {
  -moz-animation: rotating 0.7s linear infinite;
  -webkit-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes rotating {
  from {
    -moz-transform: rotate(360deg);
  }

  to {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes rotating {
  from {
    -moz-transform: rotate(360deg);
  }

  to {
    -moz-transform: rotate(0deg);
  }
}

.btn-stacked .btn,
.btn-stacked .btn-sml {
  display: block;
  margin-bottom: 1em;
}

.btn-stacked .btn:last-child,
.btn-stacked .btn-sml:last-child {
  margin-bottom: 0;
}

.btns-full-width .btn,
.btns-full-width .btn-sml {
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}

.btn.btn-yn {
  margin: 0 0 30px 0;
  width: 47%;
  border: 3px solid #89b6e2;
  text-align: center;
  color: #89b6e2;
  background: #ffffff;
}

.btn.btn-yn:after {
  content: "";
}

.btn.btn-yn:first-of-type {
  float: left;
}

.btn.btn-yn:last-of-type {
  float: right;
}

.btn.btn-yn:hover {
  box-shadow: inset 0 -100px 0 0 #89b6e2;
}

.btn.btn-yn.active {
  border-color: #EC7403;
  color: #EC7403;
  position: relative;
}

.btn.btn-yn.active:hover {
  box-shadow: inset 0 -100px 0 0 #EC7403;
  color: #ffffff;
}

.btn.btn-yn.active:hover:after {
  border-top-color: #EC7403;
}

.btn.btn-yn.active:after,
.btn.btn-yn.active:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.btn.btn-yn.active:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 12px;
  margin-left: -12px;
  -webkit-transition: border-color ease 0.8s;
  -moz-transition: border-color ease 0.8s;
  transition: border-color ease 0.8s;
}

.btn.btn-yn.active:before {
  border-color: rgba(236, 116, 3, 0);
  border-top-color: #EC7403;
  border-width: 16px;
  margin-left: -16px;
}

.ptsb-calc .chart {
  margin: 20px;
  font-size: 12px;
}

.ptsb-calc .chart__row {
  margin-bottom: 10px;
}

.ptsb-calc .chart__value {
  width: 33%;
  float: left;
}

.ptsb-calc .chart__value p {
  font-size: 12px;
  text-align: right;
  color: #EC7403 !important;
  line-height: 1.5;
  padding: 3px 0 0 0 !important;
  margin: 0;
}

.ptsb-calc .chart__value p span {
  display: block;
  color: #8e8e8e !important;
}

.ptsb-calc .chart__bar {
  width: 63%;
  float: right;
}

.ptsb-calc .chart__bar--val-1 {
  display: block;
  width: 1%;
}

.ptsb-calc .chart__bar--val-2 {
  display: block;
  width: 2%;
}

.ptsb-calc .chart__bar--val-3 {
  display: block;
  width: 3%;
}

.ptsb-calc .chart__bar--val-4 {
  display: block;
  width: 4%;
}

.ptsb-calc .chart__bar--val-5 {
  display: block;
  width: 5%;
}

.ptsb-calc .chart__bar--val-6 {
  display: block;
  width: 6%;
}

.ptsb-calc .chart__bar--val-7 {
  display: block;
  width: 7%;
}

.ptsb-calc .chart__bar--val-8 {
  display: block;
  width: 8%;
}

.ptsb-calc .chart__bar--val-9 {
  display: block;
  width: 9%;
}

.ptsb-calc .chart__bar--val-10 {
  display: block;
  width: 10%;
}

.ptsb-calc .chart__bar--val-11 {
  display: block;
  width: 11%;
}

.ptsb-calc .chart__bar--val-12 {
  display: block;
  width: 12%;
}

.ptsb-calc .chart__bar--val-13 {
  display: block;
  width: 13%;
}

.ptsb-calc .chart__bar--val-14 {
  display: block;
  width: 14%;
}

.ptsb-calc .chart__bar--val-15 {
  display: block;
  width: 15%;
}

.ptsb-calc .chart__bar--val-16 {
  display: block;
  width: 16%;
}

.ptsb-calc .chart__bar--val-17 {
  display: block;
  width: 17%;
}

.ptsb-calc .chart__bar--val-18 {
  display: block;
  width: 18%;
}

.ptsb-calc .chart__bar--val-19 {
  display: block;
  width: 19%;
}

.ptsb-calc .chart__bar--val-20 {
  display: block;
  width: 20%;
}

.ptsb-calc .chart__bar--val-21 {
  display: block;
  width: 21%;
}

.ptsb-calc .chart__bar--val-22 {
  display: block;
  width: 22%;
}

.ptsb-calc .chart__bar--val-23 {
  display: block;
  width: 23%;
}

.ptsb-calc .chart__bar--val-24 {
  display: block;
  width: 24%;
}

.ptsb-calc .chart__bar--val-25 {
  display: block;
  width: 25%;
}

.ptsb-calc .chart__bar--val-26 {
  display: block;
  width: 26%;
}

.ptsb-calc .chart__bar--val-27 {
  display: block;
  width: 27%;
}

.ptsb-calc .chart__bar--val-28 {
  display: block;
  width: 28%;
}

.ptsb-calc .chart__bar--val-29 {
  display: block;
  width: 29%;
}

.ptsb-calc .chart__bar--val-30 {
  display: block;
  width: 30%;
}

.ptsb-calc .chart__bar--val-31 {
  display: block;
  width: 31%;
}

.ptsb-calc .chart__bar--val-32 {
  display: block;
  width: 32%;
}

.ptsb-calc .chart__bar--val-33 {
  display: block;
  width: 33%;
}

.ptsb-calc .chart__bar--val-34 {
  display: block;
  width: 34%;
}

.ptsb-calc .chart__bar--val-35 {
  display: block;
  width: 35%;
}

.ptsb-calc .chart__bar--val-36 {
  display: block;
  width: 36%;
}

.ptsb-calc .chart__bar--val-37 {
  display: block;
  width: 37%;
}

.ptsb-calc .chart__bar--val-38 {
  display: block;
  width: 38%;
}

.ptsb-calc .chart__bar--val-39 {
  display: block;
  width: 39%;
}

.ptsb-calc .chart__bar--val-40 {
  display: block;
  width: 40%;
}

.ptsb-calc .chart__bar--val-41 {
  display: block;
  width: 41%;
}

.ptsb-calc .chart__bar--val-42 {
  display: block;
  width: 42%;
}

.ptsb-calc .chart__bar--val-43 {
  display: block;
  width: 43%;
}

.ptsb-calc .chart__bar--val-44 {
  display: block;
  width: 44%;
}

.ptsb-calc .chart__bar--val-45 {
  display: block;
  width: 45%;
}

.ptsb-calc .chart__bar--val-46 {
  display: block;
  width: 46%;
}

.ptsb-calc .chart__bar--val-47 {
  display: block;
  width: 47%;
}

.ptsb-calc .chart__bar--val-48 {
  display: block;
  width: 48%;
}

.ptsb-calc .chart__bar--val-49 {
  display: block;
  width: 49%;
}

.ptsb-calc .chart__bar--val-50 {
  display: block;
  width: 50%;
}

.ptsb-calc .chart__bar--val-51 {
  display: block;
  width: 51%;
}

.ptsb-calc .chart__bar--val-52 {
  display: block;
  width: 52%;
}

.ptsb-calc .chart__bar--val-53 {
  display: block;
  width: 53%;
}

.ptsb-calc .chart__bar--val-54 {
  display: block;
  width: 54%;
}

.ptsb-calc .chart__bar--val-55 {
  display: block;
  width: 55%;
}

.ptsb-calc .chart__bar--val-56 {
  display: block;
  width: 56%;
}

.ptsb-calc .chart__bar--val-57 {
  display: block;
  width: 57%;
}

.ptsb-calc .chart__bar--val-58 {
  display: block;
  width: 58%;
}

.ptsb-calc .chart__bar--val-59 {
  display: block;
  width: 59%;
}

.ptsb-calc .chart__bar--val-60 {
  display: block;
  width: 60%;
}

.ptsb-calc .chart__bar--val-61 {
  display: block;
  width: 61%;
}

.ptsb-calc .chart__bar--val-62 {
  display: block;
  width: 62%;
}

.ptsb-calc .chart__bar--val-63 {
  display: block;
  width: 63%;
}

.ptsb-calc .chart__bar--val-64 {
  display: block;
  width: 64%;
}

.ptsb-calc .chart__bar--val-65 {
  display: block;
  width: 65%;
}

.ptsb-calc .chart__bar--val-66 {
  display: block;
  width: 66%;
}

.ptsb-calc .chart__bar--val-67 {
  display: block;
  width: 67%;
}

.ptsb-calc .chart__bar--val-68 {
  display: block;
  width: 68%;
}

.ptsb-calc .chart__bar--val-69 {
  display: block;
  width: 69%;
}

.ptsb-calc .chart__bar--val-70 {
  display: block;
  width: 70%;
}

.ptsb-calc .chart__bar--val-71 {
  display: block;
  width: 71%;
}

.ptsb-calc .chart__bar--val-72 {
  display: block;
  width: 72%;
}

.ptsb-calc .chart__bar--val-73 {
  display: block;
  width: 73%;
}

.ptsb-calc .chart__bar--val-74 {
  display: block;
  width: 74%;
}

.ptsb-calc .chart__bar--val-75 {
  display: block;
  width: 75%;
}

.ptsb-calc .chart__bar--val-76 {
  display: block;
  width: 76%;
}

.ptsb-calc .chart__bar--val-77 {
  display: block;
  width: 77%;
}

.ptsb-calc .chart__bar--val-78 {
  display: block;
  width: 78%;
}

.ptsb-calc .chart__bar--val-79 {
  display: block;
  width: 79%;
}

.ptsb-calc .chart__bar--val-80 {
  display: block;
  width: 80%;
}

.ptsb-calc .chart__bar--val-81 {
  display: block;
  width: 81%;
}

.ptsb-calc .chart__bar--val-82 {
  display: block;
  width: 82%;
}

.ptsb-calc .chart__bar--val-83 {
  display: block;
  width: 83%;
}

.ptsb-calc .chart__bar--val-84 {
  display: block;
  width: 84%;
}

.ptsb-calc .chart__bar--val-85 {
  display: block;
  width: 85%;
}

.ptsb-calc .chart__bar--val-86 {
  display: block;
  width: 86%;
}

.ptsb-calc .chart__bar--val-87 {
  display: block;
  width: 87%;
}

.ptsb-calc .chart__bar--val-88 {
  display: block;
  width: 88%;
}

.ptsb-calc .chart__bar--val-89 {
  display: block;
  width: 89%;
}

.ptsb-calc .chart__bar--val-90 {
  display: block;
  width: 90%;
}

.ptsb-calc .chart__bar--val-91 {
  display: block;
  width: 91%;
}

.ptsb-calc .chart__bar--val-92 {
  display: block;
  width: 92%;
}

.ptsb-calc .chart__bar--val-93 {
  display: block;
  width: 93%;
}

.ptsb-calc .chart__bar--val-94 {
  display: block;
  width: 94%;
}

.ptsb-calc .chart__bar--val-95 {
  display: block;
  width: 95%;
}

.ptsb-calc .chart__bar--val-96 {
  display: block;
  width: 96%;
}

.ptsb-calc .chart__bar--val-97 {
  display: block;
  width: 97%;
}

.ptsb-calc .chart__bar--val-98 {
  display: block;
  width: 98%;
}

.ptsb-calc .chart__bar--val-99 {
  display: block;
  width: 99%;
}

.ptsb-calc .chart__bar--val-100 {
  display: block;
  width: 100%;
}


.ptsb-calc .ico--coins h2,
.ptsb-calc .ico--piggy h2,
.ptsb-calc .ico--house h2,
.ptsb-calc .ico--houseQuestion h2,
.ptsb-calc .ico--housePercent h2,
.ptsb-calc .ico--car h2,
.ptsb-calc .ico--shield h2,
.ptsb-calc .ico--handshake h2,
.ptsb-calc .ico--credit-card h2,
.ptsb-calc .ico--wallet h2,
.ptsb-calc .ico--coins h3,
.ptsb-calc .ico--piggy h3,
.ptsb-calc .ico--house h3,
.ptsb-calc .ico--houseQuestion h3,
.ptsb-calc .ico--housePercent h3,
.ptsb-calc .ico--car h3,
.ptsb-calc .ico--shield h3,
.ptsb-calc .ico--handshake h3,
.ptsb-calc .ico--credit-card h3,
.ptsb-calc .ico--wallet h3 {
  padding-left: 62px;
  line-height: 42px;
}

.ptsb-calc .ico--coins h4,
.ptsb-calc .ico--piggy h4,
.ptsb-calc .ico--house h4,
.ptsb-calc .ico--houseQuestion h4,
.ptsb-calc .ico--housePercent h4,
.ptsb-calc .ico--car h4,
.ptsb-calc .ico--shield h4,
.ptsb-calc .ico--handshake h4,
.ptsb-calc .ico--credit-card h4,
.ptsb-calc .ico--wallet h4 {
  padding-left: 75px;
  line-height: 1.25;
  background-size: 50px;
}


.ptsb-calc .ico--coins h2,
.ptsb-calc .ico--coins h3,
.ptsb-calc .ico--coins h4,
.ptsb-calc .ico--coins h5 {
  background: transparent url(/Calculators/img/icon-coins.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-coins.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-coins.svg', sizingMethod='scale')";
  background-size: 60px 58px;
  min-height: 55px;
}

.ptsb-calc .ico--piggy h2,
.ptsb-calc .ico--piggy h3,
.ptsb-calc .ico--piggy h4,
.ptsb-calc .ico--piggy h5 {
  background: transparent url(/Calculators/img/icon-piggy.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-piggy.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-piggy.svg', sizingMethod='scale')";
  background-size: 58px 58px;
  min-height: 55px;
  background-position-y: -3px;
}

.ptsb-calc .ico--house h2,
.ptsb-calc .ico--house h3,
.ptsb-calc .ico--house h4,
.ptsb-calc .ico--house h5 {
  background: transparent url(/Calculators/img/icon-house.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house.svg', sizingMethod='scale')";
  background-size: 60px 60px;
  min-height: 55px;
}

.ptsb-calc .ico--houseQuestion h2,
.ptsb-calc .ico--houseQuestion h3,
.ptsb-calc .ico--houseQuestion h4,
.ptsb-calc .ico--houseQuestion h5 {
  background: transparent url(/Calculators/img/icon-house-question.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/img/icon-house-question.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/img/icon-house-question.svg', sizingMethod='scale')";
  background-size: 250px 250px;
  min-height: 65px;
}

.ptsb-calc .ico--housePercent h2,
.ptsb-calc .ico--housePercent h3,
.ptsb-calc .ico--housePercent h4,
.ptsb-calc .ico--housePercent h5 {
  background: transparent url(/Calculators/img/icon-house-percent.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house-percent.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house-percent.svg', sizingMethod='scale')";
  background-size: 250px 250px;
  min-height: 65px;
}

.ptsb-calc .ico--car h2,
.ptsb-calc .ico--car h3,
.ptsb-calc .ico--car h4,
.ptsb-calc .ico--car h5 {
  background: transparent url(/Calculators/img/icon-car.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-car.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-car.svg', sizingMethod='scale')";
  background-size: 61px 28px;
  min-height: 55px;
  background-position-y: 9px;
}

.ptsb-calc .ico--shield h2,
.ptsb-calc .ico--shield h3,
.ptsb-calc .ico--shield h4,
.ptsb-calc .ico--shield h5 {
  background: transparent url(/Calculators/img/icon-shield.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-shield.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-shield.svg', sizingMethod='scale')";
  background-size: 44px 58px;
  min-height: 59px;
  background-position-x: 8px;
}

.ptsb-calc .ico--handshake h2,
.ptsb-calc .ico--handshake h3,
.ptsb-calc .ico--handshake h4,
.ptsb-calc .ico--handshake h5 {
  background: transparent url(/Calculators/img/icon-handshake.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-handshake.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-handshake.svg', sizingMethod='scale')";
  background-size: 60px 35px;
  background-position-y: 5px;
  min-height: 55px;
}

.ptsb-calc .ico--credit-card h2,
.ptsb-calc .ico--credit-card h3,
.ptsb-calc .ico--credit-card h4,
.ptsb-calc .ico--credit-card h5 {
  background: transparent url(/Calculators/img/icon-credit-card.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-credit-card.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-credit-card.svg', sizingMethod='scale')";
  background-size: 64px 64px;
  background-position-y: -7px;
  min-height: 55px;
}

.ptsb-calc .ico--wallet h2,
.ptsb-calc .ico--wallet h3,
.ptsb-calc .ico--wallet h4,
.ptsb-calc .ico--wallet h5 {
  background: transparent url(/Calculators/img/icon-wallet-orange.svg) no-repeat left top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-wallet-orange.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-wallet-orange.svg', sizingMethod='scale')";
  min-height: 55px;
  background-size: 80px 80px;
}

.ptsb-calc .flag--EUR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/EUR.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--GBP .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/GBP.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--USD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/USD.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--JPY .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/JPY.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--AUD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/AUD.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--TRY .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/TRY.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--CAD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/CAD.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--CZK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/CZK.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--HUF .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/HUF.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--HKD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/HKD.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--LVL .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/LVL.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--NZD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/NZD.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--NOK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/NOK.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--ZAR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/ZAR.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--SAR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/SAR.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--CHF .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/CHF.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc .flag--JPY .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/JPY.png) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list {
  padding: 0 10px;
}

.ptsb-calc ul.popular-currency-list li {
  padding-left: 50px;
  text-align: left;
  min-height: 30px;
}

.ptsb-calc ul.popular-currency-list li:not(:last-child) {
  border-bottom: 1px solid #e7ecf1;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.ptsb-calc ul.popular-currency-list li .code {
  color: #0E2B69;
  font-weight: 500;
}

.ptsb-calc ul.popular-currency-list li h4 {
  font-size: 18px;
  color: #6699cc;
}

.ptsb-calc ul.popular-currency-list li:before {
  display: none;
}

.ptsb-calc .flag--AED .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/AED.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.AED {
  background: url(/Calculators/img/flags/AED.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--AUD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/AUD.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.AUD {
  background: url(/Calculators/img/flags/AUD.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--BGN .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/BGN.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.BGN {
  background: url(/Calculators/img/flags/BGN.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--CAD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/CAD.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.CAD {
  background: url(/Calculators/img/flags/CAD.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--CHF .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/CHF.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.CHF {
  background: url(/Calculators/img/flags/CHF.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--CZK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/CZK.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.CZK {
  background: url(/Calculators/img/flags/CZK.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--DKK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/DKK.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.DKK {
  background: url(/Calculators/img/flags/DKK.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--EGP .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/EGP.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.EGP {
  background: url(/Calculators/img/flags/EGP.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--EUR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/EUR.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.EUR {
  background: url(/Calculators/img/flags/EUR.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--GBP .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/GBP.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.GBP {
  background: url(/Calculators/img/flags/GBP.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--HKD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/HKD.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.HKD {
  background: url(/Calculators/img/flags/HKD.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--HRK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/HRK.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.HRK {
  background: url(/Calculators/img/flags/HRK.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--HUF .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/HUF.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.HUF {
  background: url(/Calculators/img/flags/HUF.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--ILS .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/ILS.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.ILS {
  background: url(/Calculators/img/flags/ILS.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--JPY .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/JPY.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.JPY {
  background: url(/Calculators/img/flags/JPY.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--LVL .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/LVL.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.LVL {
  background: url(/Calculators/img/flags/LVL.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--MYR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/MYR.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.MYR {
  background: url(/Calculators/img/flags/MYR.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--NOK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/NOK.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.NOK {
  background: url(/Calculators/img/flags/NOK.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--NZD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/NZD.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.NZD {
  background: url(/Calculators/img/flags/NZD.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--PLN .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/PLN.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.PLN {
  background: url(/Calculators/img/flags/PLN.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--SAR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/SAR.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.SAR {
  background: url(/Calculators/img/flags/SAR.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--SEK .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/SEK.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.SEK {
  background: url(/Calculators/img/flags/SEK.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--SGD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/SGD.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.SGD {
  background: url(/Calculators/img/flags/SGD.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--THB .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/THB.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.THB {
  background: url(/Calculators/img/flags/THB.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--TRY .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/TRY.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.TRY {
  background: url(/Calculators/img/flags/TRY.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--USD .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/USD.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.USD {
  background: url(/Calculators/img/flags/USD.svg) no-repeat left top transparent;
  background-size: 40px;
}

.ptsb-calc .flag--ZAR .flag-icon {
  height: 40px;
  background: url(/Calculators/img/flags/ZAR.svg) no-repeat center top transparent;
  background-size: 50px;
}

.ptsb-calc ul.popular-currency-list li.ZAR {
  background: url(/Calculators/img/flags/ZAR.svg) no-repeat left top transparent;
  background-size: 40px;
}


p.question {
  position: relative;
}

p.question .tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
  left: auto;
  right: -14px;
  margin-bottom: 20px;
  bottom: 50%;
}

p.question .tooltip.instruct .tooltiphead {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

p.question .tooltip.instruct a {
  background-color: #EC7403;
  border-color: #B45A06;
  color: #FFF;
  border-radius: 4px;
  box-shadow: 1px 3px 0px #B45A06;
  display: block;
  text-align: center;
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 5px 0px;
}

p.question .tooltip.instruct p {
  margin-bottom: 5px;
}

p.question .tooltip.instruct.hidden {
  display: none;
}

p.question .tooltip.minMax {
  margin-left: auto;
  margin-right: auto;
  top: -150px;
  right: 0;
}

p.question .tooltip:after {
  right: 15px;
  bottom: -15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #ffffff;
  border-width: 8px;
  margin-top: -8px;
}

p.question.half {
  display: inline-block;
  width: 43%;
  margin-top: 14px;
  background-position-x: 51px;
}

p.question.half .tooltip {
  left: 0;
  bottom: 50%;
  margin-left: auto;
  margin-right: auto;
}

p.question.half .tooltip:after {
  left: 52px;
}




/*# sourceMappingURL=ptsb-calc.css.map */

.modal_bg {
  background: #000;
  opacity: 0.5;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.modal_tooltip {
  background: #FFF;
  padding: 20px;
  opacity: 1;
  position: fixed;
  left: 50%;
  top: 130px;
  z-index: 150;
  box-sizing: border-box;
}

.modal_tooltip p {
  font-size: 16px;
  line-height: 1.2;
  margin-top: 0;
}

.modal_tooltip .btn {
  width: 100%;
}

.tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 3px;
  position: absolute;
  bottom: 50%;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
}

.tooltip.instruct {
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  right: -13px;
}

.tooltip.instruct .tooltiphead {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.tooltip.instruct a,
.tooltip.instruct span a {
  background-color: transparent;
  border-color: transparent;
  color: #89b6e2;
  border-radius: 0;
  box-shadow: 0 0 0 transparent;
  display: inline;
  text-align: left;
  padding: 0;
  margin: 0;
}

.tooltip.instruct a.toolbutton {
  background-color: #EC7403;
  border-color: #B45A06;
  color: #FFF;
  border-radius: 4px;
  box-shadow: 1px 3px 0px #B45A06;
  display: block;
  text-align: center;
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 5px 0px;
}

.tooltip.instruct p {
  margin-bottom: 5px;
}

.tooltip.instruct.hidden {
  display: none;
}

.tooltip.minMax {
  margin-left: auto;
  margin-right: auto;
  top: -150px;
  right: 0;
}

.tooltip:after {
  right: 15px;
  bottom: -15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #ffffff;
  border-width: 8px;
  margin-top: -8px;
}

.ptsb-calc .slide_wrap {
  margin-top: 45px;
  position: relative;
  z-index: 0;
}

.ptsb-calc .slide_wrap:before,
.ptsb-calc .slide_wrap:after {
  color: #9ca4b6;
  font-size: 14px;
  width: 4px;
  height: 20px;
  position: absolute;
  bottom: -30px;
}

.ptsb-calc .slide_wrap .ui-slider-horizontal {
  height: 0.5em;
}

.ptsb-calc .slide_wrap .ui-widget-content {
  border: 0;
  background: #EC7403;
  z-index: 10;
  cursor: pointer;
}

.ptsb-calc .slide_wrap .ui-widget-header {
  background: #9ca4b6;
}

.ptsb-calc .slide_wrap .marker {
  width: 2px;
  height: 29px;
  content: "";
  background-color: #9ca4b6;
  position: absolute;
  top: -10px;
  z-index: 5;
  border-radius: 50;
}

.ptsb-calc .ui-state-default {
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 50px;
  background: #ffffff;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  margin-left: -12px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  border: 1px solid #CCC;
  top: -8px;
  cursor: pointer;
  outline: none;
}

.ptsb-calc .ui-state-hover,
.ptsb-calc .ui-state-active {
  background: #478dd2;
  border: 1px solid #CCC;
  outline: none;
}

.loan-calc.ptsb-calc {
  border: 0;
}

.loan-calc .star {
  color: #EC7403;
}

.loan-calc section {
  border: 1px solid #c2d6eb;
}

.loan-calc section:first-child {
  border-bottom: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.loan-calc section:last-of-type {
  border-top: 0;
  border: 1px solid #c2d6eb;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.loan-calc section.ptsb-calc__tabs {
  *zoom: 1;
  width: 100%;
  padding: 0;
  border: 0;
  position: relative;
  z-index: 0;
}

.loan-calc section.ptsb-calc__tabs:before,
.loan-calc section.ptsb-calc__tabs:after {
  content: " ";
  display: block !important;
}

.loan-calc section.ptsb-calc__tabs:after {
  clear: both;
}

.loan-calc .bkg-col--mystic.flash,
.loan-calc .ptsb-calc__calculate.flash,
.loan-calc .bkg-col--dk-blue.flash {
  border: 0;
  border-left: 1px solid #6f6f6f;
  border-right: 1px solid #6f6f6f;
}

.loan-calc .bkg-col--dk-blue.flash {
  border-bottom: 1px solid #6f6f6f;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.loan-calc .tab {
  width: 50%;
  height: 100px;
  display: inline-block;
  margin: 0;
  float: left;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  position: relative;
  z-index: 10;
}

.loan-calc .tab.inactive {
  background: #b3c3d3;
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.loan-calc .tab.inactive.tab-left {
  border-top: 1px solid rgba(111, 111, 111, 0.5);
  border-left: 1px solid rgba(111, 111, 111, 0.5);
  border-bottom: 1px solid #6f6f6f;
  border-right: 0;
}

.loan-calc .tab.inactive.tab-right {
  border-top: 1px solid rgba(111, 111, 111, 0.5);
  border-left: 0;
  border-bottom: 1px solid #6f6f6f;
  border-right: 1px solid rgba(111, 111, 111, 0.5);
}

.loan-calc .tab.tab-left {
  border-top: 1px solid #6f6f6f;
  border-right: 1px solid #6f6f6f;
  border-left: 1px solid #6f6f6f;
  border-bottom: 0;
}

.loan-calc .tab.tab-right {
  border-top: 1px solid #6f6f6f;
  border-right: 1px solid #6f6f6f;
  border-left: 1px solid #6f6f6f;
  border-bottom: 0;
}

.loan-calc .ptsb-calc__inputs .tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
  left: auto;
  right: -14px;
}

.loan-calc .ptsb-calc__inputs .tooltip.instruct .tooltiphead {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.loan-calc .ptsb-calc__inputs .tooltip.instruct a {
  background-color: #EC7403;
  border-color: #B45A06;
  color: #FFF;
  border-radius: 4px;
  box-shadow: 1px 3px 0px #B45A06;
  display: block;
  text-align: center;
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 5px 0px;
}

.loan-calc .ptsb-calc__inputs .tooltip.instruct p {
  margin-bottom: 5px;
}

.loan-calc .ptsb-calc__inputs .tooltip.instruct.hidden {
  display: none;
}

.loan-calc .ptsb-calc__inputs .tooltip.minMax {
  margin-left: auto;
  margin-right: auto;
  top: -190px;
  right: 0;
}

.loan-calc .ptsb-calc__inputs .tooltip:after {
  right: 15px;
  bottom: -15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #ffffff;
  border-width: 8px;
  margin-top: -8px;
}

.loan-calc .ptsb-calc__inputs.hidden {
  display: none;
}

.loan-calc .ptsb-calc__inputs.dropdown-leg:after {
  margin-top: 4px;
  top: 1px;
  bottom: 0px;
  right: 2px;
  width: 30px;
  background-position-x: 8px;
}

.loan-calc .ptsb-calc__inputs.input_pay,
.loan-calc .ptsb-calc__inputs.input_loan {
  position: relative;
}

.loan-calc input.noMargin {
  margin-bottom: 0px;
}

.loan-calc .input-split {
  width: 100%;
}

.loan-calc .input-split--half .text {
  padding-left: 0px;
}

.loan-calc .currency input.highlight {
  border-radius: 3px;
  border: 2px solid #EC7403;
}

.loan-calc p.question {
  position: relative;
}

.loan-calc p.question .tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
  left: auto;
  right: -14px;
}

.loan-calc p.question .tooltip.instruct .tooltiphead {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.loan-calc p.question .tooltip.instruct a {
  background-color: #EC7403;
  border-color: #B45A06;
  color: #FFF;
  border-radius: 4px;
  box-shadow: 1px 3px 0px #B45A06;
  display: block;
  text-align: center;
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 5px 0px;
}

.loan-calc p.question .tooltip.instruct p {
  margin-bottom: 5px;
}

.loan-calc p.question .tooltip.instruct.hidden {
  display: none;
}

.loan-calc p.question .tooltip.minMax {
  margin-left: auto;
  margin-right: auto;
  top: -150px;
  right: 0;
}

.loan-calc p.question .tooltip:after {
  right: 15px;
  bottom: -15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #ffffff;
  border-width: 8px;
  margin-top: -8px;
}

.loan-calc .ico--car h4 {
  padding-top: 13px;
}

.loan-calc .ico--piggy h4 {
  padding-top: 7px;
}

.loan-calc .ico--handshake h4 {
  padding-top: 10px;
}

.loan-calc .ico--shield h4 {
  padding-top: 12px;
}

.loan-calc #year_label {
  display: block;
  width: 100%;
}

.loan-calc p.question.half {
  display: inline-block;
  width: 43%;
  margin-top: 14px;
  background-position-x: 51px;
}

.loan-calc p.question.half .tooltip {
  left: 0;
  bottom: 50%;
  margin-left: auto;
  margin-right: auto;
}

.loan-calc p.question.half .tooltip:after {
  left: 52px;
}

.loan-calc span#year_wrap {
  width: 50%;
  display: inline-block;
  float: right;
}

.loan-calc span#year_wrap:after {
  width: 50%;
  background: #b3c3d3;
  border-radius: 0 3px 3px 0;
  color: #ffffff;
  font-size: 1.125em;
  font-weight: 500;
  position: relative;
  text-align: center;
  content: "years";
  float: right;
  height: 55px;
  line-height: 55px;
}

.loan-calc span#year_wrap input {
  width: 50%;
  border-radius: 3px 0 0 3px;
}

.loan-calc .slide_wrap {
  margin-top: 45px;
  position: relative;
}

.loan-calc .slide_wrap:before,
.loan-calc .slide_wrap:after {
  color: #9ca4b6;
  font-size: 14px;
  width: 4px;
  height: 20px;
  position: absolute;
  bottom: -30px;
}

.loan-calc .slide_wrap:before {
  content: "1";
  left: 1%;
  margin-left: -3px;
}

.loan-calc .slide_wrap:after {
  content: "5";
  right: 1%;
}

.loan-calc .slide_wrap .ui-slider-horizontal {
  height: 0.5em;
}

.loan-calc .slide_wrap .ui-widget-content {
  border: 0;
  background: #EC7403;
  z-index: 10;
  cursor: pointer;
}

.loan-calc .slide_wrap .ui-widget-header {
  background: #9ca4b6;
}

.loan-calc .slide_wrap .marker {
  width: 2px;
  height: 29px;
  content: "";
  background-color: #9ca4b6;
  position: absolute;
  top: -10px;
  z-index: 5;
  border-radius: 50;
}

.loan-calc .ui-state-default {
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 50px;
  background: #ffffff;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  margin-left: -12px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  border: 1px solid #CCC;
  top: -8px;
  cursor: pointer;
  outline: none;
}

.loan-calc .ui-state-hover,
.loan-calc .ui-state-active {
  background: #478dd2;
  border: 1px solid #CCC;
  outline: none;
}

.loan-calc .load_btn img.loading {
  -moz-animation: rotating 0.7s linear infinite;
  -webkit-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes rotating {
  from {
    -moz-transform: rotate(360deg);
  }

  to {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes rotating {
  from {
    -moz-transform: rotate(360deg);
  }

  to {
    -moz-transform: rotate(0deg);
  }
}

.loan-calc .bkg-col--dk-blue.flash {
  padding-top: 5px;
}

.loan-calc .ptsb-calc__results {
  margin-top: 0px;
}

.loan-calc .ptsb-calc__results.hidden {
  display: none;
}

.loan-calc .ptsb-calc__results h2 {
  display: inline-block;
  font-size: 2.625em;
  font-weight: 400;
  text-shadow: 2px 2px 2px #6f6f6f;
  margin: 0;
  padding-top: 10px;
}

.loan-calc .ptsb-calc__results #h2_sub {
  color: #ffffff;
  display: block;
  position: relative;
  margin: -8px 0 10px;
}

.loan-calc .ptsb-calc__results .switch {
  color: #ffffff;
  font-weight: 100;
  text-decoration: underline;
  cursor: pointer;
}

.loan-calc .ptsb-calc__results .switch:hover {
  color: #e7ecf1;
}

.loan-calc .ptsb-calc__results .switch span {
  position: relative;
}

.loan-calc .ptsb-calc__results .switch span:before {
  content: "";
  color: #FFF;
  height: 17px;
  width: 30px;
  background: transparent url(/Calculators/img/icon-pen.svg) no-repeat center center;
  position: absolute;
  left: -32px;
  top: 2px;
}

.loan-calc .btn-group {
  margin-top: 25px;
}

.loan-calc .alt_loan_links {
  *zoom: 1;
  width: 100%;
}

.loan-calc .alt_loan_links:before,
.loan-calc .alt_loan_links:after {
  content: " ";
  display: block !important;
}

.loan-calc .alt_loan_links:after {
  clear: both;
}

.loan-calc .alt_loan_links a {
  color: #FFFFFF;
  text-decoration: underline;
  font-weight: 100;
  padding: 0px 10px 10px 10px;
}

.loan-calc .alt_loan_links a.alt_left {
  float: left;
  padding-left: 1.5%;
  padding-right: 0;
}

.loan-calc .alt_loan_links a.alt_right {
  float: right;
  padding-right: 1.5%;
  padding-left: 0;
}

.loan-calc .alt_loan_links a:hover {
  color: #e7ecf1;
}


#ccard-calc .ptsb-calc__inputs .tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
  left: auto;
  right: -14px;
  cursor: initial;
}

#ccard-calc .ptsb-calc__inputs .tooltip.instruct .tooltiphead {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

#ccard-calc .ptsb-calc__inputs .tooltip.instruct a,
#ccard-calc .ptsb-calc__inputs .tooltip.instruct span a {
  background-color: transparent;
  border-color: transparent;
  color: #89b6e2;
  border-radius: 0;
  box-shadow: 0 0 0 transparent;
  display: inline;
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

#ccard-calc .ptsb-calc__inputs .tooltip.instruct a.toolbutton {
  background-color: #EC7403;
  border-color: #B45A06;
  color: #FFF;
  border-radius: 4px;
  box-shadow: 1px 3px 0px #B45A06;
  display: block;
  text-align: center;
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 5px 0px;
  cursor: pointer;
}

#ccard-calc .ptsb-calc__inputs .tooltip.instruct p {
  margin-bottom: 5px;
}

#ccard-calc .ptsb-calc__inputs .tooltip.instruct.hidden {
  display: none;
}

#ccard-calc .ptsb-calc__inputs .tooltip.minMax {
  margin-left: auto;
  margin-right: auto;
  top: -150px;
  right: 0;
}

#ccard-calc .ptsb-calc__inputs .tooltip:after {
  right: 15px;
  bottom: -15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #ffffff;
  border-width: 8px;
  margin-top: -8px;
}

#ccard-calc .star {
  color: #EC7403;
}

#ccard-calc .dropdown select.input-validation-error {
  border-color: #dc3135 !important;
}

#ccard-calc .dropdown:after {
  margin-top: 8px !important;
}

#ccard-calc .message.start {
  margin: 5px 0px 10px;
}

#ccard-calc #result_area {
  padding-top: 8px;
}

#ccard-calc .message.hide {
  display: none;
}

#ccard-calc .information.hide {
  display: none;
}

/*#data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
  left: auto;
  right: -14px;
  cursor: initial; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.instruct .tooltiphead {
    display: block;
    font-size: 18px;
    padding-bottom: 10px;
    padding-top: 10px; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.instruct a, #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.instruct span a {
    background-color: transparent;
    border-color: transparent;
    color: #89b6e2;
    border-radius: 0;
    box-shadow: 0 0 0 transparent;
    display: inline;
    text-align: left;
    padding: 0;
    margin: 0;
    cursor: pointer; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.instruct a.toolbutton {
    background-color: #EC7403;
    border-color: #B45A06;
    color: #FFF;
    border-radius: 4px;
    box-shadow: 1px 3px 0px #B45A06;
    display: block;
    text-align: center;
    padding: 10px 10px 10px 10px;
    margin: 10px 0px 5px 0px;
    cursor: pointer; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.instruct p {
    margin-bottom: 5px; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.instruct.hidden {
    display: none; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip.minMax {
    margin-left: auto;
    margin-right: auto;
    top: -150px;
    right: 0; }
  #data_calc.ptsb-calc .ptsb-calc__inputs p.question .tooltip:after {
    right: 15px;
    bottom: -15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #ffffff;
    border-width: 8px;
    margin-top: -8px; }*/

#data_calc.ptsb-calc .load_btn img.loading {
  -moz-animation: rotating 0.7s linear infinite;
  -webkit-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;
}

#mortgage_calc .dropdown-leg:after {
  margin-top: 10px;
}

#mortgage_calc .slide_wrap {
  margin-top: 20px;
}

#mortgage_calc .message.hide {
  display: none;
}

#mortgage_calc .information.hide {
  display: none;
}




#explore-calc .ptsb-calc__inputs {
  *zoom: 1;
  width: 100%;
}

#explore-calc .ptsb-calc__inputs:before,
#explore-calc .ptsb-calc__inputs:after {
  content: " ";
  display: block !important;
}

#explore-calc .ptsb-calc__inputs:after {
  clear: both;
}

#explore-calc .input-split {
  width: 26%;
  display: inline-block;
}

#explore-calc .dropdown {
  width: 70%;
  display: inline-block;
  float: right;
}

#explore-calc .input_section {
  float: left;
  *zoom: 1;
  width: 100%;
}

#explore-calc .input_section:before,
#explore-calc .input_section:after {
  content: " ";
  display: block !important;
}

#explore-calc .input_section:after {
  clear: both;
}

#explore-calc .input_section .partner_label {
  width: 50%;
  float: left;
}

#explore-calc .input_section .partner_label span {
  display: block;
}

#explore-calc .input_section label {
  width: 50%;
  float: right;
  display: block;
}

#explore-calc .input_section label span.currency:before {
  width: 40%;
  font-size: 1.75em;
  font-weight: 200;
}

#explore-calc .input_section label span.currency input {
  width: 60%;
}


#explore-calc .input-split--half {
  width: 48%;
}

#explore-calc .expandTopaz_btn {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  background-image: url("/Calculators/img/icon-down-arrow.svg");
  background-image: url("/Calculators/img/btnArrowDown.jpg");
  background-size: 42px;
  background-repeat: no-repeat;
  height: 36px;
  width: 42px;
  position: absolute;
  top: -6px;
  right: 0px;
  z-index: 10;
}

// HS-3015
#explore-calc .expandable__topaz .arrow_box {
  padding: 10px;
  width: calc(50% - 10px);
}

#explore-calc .expandable__topaz p {
  font-weight: 300;
}

.ptsb-calc .btn:after,
.ptsb-calc .btn-sml:after {
  margin: 0;
}

/*

REMOVED FOR THE NEW EFFECT ON THE BUTTONS
#explore-calc .boxes-topaz__container {
    text-align: center;
    height: 50px;
    margin: 30px 0;
}

#explore-calc .arrow_box {
    padding: 10px 29px;
    margin: 0 5px;
    background-color: white;
    font-weight: bold;
    color: #82B7E3;
    cursor: pointer;
    position: relative;
    background: #ffffff;
    border: 3px solid #82B7E3;
}

    #explore-calc .arrow_box.selected {
        border: 3px solid #F37300;
        color: #F37300;
    }

    #explore-calc .arrow_box:after, .arrow_box:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    #explore-calc .arrow_box:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 15px;
        margin-left: -15px;
    }

    #explore-calc .arrow_box:before {
        border-color: rgba(130, 183, 227, 0);
        border-top-color: #82B7E3;
        border-width: 20px;
        margin-left: -20px;
    }

    #explore-calc .arrow_box.selected:before {
        border-top-color: #F37300;
    }
    */
#explore-calc .expandable__topaz .input-split .currency--EUR:before {
  width: 40%;
  font-weight: 100;
  font-size: 25px;
}

#explore-calc .expandable__topaz .input-split .currency--EUR input {
  width: 60%;
}

p.subsection {
  font-weight: 300;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
