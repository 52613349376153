.related-searches {
    p {
        font-size: em(17px);
        margin-bottom: 0;
    }
    a {
        color: palette(dk-blue);
        font-weight: $font-weight--bold;
        text-decoration: underline;
        @include respond-to(small) {
            display: block;
        }
        &:hover {
            color: palette(dk-blue, x-dark);
        }
    }
}

.search-results {
    .box {
        border-color: #cfdce7;
        @include respond-to(small) { 
                     padding-bottom: 0;
                }
    }
    h4 {
        display: block;
        
    }
    &-repeat {
        padding: 3px;
        max-width: 250px;
        float: right;
        border: 1px solid #cfdce7;
        border-radius: 3px;
        @include respond-to(small) {
            max-width: none;
            width: 100%;
            margin-top: 20px;
        }
        input {
            background-color: transparent;
            &.btn {
                color: palette(orange);
                font-family: FontAwesome;
                background-color: transparent;
                width: 20%;
                text-align: center;
                 @include respond-to(small) { 
                     width: 15%;
                }
                &:hover {
                    box-shadow: inset 0 -100px 0 0 palette(orange);
                    background-color: palette(orange);
                    color: $white;
                }
            }
            &[type="text"] {
                background: transparent;
                border: none;
                width: 75%;
                margin-right: 0;
                   @include respond-to(small) { 
                     width: 80%;
                }
            }
        }
    }
    &-nav {
        display: table;
        width: 100%;
        margin: 0 0 em(37px) 0;
        padding: 0;
        border: 1px solid #cfdce7;
        border-radius: 5px;
        @include respond-to(small) {
            display: none;
        }
        li {
            font-size: 17px;
            display: table-cell;
            text-align: center;
            border-left: 1px solid #cfdce7;
            position: relative;
            &:first-child {
                border-left: none;
                a {
                    border-radius: 5px 0 0 5px;
                }
            }
            &:last-child a {
                border-radius: 0 5px 5px 0;
            }
            a {
                display: block;
                padding: 10px 0 9px;
                color: palette(deep-blue);
                .count {
                    font-size: em(14px);
                    color: palette(gull);
                }
            }
        }
        .current a {
            color: palette(orange);
            font-weight: $font-weight--bold;
            background: #fbfcfd; /* Old browsers */
            background: -moz-linear-gradient(top,  #fbfcfd 0%, #ecf0f4 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fbfcfd), color-stop(100%,#ecf0f4)); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  #fbfcfd 0%,#ecf0f4 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  #fbfcfd 0%,#ecf0f4 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  #fbfcfd 0%,#ecf0f4 100%); /* IE10+ */
            background: linear-gradient(to bottom,  #fbfcfd 0%,#ecf0f4 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfd', endColorstr='#ecf0f4',GradientType=0 ); /* IE6-9 */
            .count {
                font-weight: $font-weight--light;
            }
            &:after,
            &:before {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:after {
                border-color: rgba(236, 240, 244, 0);
                border-top-color: #ecf0f4;
                border-width: 16px 18px;
                margin-left: -18px;
            }
            &:before {
                border-color: rgba(207, 220, 231, 0);
                border-top-color: #cfdce7;
                border-width: 17px 20px;
                margin-left: -20px;
            }
        }
        &--half {
            width: 50%;
            @include respond-to(medium) {
                width: 100%;
            }
        }
    }
    .title {
        margin: -20px -20px 0 -20px;
        padding: 30px 40px;
        background-color: #fafbfd;
        border-bottom: 1px solid #cfdce7;
        color: palette(grey);
        font-weight: $font-weight--light;
        @include respond-to(small) {
            display: none;
        }
        &--mobile {
            margin: -20px -20px 0 -20px;
            padding: 15px 20px;
            font-weight: $font-weight--light;
            display: none;
            background: url('../img/icons/icon-right-arrow--lt-blue.svg') no-repeat 97% center #fafbfd;
            background-size: 11px;
            &.accordian--open {
                background-image: url('../img/icons/icon-up-arrow--lt-blue.svg');
                background-size: 20px;
                border-bottom: 1px solid #cfdce7;
            }
            @include respond-to(small) {
                display: block;
            }
            .count {
                display: block;
                color: palette(grey);
                font-size: 15px;
            }
        }
    }
    .keyword {
        font-weight: $font-weight--ultra-bold;
        color: palette(orange);
    }
    
    ol {
        margin-top: 40px;
        margin-bottom: 40px;
        @include respond-to(small) {
            display: none;
        }
        li {
            margin-bottom: 30px;
            font-size: 20px;
            color: palette(deep-blue);
            font-weight: $font-weight--bold;
            font-family: $font-family--secondary;
            a {
                color: palette(deep-blue);
                display: block;
            }
            p {
                font-size: 16px;
                font-weight: $font-weight--light;
                color: palette(grey);
            }
        }
    }
    &-mobile {
        @include respond-to(small) {
            display: table;
            margin-bottom: 20px;
            li {
                font-size: 15px;
            }
        }
    }
}