//blockquotes
blockquote,
.blockquote {
    border-left: 5px solid $mango-tango;
    padding: 10px 20px;
    font-family: $font-family--secondary;
    font-weight: $font-weight--bold;
    font-size: em(23px);
    margin: 40px;
}

.warning--grey {
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #6f6f6f;
    display: block;
    font-family: $font-family--primary;
    font-weight: $font-weight--bold;
    margin-bottom: 30px;
}

.box {
    .warning--grey {
        margin-bottom: 0px !important;
    }
}

.infographichomeblock {
    overflow: hidden !important;
}