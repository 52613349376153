
// Block: Product Option Block (The container)
.productoptionblock {
    padding-top: 65px;
    text-align: center;
    
    &--wrapper {
        margin: 0 auto;
        max-width: 1050px;
    }

    .buttons {
        padding-top: 65px;

        @include breakpoint(640) {
            .btn {
                display: block;
                margin: 0 0 10px 0;
            }
        }
    }

    @include breakpoint(767) {
        .btn { width: 100%; }
    }
}


// Block: Product Options Data Block
.productoptiondatablock {

    > div > .base { padding-bottom: 65px; }

    h3 {
        color: palette(grey);
        font-weight: 300;
        margin-bottom: 40px;
    }

    .orange {
        color: $mango-tango;
    }

    .colouredcontainerblock h3 { margin-bottom: 0; }
}


// Block: Product Option Data Item Block
// ---
// shares same styles as ctacalltoaction block: _cta.scss
.productoptiondataitemblock {
    
    .cta {
        &--image {
            align-items: center;
            display: flex !important;
            height: 200px !important;
            justify-content: center;
            padding: 30px;
            @include breakpoint(768) {
                padding: 15px;
            }

            h4 {
                color: $white;
                &:before {
                    display: block;
                    margin: 0 auto 15px auto;
                    height: 60px;
                    width: 60px;
                }
            }
        }

        &--content {
            text-align: left;
        }
    }

    @media all and (min-width: 768px) {
        &.col-4 {
            width: calc(33.333333% - 20px);
        }
    }

    @include breakpoint(767) {
        &.col-4 {
            margin: 0 0 20px 0 !important;
        }
    }

    &.slick-slide {
        margin: 0 10px !important;
    }

}


.bold-span {
    color: $color-deep;
    font-size: 1.6875em;
    font-weight: 700;
    padding-right: 10px;
}

#ddl {
    appearance: none;
    background-image: url('../img/dropdown-arrow.png');
    background-position: 93% center;
    background-repeat: no-repeat;
    background-color: #F9FAFC;
    border: 1px solid #CFDDE8;
    border-radius: 3px;
    font-size: 22px;
    font-weight: 800;
    height: 52px;
    line-height: 52px;
    margin-bottom: 60px;
    max-width: 100%;
    padding: 0 50px 0 15px;
    @media all and (min-width: 640px) { min-width: 300px; }
}