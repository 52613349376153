.intro-text {
    h1 {
        margin-top: 0;
        line-height: 1.25;
    }
    p {
        font-size: em(17px);
        margin-top: em(8px);
        margin-bottom: em(32px);
        .box & {
            margin-bottom: em(20px);
        }
        @include respond-to(large) {
            margin-left: auto;
            margin-right: auto;
            text-align: left;
        }
        &.t-and-c {
            max-width: 100% !important;
            font-size: 12px !important;
            margin: 10px 0 5px;
        }
    }

    &.text-center p {
        text-align: center !important;
    }
}

