
// Block: Full Width Banner Block
.fw-banner {
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    height: 440px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;

    @include breakpoint(767) {
        height: 280px;
        padding: 15px 0;

        &::before {
            content: '';
            background: linear-gradient(transparent, rgba(0,0,0,.65));
            bottom: 0;
            height: 100px;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;  
        }
        &::after {
            content: '';
            background: transparent;
            border-radius: 50%;
            bottom: 15px;
            // box-shadow: 0 0 90px 70px rgba(0,0,0,.75);
            height: 10px;
            left: initial;
            position: absolute;
            right: -70px;
            width: 70px;
            z-index: 1;
        }
    }

    .base,
    .row {
        height: 100%;
    }

    // Banner content
    .banner {
        &--content {
            display: table;
            height: 100%;
            margin: 0 auto;
            max-width: 880px;
            width: 100%;

            // Banner content inner
            &--inner {
                display: table-cell;
                padding: 0 10px;
                vertical-align: middle;
            }
        }

        // Banner icons
        &--icons {
            display: table;
            margin-top: 20px;
            padding-top: 85px;
            width: 100%;
            @media all and (min-width: 768px) {
                border-spacing: 15px;
            }

            @include breakpoint(767) {
                display: none;
            }

            // Banner icon
            &__icon {
                display: table-cell;
                max-width: 0;
                padding: 0 15px;
                position: relative;

                h5 {
                    font-size: 18px;
                    line-height: 1.5;
                    position: relative;
                    @include breakpoint(480) {
                        font-size: 14px;
                    }
                    &::before {
                        height: 64px;
                        left: calc(50% - 32px);
                        position: absolute;
                        top: -75px;
                        width: 64px;
                    }
                }
            }
            &.with-underline {
                .banner--icons__icon h5::after {
                    content: '';
                    background-color: $mango-tango;
                    border-radius: 30px;
                    bottom: -25px;
                    display: block;
                    height: 7px;
                    left: calc(50% - 35px);
                    position: absolute;
                    width: 70px;
                }
            }
            p { font-weight: 400; }
        }
    }

    h1, h5 {
        color: $white;
    }

    h1 {
        font-size: em(54px);
        margin: -10px 0 10px 0;
        @include breakpoint(480) {
            font-size: 36px;
        }
    }

    h5 {
        font-size: em(22px);
        font-weight: 300;
        
        @include breakpoint(480) {
            font-size: 18px;
        }

        p { 
            margin: 0;
            // position: relative;
            // top: -5px;
        }
    }

}

@media all and (min-width: 768px) {
    .mobile-banner {
        display: none;
    }
}

@include breakpoint(767) {
    .desktop-banner {
        display: none;
    }
}
