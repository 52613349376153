// =================================
// Helpers
// =================================
// Clearfix
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Scren Reader text
.srt {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// 
%pseudos {
    display: block;
    content: '';
    position: absolute;
}

// Input styles 
%input {
    background: palette(grey, xx-light);
    border: 1px solid palette(grey, x-light);
    @include border-radius(3px);
    margin-right: 10px;
    height: em(35px);
}

%padlock-ico {
    display: inline-block;
    width: 14px;
    height: 18px;
    background: url('../img/icons/padlock.png')no-repeat center center transparent;
    margin-bottom: -3px;
    margin-right: 8px;
}

//Currency Input Box
%currency {
    background: palette(gull);
    border-radius: 3px 0 0 3px;
    color: $white;
    font-size: em(20px);
    font-weight: $font-weight--medium;
    padding: 9px 10px 7px;
    position: relative;
    min-height: 46px;
}

// Text Alignment
.text {
    &-center {
        text-align: center;
        h5 {
            display: inline-block;
        }
    }
    &-right {
        text-align: right;
    }
    &-left {
        text-align: left;
    }
}

// Restrict paragraph widths
.trim {
    &--three-qtr {
        p,
        h1 {
            max-width: 75%;
            @include respond-to (large) {
                max-width: 100%;
            }
        }
        &.text-center p {
            margin-right: auto;
            margin-left: auto;
        }
    }
    &--two-thirds {
        p,
        h1 {
            max-width: 65%;
            @include respond-to (large) {
                max-width: 100%;
            }
        }
        &.text-center p {
            margin-right: auto;
            margin-left: auto;
        }
    }
    &--half {
        p,
        h1 {
            max-width: 50%;
            @include respond-to (large) {
                max-width: 100%;
            }
        }
        &.text-center p {
            margin-right: auto;
            margin-left: auto;
        }
    }
    &--one-qtr {
        p,
        h1 {
            max-width: 25%;
            @include respond-to (large) {
                max-width: 100%;
            }
        }
        &.text-center p {
            margin: 0 auto;
        }
    }
}

// Uniform Padding
.padding {
    &__vert {
        padding-top: em(20px);
        padding-bottom: em(20px);
        &--x2 {
            padding-top: em(40px);
            padding-bottom: em(40px);
            @include respond-to(small) {
                padding-top: em(20px);
                padding-bottom: em(20px);
            }
        }
        &--half {
            padding-top: em(10px);
            padding-bottom: em(10px);
        }
    }
    &__horiz {
        padding-left: em(10px);
        padding-right: em(10px);
        &--x2 {
            padding-left: em(20px);
            padding-right: em(20px);
            @include respond-to(small) {
                padding-left: em(10px);
                padding-right: em(10px);
            }
        }
    }
    &__both {
        padding: em(20px) em(10px);
        &--x2 {
            padding: em(40px) em(20px);
            @include respond-to(small) {
                padding: em(20px) em(10px);
            }
        }
    }
    &__bottom {
        padding-bottom: em(20px);
        &--x2 {
            padding-bottom: em(40px);
            @include respond-to(small) {
                padding-bottom: em(20px);
            }
        }
    }
    &__top {
        padding-top: em(20px);
        &--half {
            padding-top: em(8px);
        }
        &--x2 {
            padding-top: em(40px);
            @include respond-to(small) {
                padding-top: em(20px);
            }
        }
    }
    &__aside {
        padding-right: em(30px)!important;
        @include respond-to(medium) {
            padding-right: 10px !important;
        }
    }
}

.narrow-cols {
    .col-6 {
        padding-left: em(42px);
        padding-right: em(42px);
        &:nth-child(odd) {
            padding-left: 10px;
        }
        &:nth-child(even) {
            padding-right: 10px;
        }
        @include respond-to(large) {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }
}

// Responsive Images 
.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Text Colours
.txt {
    &--gull {
        color: palette(gull, light);
    }
    &--pub-date {
        color: palette(orange);
        margin-bottom: 0;
    }
    &--light {
        font-weight: $font-weight--light;
    }
    &--tiny {
        font-size: 0.7em;
    }
    &--orange {
        color: palette(orange);
    }
    &--lt-blue {
        color: palette(lt-blue);
    }
    &--dk-blue {
        color: palette(dk-blue);
    }
    &--deep-blue {
        color: palette(deep-blue);
    }
    &--green {
        color: palette(green);
    }
    &--red {
        color: palette(red);
    }
    &--black {
        color: $black;
    }
}

// Multi column lists 
.list-2 li {
    float: left;
    width: 50%;
    padding-right: 15px;
    @include respond-to(x-small) {
        float: none;
        width: 100%;
    }
}

.list-3 li {
    float: left;
    width: 33.3333%;
}

//Orange Bullet pointed lists
%orange-bullets {
    ul {
        list-style: none;
        padding-left: 20px;
        @extend %clearfix;
        li:not(.positive--list):not(.negative--list) {
            padding-left: 0.5em; //text-indent: -0.5em;
            position: relative;
            li:before {
                content: "";
                background-color: palette(gull);
                width: 6px;
                display: block;
                height: 6px;
                position: absolute;
                left: -8px;
                top: 8px;
                border-radius: 50%;
            }
            &:before {
                content: "";
                background-color: palette(orange);
                width: 8px;
                display: block;
                height: 8px;
                position: absolute;
                left: -10px;
                top: 7px;
                border-radius: 50%;
            }
        }
        ul {
            margin: 5px 0 5px;
        }
        ol {
            list-style-type: decimal;
            li {
                &:before {
                    display: none;
                }
            }
        }
    }
    &.re-direct ul {
        margin: 0;
        padding: 0;
        li {
            padding: 20px 10px 15px;
            margin: 0;
            &:not(:last-child) {
                border-bottom: 1px solid palette(grey, xx-light);
            }
            &:before {
                display: none !important;
            }
            p {
                margin-bottom: 10px;
            }
        }
    }
}

.tiny-bullets li {
    font-size: 15px;
}

// Watermark for lower pages 
.watermarked .base {
    background: url("../img/PTSB-watermark.png") no-repeat right bottom #fff;
}

// Loading GIF 
.loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2;
    display: none;
    &-gif {
        position: fixed;
        left: 50%;
        top: 50%;
        background: $white;
        border-radius: 3px;
        padding: 20px 20px 15px 20px;
        margin-left: -52px;
        margin-top: -56px;
    }
}

.hide {
    &-xs {
        @include respond-to(x-small) {
            display: none;
        }
    }
    &-s {
        @include respond-to(small) {
            display: none;
        }
    }
    &-m {
        @include respond-to(medium) {
            display: none;
        }
    }
}

.show {
    &-desktop {
        display: block;
        @include respond-to(large) {
            display: none;
        }
    }
    &-mobile {
        display: none;
        @include respond-to(large) {
            display: block;
        }
    }
}

.flex {
    display: flex;
    flex-flow: row wrap;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        justify-content: center;
        .col-3 {
            &.ctablockwithimage {
                width: calc(25% - 21px);
            }
            &.applicationstepblock {
                width: calc(25% - 31px);
            }
        }
        .col-12 {
            &.ctablockwithimage {
                margin-left: 0;
            }
        }
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        justify-content: center;
        .col-3 {
            &.ctablockwithimage {
                width: calc(50% - 21px);
            }
            &.applicationstepblock {
                width: calc(50% - 21px);
            }
        }
        .col-12 {
            &.ctablockwithimage {
                margin-left: 0;
            }
        }
    }
}

.engage-slider>div {
    @extend .flex;
}

.color-primary { color: $mango-tango; }