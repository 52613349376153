.btt {
    width: 100%;
    position: relative;
    padding:30px 0 0;
    text-align: center;
    display: none;
    @include respond-to(small) {
        display: block;
    }
    .btn-sml {
        padding-right: 40px;
        &:hover {
            box-shadow: none!important;
        }
        &:after {
            content: "\f106";
            position: absolute;
            right:15px;
        }
    }
}

footer {
    /*&:before{
        content:"";
        @include sprite($ftr-bkg);
        top:0px; left:0px;
        background-repeat:repeat-x;
        position:absolute;
        z-index:1;
        width:100%;
        
    }*/
    position: relative;
    background-color:#0c2458;
    @include lgradient(180, #103075, #0c2458);
    //background: url('img/ftr-bkg.gif') repeat-x top left ;
    color: $white;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
    h5 {
        font-weight: 400;
        margin-bottom: em(8px);
        font-size: em(17px);
    }
    .copy-info {
        border-top: 1px solid rgba(palette(lt-blue), 0.3);
        margin-top: em(24px);
        padding-top: em(16px);
        p {
            font-size: em(12px);
            margin-bottom: 0;
        }
        @include respond-to(large) {
            text-align: center;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        a {
            text-decoration: none;
            color: palette(lt-blue, light);
            display: block;
            font-size: em(14px);
            line-height: 2;
            &:hover {
                color: $white;
            }
        }
    }
    .nav-horiz {
        float: right;
        li {
            display: inline-block;
            a {
                font-size: 12px;
                display: block;
                color: $white;
                border-right: 1px solid;
                margin-right: 5px;
                padding-right: 10px;
                line-height: 1;
            }
            &:last-child a {
                border-right: 0;
                margin-right: 0;
                padding-right: 0;
            }
        }
        @include respond-to(large) {
            float: none;
            display: inline-block;
            margin: 30px auto 0;
        }
    }
    .twitter-link {
        position:relative;
        &:before{
            content:"";
            @include sprite($twitter-bird);
            position:absolute;
            top:0px; right:0px;
            z-index:1;
        }
        h5 {
            font-weight: 600;
            color: palette(lt-blue);
        }
        p {
            margin-bottom: 0;
        }
        h4 {
            margin: 0;
            padding-bottom: em(16px);
            line-height: 1;
            a {
                color: $white;
                text-decoration: none;
            }
        }
        .highlight {
            font-weight: 600;
            color: palette(lt-blue);
        }
        @include respond-to(large) {
            width: 50%;
            float: left;
        }
        *{
            position:relative;
            //z-index:2;
        }
    }
    .urgent-contact {
        background-color: palette(lt-blue);
        h6 {
            color: palette(deep-blue);
            font-weight: 600;
        }
        p {
            margin-bottom: 0;
        }
        @include respond-to(large) {
            width: 50%;
            float: right;
        }
    }
    .col-8 {
        @include respond-to(large) {
            .col-4 {
                width: 33.3333%;
            }
        }
    }
    .urgent-contact,
    .twitter-link {
        @include respond-to(large) {
            margin-top: 30px;
        }
        @include respond-to(small) {
            width: 100%;
            margin-top: 0;
        }
    }
    .footer-menus {
        @include respond-to(small) {
            display: none;
        }
    }
    .col-1,
    .span-1,
    .col-2,
    .span-2,
    .col-3,
    .span-3,
    .col-4,
    .span-4,
    .col-5,
    .span-5,
    .col-6,
    .span-6,
    .col-7,
    .span-7,
    .col-8,
    .span-8,
    .col-9,
    .span-9,
    .col-10,
    .span-10,
    .col-11,
    .span-12,
    .col-12,
    .span-12 {
        @include respond-to(large) {
            width: 100%;
        }
    }
}