// Base Button
%btn {
    border-bottom: 3px solid #8c8c8c;
    color: white;
    background-color: #d6d6d6;
    box-shadow: inset 0 0 0 0 #d6d6d6;
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    text-align: left;
    -webkit-transition: all ease 0.8s;
    -moz-transition: all ease 0.8s;
    transition: all ease 0.8s;
    margin-right: 10px;
    font-family: $font-family--secondary;
    font-weight: $font-weight--bold;
    position: relative;
    &:hover {
        color: $white;
    }
}

.btn,
.btn-sml {
  @extend %btn;

  &:hover {
    box-shadow: inset 0 -100px 0 0 #8c8c8c;
  }

  &:after {
    font-family: FontAwesome; //float: right;
    //top:15px;
    //margin-left: 10px;
    //margin-top: -1px;
    position: absolute;
    right: 15px;
    content: "\f105";
  }

  &.inactive {
    border-bottom: 3px solid #c1c0c0;
    color: white;
    background-color: #d6d6d6;
    box-shadow: inset 0 0 0 0 #d6d6d6;
    pointer-events: none;
  }

  .btn-stacked & {
    display: block;
    margin-bottom: em(16px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btns-full-width & {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  &.btn-raised {
    margin-bottom: 20px;
  }

  .info {
    font-size: em(13px);
    font-weight: $font-weight--thin;
  }
}

.btn{
    &:after {
        top:14px;
    }
}
.btn-sml {
    &:after {
        top:11px;
    }
}

.btns-centered {
    text-align: center;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li {
        text-align: left;
    }
}

.btn {
    padding: 15px 35px 9px 20px;
    @include respond-to(small) {
        padding: 15px 30px 9px 15px;
        &-nochev {
            padding: 15px 15px 9px;
        }
    }
    &.btn-ghost--frameless {
        padding-bottom: 12px;
        box-shadow: none;
        padding-right: 35px;
        &:after {
            top: 12px;
        }
    }
    font-size: 16px;
    &-right {
        float: right;
    }
    &-left {
        float: left;
    }
    &.btn-ghost--highlight {
        padding-top: 12px;
        padding-bottom: 11px;
    }
    &-download {
        padding-right: 45px;
        &:after {
            content: '';
            background-image: url('../img/download.png');
            background-size: cover;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
        }
    }
}

.btn-sml {
    padding: 11px 25px 8px 18px;
    font-size: 14px;
    &.btn-ghost--frameless {
        padding-bottom: 11px;
        box-shadow: none;
        padding-right: 35px;
        &:after {
            top: 9px;
        }
    }
    &.btn-ghost--highlight {
        padding-top: 8px;
        padding-bottom: 10px;
    }
}

.btn-form {
    padding: 0 10px;
    height: em(35px);
}

.btn-ghost {
    background: none;
    border: 2px solid #d6d6d6;
    color: #d6d6d6;
    box-shadow: inset 0 0 0 0 transparent;
    padding: 13px 30px 9px 20px;
    &--frameless {
        border-width: 0;
    }
    &:hover {
        color: #ffffff;
        box-shadow: inset 0 -100px 0 0 #d6d6d6;
    }
    &:after {
        top: 12px;
    }
}

.btn-nochev {
    text-align: center;
    &:after {
        content: '';
    }
}

.btn-revchev {
    padding-left: 35px !important;
    padding-right: 15px !important;
    &:after {
        //float: left;
        //margin-right: 10px;
        //margin-left: 0;
        //margin-top: -1px;
        content: "\f104";
        position: absolute;
        left: 15px;
        top: 10px;
    @include breakpoint(small) {
        right: auto !important;
        left: 23px;
    }

    }
}

.btn-ico-map {
    &:after {
        float: left;
        content: "\f041";
        margin-right: 10px;
        margin-left: 0;
        margin-top: -1px;
    }
}

.btn-half-width {
    width: 50%;
}

.btn-group {
    @extend %clearfix;
}

/// Fetch nested keys
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Keys to fetch
/// @return {*}
@function map-deep-get($map,
$keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@each $item,
$color in $btn-colors {
    .btn-#{$item} {
        background-color: map-deep-get($btn-colors, $item, 'light-col');
        border-color: map-deep-get($btn-colors, $item, 'dark-col');
        color: map-deep-get($btn-colors, $item, 'font-col');
        box-shadow: inset 0 0 0 0 map-deep-get($btn-colors, $item, 'light-col');
        &:hover {
            box-shadow: inset 0 -100px 0 0 map-deep-get($btn-colors, $item, 'dark-col');
        }
    }
    .btn-#{$item}.btn-ghost {
        background: transparent;
        border-color: map-deep-get($btn-colors, $item, 'light-col');
        color: map-deep-get($btn-colors, $item, 'light-col');
        &:hover {
            box-shadow: inset 0 -100px 0 0 map-deep-get($btn-colors, $item, 'light-col');
            color: map-deep-get($btn-colors, $item, 'font-col');
        }
        &.btn-sml {
            padding: 9px 30px 7px 18px;
            &:after {
                top: 9px;
            }
        }
    }
    .btn-#{$item}.btn-txt-link {
        background-color: transparent;
        border-color: transparent;
        color: map-deep-get($btn-colors, $item, 'light-col');
        &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: map-deep-get($btn-colors, $item, 'light-col');
        }
    }
}

.btn-ghost--highlight {
    border: 2px solid $white;
}

.btn-open24 {
    font-size: em(16px);
    font-weight: $font-weight--light;
    .padlock-ico {
        @extend %padlock-ico;
    }
     @include respond-to(medium){
         display: none;
     }
}

.btn-nochev {
    text-align: center;
    &:after {
        content: '';
    }
    &.btn-sml {
        padding-right: 18px!important;
        padding-left: 18px!important;
    }
    &.btn {
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
}

a[href^="tel"]:link,
a[href^="tel"]:visited,
a[href^="tel"]:hover {
    text-decoration: none;
    pointer-events: none;
    cursor: default;
    @include respond-to(small) {
        text-decoration: underline;
        pointer-events: auto;
        cursor: pointer;
    }
}

.editorialblock .box {
    .btn-lrg {
        .col-3 &,
        .col-4 & {
            width: 100%;
        }
    }
}

.buttons .btn:not(:last-child) {
    margin-bottom: 15px;
}
