
$inbranch-bg: #EFF4F8;



// Fades
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

@keyframes fade-out {
  0% {
    opacity: 100%;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(25px);
  }
}

.inbranch-hidden {
  height: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.fade-in {
  animation: fade-in .35s ease forwards;
  height: auto;
  pointer-events: all;
  display: block;
  z-index: 10;
}

.fade-out {
  animation: fade-out .15s ease forwards;
  @extend .hidden;
}


.inbranch__wrapper {
  background-color: $inbranch-bg;
  height: 100vh;
}


.inbranch__splash {
  margin-top: -118px;

  .splash-banner {
    height: 100vh;

    .splash-banner__image {
      height: 102%;
      object-fit: cover;
      object-position: 58% 50%;
      width: 100%;
    }
  }

  .splash-banner__content {
    padding: 0 25px;
    position: absolute;
    top: calc(50% + 59px);
    transform: translateY(-50%);
    width: 45%;

    h1 {
      color: #fff;
      font-size: 54px;
      text-align: left;
      line-height: 1.25;
    }

    h4 {
      color: #fff;
      font-size: 25px;
      margin: 20px auto 0 auto;
      max-width: 200px;
    }
  }
}

.inbranch__header {
  padding: 30px 0 25px 0;
  position: relative;
  z-index: 1;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &:not(.inbranch__header--active) {
    background-color: rgba(255, 255, 255, .5);
  }

  &.inbranch__header--active {
    background-color: $inbranch-bg;
  }
}

.inbranch__content {
  background-color: $inbranch-bg;


  // Upper Half
  .banner__img {
    max-height: 295px;
    min-height: 0;
  }

  @media all and (min-width: 981px) {
    .banner__text.banner__text--half-.jumbo {
      transform: translateY(-50%);
      width: 50%;
    }
  }

  @media all and (max-width: 980px) {
    .banner__text.banner__text--half-.jumbo {
      margin-top: 20px;
    }
  }


  // Lower Half
  .overlay > div {
    display: flex;
    flex-flow: row wrap;

    .inbranchpromo {
      margin-bottom: 20px;

      > div {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 0;

        @media all and (min-width: 768px) {
          min-height: 180px;
        }

        &.coming-soon {
          pointer-events: none;
          position: relative;

          &:before {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,.85);
            position: absolute;
            z-index: 1;
          }

          &:after {
            content: "Coming soon...";
            align-items: center;
            color: #222;
            display: flex;
            font-size: 30px;
            font-weight: 700;
            height: 100%;
            justify-content: center;
            transform: rotate(-20deg);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
          }
        }
      }

      h3 {
        background: url("../img/icons/icon-right-arrow--deep-blue.svg") no-repeat 100% 50% #fff;
        line-height: 1.15;
        padding: 0 55px;
        position: relative;

        &:before {
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      .inbranchpromo__url {
        color: #222;
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  .topbar__orange--mobile { display: none !important; }
  .inbranch-promo--not    { display: none !important; }
  .inbranch-promo         { display: block !important; }
}

.inbranch-promo { display: none !important; }


.inbranch__popup {
  align-items: center;
  background-color: rgba(0,0,0,.75);
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  height: 100%;
  top: 0;
  width: 100%;

  > .inbranch__popup-inner {
    background-color: #fff;
    border-radius: 5px;
    font-size: 20px;
    padding: 15px;
    max-width: 300px;
  }
}


// Quiz updates
.inbranch__wrapper {
  .ilfs-bg-words, .ilfs-questions-holder {
    padding: 0 !important;
  }


  .ilfs-white-bg {
    background: #fff !important;
  }

  a.backBtn {
    margin-right: 20px !important;
    margin-bottom: 40px !important;
    padding: 15px 35px 9px 20px !important;
    font-size: 16px !important;
    width: auto !important;
    height: auto !important;
    font-weight: 600 !important;
    font-size: 18px !important;

    span {
      text-decoration: none !important;
    }
  }
}

//Page alignment fix
.inbranch__content {
  .homebannerblock{
    .row{
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.inbranch__header{
  a{
    margin: 0 10px !important;
  }
}

.inbranch__content {
  .intent-calc section {
    margin: 0 !important;
  }

  .intent-calc-wrapper {
    border-top: 6px solid #ec7403 !important;
  }
}


// Font styles and spacing fixes as per document
.inbranch__content {
  .banner .banner__text.jumbo h1 {
    font-size: 48px;
  }

  .overlay > div .inbranchpromo h3 {
    line-height: 40px;
    margin-bottom: 0.7em;
    padding: 0 45px;
    font-size: 20px;
  }

  .overlay > div .inbranchpromo > div {
    border-radius: 5px;
  }

  .shadow {
    box-shadow: 0 0 10px hsla(0,0%,44%,.6);
  }
}
.inbranch__splash .splash-banner__content h1{
  font-size: 60px;
}

.inbranch__header {
  padding: 15px 0 10px;
}

.splash-banner{

  img{width: 180px;}
}

.banner .banner__img img, .banner .banner__img img.banner__img--desktop {
  min-height: 300px;
  min-width: 1600px;
}


.inbranch__splash .splash-banner__content {
  width: 32%;
  left: 5%;
}

.inbranch__splash .splash-banner__content h4{
  font-size: 27px;
}
