.mob-hamburger {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    width: 60px;
    height: 48px;
    font-size: 0;
    text-indent: -999999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
    background: transparent;
    color: palette(deep-blue);
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 32px;
    font-weight: 700;
    
    &:after {
        content: 'MENU';
        display: block;
        position: absolute;
        bottom: -5px;
        font-size: 12px;
        text-indent: initial;
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
    &:focus {
        outline: none;
    }
    span {
        display: block;
        position: absolute;
        text-indent: -9999px;
        top: 15px;
        left: 10px;
        right: 10px;
        height: 6px;
        background: palette(deep-blue);
        border-radius: 4px;
    }
    span::before,
    span::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: palette(deep-blue);
        content: "";
        border-radius: 4px;
    }
    span::before {
        top: -12px;
    }
    span::after {
        bottom: -12px;
    }

    &--htx span::before,
    &--htx span::after {
        transition-duration: 0.3s, 0.3s;
        transition-delay: 0.3s, 0s;
    }
    &--htx span::before {
        transition-property: top, transform;
    }
    &--htx span::after {
        transition-property: bottom, transform;
    }
    /* active state, i.e. menu open */

    &--htx.is-active span {
        background: none;
    }
    &--htx.is-active span::before {
        top: 0;
        transform: rotate(45deg);
    }
    &--htx.is-active span::after {
        bottom: 0;
        transform: rotate(-45deg);
    }
    &--htx.is-active span::before,
    &--htx.is-active span::after {
        transition-delay: 0s, 0.3s;
    }
}