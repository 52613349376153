.nav-dropdown {

    position: relative;
    display: none;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    float: right;
    width: 50%;
    margin-right: 10px;
    
    @include respond-to (medium) {
        display: block;
        & + div[class*=col-] {
            display: none;
        }
    }
   
    @include respond-to (small) {
        width: 95%;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    &::after {
        content: ' ';
        position: absolute;
        right: 1px;
        bottom: 7px;
        width: 40px;
        height: 40px;
        display: block;
        background: url('../img/icons/icon-down-arrow.svg') no-repeat 4px 17px palette(white, dark);
        filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='img/icons/icon-down-arrow.svg', sizingMethod='scale');
        -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='img/icons/icon-down-arrow.svg', sizingMethod='scale')";
        background-size: 15px 9px;
        pointer-events: none;
        margin-top: 30px;
    }
            select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid palette(dk-blue, xx-light);
        background: palette(white, dark);
        border-radius: 3px;
        padding: 14px 14px 11px;
        width: 100%;
        color: palette(grey, dark);
        font-size: 18px;
        cursor: pointer;
        height: 55px;
        &::-ms-expand {
            display: none;
        }
    }
    :-moz-any(select) {
        width: 110%;
    }
    &.ie9 select {
        width: 110%;
    }
    select:focus {
        outline: 0;
    }
}