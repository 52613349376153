.col-9 .launch--calc{
    padding:10px 0px;
}

.desktop-cta{
    display: block;
    @include respond-to(medium){
        display: none;
    }
}
.mobile-cta{
    display: none;
    @include respond-to(medium){
        display: block;
    }
}

.banner {
    position: relative;
    border-top: 0;
    //max-height: 380px;
    overflow: hidden;
    margin-top: -1px;
    /* @include respond-to(medium) {
        max-height:525px;
    } */
    /* @include breakpoint(406) {
        max-height:535px;
    }
    @include breakpoint(406) {
        max-height:535px;
    } */
    /* @include respond-to(tiny) {
        max-height:565px;
    } */
    .banner__img {
        width: 100%;
        min-height: 355px;
        max-width: 100%;
        overflow: hidden;
        @include respond-to(medium) {
            position: absolute;
        }
        img {
            min-height: 355px;
            min-width: 1500px;
            display: block;
            width: 100%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        
        img.banner__img--desktop {
            min-height: 355px;
            min-width: 1500px;
            
        }        
        img.banner__img--tablet {
            min-height: 355px;
            min-width: 768px;
           
        }
        
        &--slide {
            position: relative;
            left: 50%;
        }
        &--scrim {
            background: url('../img/scrim.png')repeat-x bottom left transparent;
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 275px;
            transform: translateX(-50%);
        }
        &--tablet {
            display: none  !important;
            @include respond-to(medium) {
                display: block !important;
            }
        }
        &--desktop {
            display: block  !important;
            @include respond-to(medium) {
                display: none  !important;
            }
        }
    }
    .banner__text {
        color: $white;
        -webkit-transform: translateY(-65%);
        -moz-transform: translateY(-65%);
        -ms-transform: translateY(-65%);
        -o-transform: translateY(-65%);
        transform: translateY(-65%);
        @include respond-to(medium) {
            color: #ffffff;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
            text-align: center;
            margin-top: 10%;
        }
        .text-center {
            .btn,
            .btn-sml {
                float: none;
            }
        }
        &.jumbo {
            h1 {
                line-height: 1.2;
                margin: 0;
                font-size: em(45px);
                @include respond-to(large) {
                    font-size: em(38px);
                }               
                @include respond-to(medium) {
                    font-size: em(32px);
                }
                @include respond-to(x-small) {
                    font-size: em(26px);
                }
            }
            p {
                font-size: em(20px);
                @include respond-to(x-small) {
                    font-size: em(15px);
                }
            }
        }
        .btn {
            float: left;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            @include respond-to(medium) {
                float: none;
            }
            @include respond-to(x-small) {
                
                margin-right: 0;
                display: block;
                margin: 10px 0 0;
            }
        }
        &-wrap {
          position: absolute;
          top: 52%;
          left: 0;
          width: 100%;
          @include respond-to(medium) {
              position: relative;
              left:auto;
              width: 95%;
              //min-height: 370px;
              margin-right: auto;
              margin-left: auto;
          }
          h1,
          h2,
          p {
              color: $white;
              &.txt--orange {
                  color: palette(orange);
              }
              &.txt--lt-blue {
                  color: palette(lt-blue);
              }
              &.txt--dk-blue {
                  color: palette(dk-blue);
              }
              &.txt--deep-blue {
                  color: palette(deep-blue);
              }
              &.txt--black {
                  color: $black;
              }
          }
            .trans-bkg--white {
                p {
                color:palette(grey, dark);
                }
                @include respond-to(medium) {
                    background:  rgba($white, 1);
                }
            }
        
      }
        &--half-right {
            width: 50%;
            float: right;
            padding: 0 10px;
            @include respond-to(large) {
                 width: 75%;
            }
            @include respond-to(medium) {
                width: 95%;
                margin: 50px auto;
                float: none;
                text-align: center;
                .btn-group {
                    display: inline-block;
                }
                p,
                h1 {
                    max-width: 100%;
                }
            }
           
        }
        &--half-left {
            width: 50%;
            float: left;
            padding: 0 10px;
            
            @include respond-to(medium) {
                width: 95%;
                margin: 50px auto;
                float: none;
                text-align: center;
                .btn-group {
                    display: inline-block;
                }
                p,
                h1 {
                    max-width: 100%;
                }
            }

        }
        &--qtr-right {
            width: 25%;
            float: right;
            padding: 0 10px;
           
            @include respond-to(medium) {
                width: 100%;
                margin: 0 /* auto 4em */;
                float: none;
                text-align: center;
                .btn-group {
                    //display: inline-block;
                }
                p,
                h1 {
                    max-width: 100%;
                }
            }
        }
        &--qtr-left {
            width: 25%;
            float: left;
            padding: 0 10px;
            @include respond-to(large) {
                width: 75%;
                margin: 0 auto;
                float: none;
                text-align: center;
                .btn-group {
                    display: inline-block;
                }
                p,
                h1 {
                    max-width: 100%;
                }
            }
        }
        p.t-and-c {
    max-width: 100% !important;
    font-size: 12px !important;
    margin: 15px 0 5px;
}
    }
    &--lower {
        .banner__text-wrap {
            top: 55%;
        }
    }
    &__overlay {
        .base {
            margin-top: -40px;
            background-color: transparent;
            @include respond-to(large) {
                margin-top: 0px;
            }
            &:before {
                background-color: $white;
                display: block;
                content: '';
                position: absolute;
                top: -25px;
                width: 100%;
                height: 50px;
                border: 30px solid white;
                border-radius: 5px;
                box-sizing: content-box;
                left: -30px;
                @include respond-to(large) {
                    box-sizing:border-box !important;
                    left: 0px !important;
                    top: 0px !important;
                }
            }
        }
        .watermarked .base {
            background-image: url("../img/PTSB-watermark.png");
            background-position: right bottom;
        }
    }
    &__blue {
         background: #6d92b7; /* Old browsers */
            background: -moz-linear-gradient(top,  #6d92b7 0%, #51769a 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6d92b7), color-stop(100%,#51769a)); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  #6d92b7 0%,#51769a 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  #6d92b7 0%,#51769a 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  #6d92b7 0%,#51769a 100%); /* IE10+ */
            background: linear-gradient(to bottom,  #6d92b7 0%,#51769a 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d92b7', endColorstr='#51769a',GradientType=0 ); /* IE6-9 */
    }
}
@include respond-to(medium) {
    .noBannerMob {
        background: palette(orange);
        .banner__img {
            display: none;
        }
        .banner__text-wrap {
            position: static;
            min-height: none;
        }
        &--black {
            background: palette(black);
        }
        &--white {
            background: palette(white);
        }
        &--lt-blue {
            background: palette(lt-blue);
        }
        &--dk-blue {
            background: palette(dk-blue);
        }
        &--deep-blue {
            background: palette(deep-blue);
        }
        &--orange {
            background: palette(orange);
        }
        &.box-bkg {
                border-radius: 3px;
            .box-bkg__image {
                display: none;
            }
            [class*="trans-bkg"] {
                background-color: transparent;
                border: none;
            }
            .box {
                background-color: transparent !important;
            }
           
        }
    }
}