
// Block: Coloured Container Block
.colouredcontainerblock {

    &--content {
        font-size: 17px;
        margin: 0 auto;
        max-width: 680px;
        padding: 0 15px;
        text-align: center;

        .btn {
            min-width: 200px;
        }

        // Content
        &.above-cta { padding-bottom: 50px; }
        &.below-cta { padding-top: 65px; }

        p:last-child { margin-bottom: 0; }

        &.above-cta h3 {
            font-size: 32px;
            line-height: 1.25;
        }

        @include breakpoint(767) {
            .btn { width: 100%; }
        }
    }

    [class*="bg-"] {
        padding: 65px 0;
    }

    [class*="bg-"] .colouredcontainerblock--content *:not(.btn) { color: $color-deep; }

    // We don't want white text on a white bg
    .bg-offwhite,
    .bg-white {
        .colouredcontainerblock--content *:not(.btn) { color: $color-deep; }
    }
    .bg-orange,
    .bg-blue,
    .bg-black {
        .colouredcontainerblock--content *:not(.btn) { color: $white; }
    }
    // Background color variants
    .bg {

        &-black    { background-color: #2B2B2B; }

        &-blue     { background: linear-gradient(to bottom, #3B6597, #062565); }

        &-orange   { background-color: $mango-tango; }

        &-offwhite { background-color: #E7E8EA; }

    }

}