#BranchVisitWrapper {
    //     #PreferredTimeSlot {
    //         width: 100vw;
    //         position: relative;
    //         left: 50%;
    //         right: 50%;
    //         margin-left: -50vw;
    //         margin-right: -50vw;
    // }
    #g-recaptcha {
        display: inline-block;
        width: 100%;
    }
    .TsAndCs {
        margin-top: 15px;
    }
    #TimeDropdown {
        width: 100%;
        max-width: 672px;
    }
    .time-block {
        background-color: #F9FAFC;
        border: 1px solid #E7EEF3;
        border-radius: 3px;
        padding: 10px 0 10px 10px !important;
        @media all and (max-width: 432px) {
            padding: 10px !important;
        }
        h6 {
            width: 100%;
            text-align: left;
            color: #6f6f6f;
            margin-bottom: 20px;
        }
        .times {
            width: 100%;
        }
        .slotTimeItem {
            float: left;
            width: calc(50% - 10px);
            color: #6f6f6f;
            padding: 10px;
            margin-bottom: 10px;
            &:after {
                display: none;
            }
        }
        .active {
            box-shadow: none;
            border: 1px solid #cfdce7;
            font-weight: 400;
            font-size: 14px;
            background: #FFFFFF;
        }
        .Inactive {
            pointer-events: none;
            opacity: 0.2;
            border: 1px solid #cfdce7;
            font-weight: 400;
            font-size: 14px;
        }
        .btn-orange {
            background-color: #ec7403;
            color: #fff;
            box-shadow: none;
            border: 1px solid #ec7403;
            font-weight: 400;
        }
    }
    .slick-initialized .slick-slide {
        display: block;
        margin-left: 10px!important;
        margin-right: 10px!important;
        width: 400px;
        @media all and (max-width: 524px) {
            width: 300px;
        }
        @media all and (max-width: 432px) {
            width: 200px;
        }
    }
    .slider-nav-wrapper {
        display: inline-block;
        width: 100%;
        .prev-next {
            width: 100%;
            display: inline-block;
            margin-bottom: -35px;
        }
        .prev-slide,
        .next-slide {
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 20px;
            font-weight: 700;
            @media all and (max-width: 432px) {
                font-size: 14px;
            }
        }
        .prev-slide {
            float: left; // margin-left: 30px;
        }
        .next-slide {
            float: right; // margin-right: 30px;
        }
    }
}

#BranchVisitResultWrapper {
    text-align: center;

    .bookappointmentblock--inner {
        margin: 0 -10px;
        background-color: #6699CC;
        padding-left: 50px;
        padding-right: 50px;

        @include breakpoint(767) {
            padding-bottom: 180px;
            padding-top: 30px;
        }

        h3,
        h5 {
            color: $white;
            display: inline-block;
            text-align: center;
        }

        &:before {
            opacity: 0.2;
        }
    }

    .appointment-details {
        background: $white;
        margin-bottom: 20px;
        text-align: center;

        .appointmentTitle {
            color: #ec7403;
            font-size: 0.8em;
            font-weight: 700;
            margin-bottom: 20px !important;

            @include breakpoint(767) {
                margin-bottom: 10px !important;
            }
        }

        .download-results {
            margin-top: -60px;
            position: relative;
            z-index: 1000; // silly inline z-index for appointment block needs overwritten

            @include breakpoint(767) {
                margin-top: 30px;
            }

            h5 {
                margin-bottom: 10px;
            }

            hr {
                margin-top: 40px;
                max-width: 505px;
            }
        }

        .remember {
            color: #ec7403;
            font-weight: 700;
            margin-left: auto;
            margin-right: auto;
            padding: 40px 0;

            @media all and (min-width: 767px) {
                margin-top: -90px;
                max-width: 380px;
            }
        }

        .download-results + .remember {
            @media all and (min-width: 767px) {
                margin-top: 0;
            }
        }
    }

    .editorialblock {
        text-align: left;
    }

    @include breakpoint(767) {
        // margin-left: -15px;
        // max-width: calc(100% + 30px);
        // width: calc(100% + 30px);
        margin-left: 10px;
        padding: 0;
    }
}

.slick-list {
    &:before,
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 60px;
        top: 0;
        height: 100%;
        pointer-events: none;
        @media all and (max-width: 606px) {
            display: none;
        }
    }
    &:before {
        left: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 20px, rgba(255, 255, 255, 0));
    }
    &:after {
        right: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 1) 20px, rgba(255, 255, 255, 0));
    }
}