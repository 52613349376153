// Block: Mortgage Calculator Block
.mortgageratecalculatorblock--upper {
    border-radius: 4px;
    border: 1px solid #d5dfe8;
    border-top: 4px solid $mango-tango;
    box-shadow: 0px 0px 5px 2px rgba(33, 33, 33, .1);
    color: $color-deep;
    margin-bottom: 20px;
    padding: 35px;
    @include breakpoint(767) {
        padding: 35px 15px;
    }
    >h4 {
        border-bottom: 2px solid $mango-tango;
        color: $mango-tango;
        display: inline-block;
        margin-bottom: 40px;
        text-transform: uppercase;
    }
    h6 {
        margin-bottom: 8px;
    } // Button styling
    button:not(.term-button) {
        background-color: $white;
        border: 1px solid #CFDBE7;
        border-radius: 0;
        color: $color-deep;
        float: left;
        margin: 0 0 25px 0;
        min-width: 130px;
        outline: none;
        position: relative;
        text-align: center;
        transition: .15s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 1; // Getting rid of unnecessary border radius
        &:first-of-type {
            border-radius: 3px 0 0 3px;
        }
        &:last-of-type {
            border-radius: 0 3px 3px 0;
        }
        &:not(:first-of-type) {
            border-left: none;
        } // Overriding default button style
        &:hover {
            box-shadow: none;
            color: $color-deep;
        } // The triangle 
        &:before {
            content: '';
            background-color: #f2f6f9;
            border-right: 1px solid;
            border-bottom: 1px solid;
            border-color: #cfdbe7;
            bottom: -10px;
            height: 20px;
            left: calc(50% - 10px);
            opacity: 0;
            position: absolute;
            transform: rotate(45deg);
            width: 20px;
            z-index: -1;
        } // When a user has selected a button
        &.btn--selected {
            background-color: #F2F6F9;
            color: $mango-tango;
            &:before {
                opacity: 1;
            }
            &:hover {
                color: $mango-tango;
            }
        }
    } // The buttons
    .btn-group {
        margin-bottom: 15px;
        &--inline {
            display: inline-block;
            margin-right: 15px;
            >.termlengthcontainer {
                position: relative;
                display: inline-block;
                p {
                    bottom: -45px;
                    position: absolute;
                    text-align: center;
                    width: 100%;
                }
            }
        }
        [type="number"],
        [type="tel"] {
            border: 1px solid #CFDBE7;
            height: 50px;
            padding: 10px 15px;
            &:not(#termLengthInput) {
                border-radius: 3px;
                font-weight: 700;
                padding-left: 50px;
                width: 220px;
            }
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                appearance: none;
            }
        }
        #termLengthInput {
            margin: 0 -4px;
            text-align: center;
            width: 60px; // @include breakpoint(767) {
            //     width: calc(100% - 82px);
            // }
        } // The plus and minus buttons
        .term {
            &-button {
                background-color: $mango-tango;
                color: $white;
                font-size: 28px;
                font-weight: 700;
                height: 50px;
                position: relative;
                top: 4px;
                width: 40px;
            }
            &-minus {
                border-radius: 3px 0 0 3px;
            }
            &-plus {
                border-radius: 0 3px 3px 0;
            }
        }
        &--full {
            button:not(.term-button) {}
        }
        @include breakpoint(768) {
            &.btns-2 {
                .btn {
                    width: 50%;
                }
            }
            .btn-group--inline {
                display: block;
                margin-right: 0;
                &:not(:last-of-type) {
                    margin-bottom: 25px;
                }
                [type=number]:not(#termLengthInput) {
                    width: 100%;
                }
            }
        }
        @include breakpoint(768) {
            &.btns-many {
                margin: 30px 0 65px 0;
            }
        }
    }
    .input--with-image {
        position: relative;
        &:before {
            content: '\f153';
            background-color: #A8B6C1;
            border-radius: 4px 0 0 4px;
            color: $white;
            font-family: 'fontawesome'; //font-weight: 700;
            height: 100%;
            left: 0;
            line-height: 50px;
            position: absolute;
            text-align: center;
            top: 0;
            width: 40px;
            font-size: 19px;
            font-weight: normal;
        }
    }
    #ltvRatioDiv,
    #warningTextDiv {
        display: none;
    }
    .buttons {
        border-top: 1px solid #d5dfe8;
        margin: 0 -35px;
        padding-top: 35px;
        &--inner {
            margin: 0 35px;
        }
        @include breakpoint(768) {
            margin: 0 -15px -15px -15px;
            padding-top: 15px;
            &--inner {
                margin: 0 15px;
            }
            a {
                width: 100%;
            }
        }
        a {
            cursor: pointer;
            float: right;
        }
    }
    .select-container {
        margin-bottom: 25px;
        position: relative;
        &:before {
            @include arrow(left);
            left: calc(100% - 40px);
        }
        &:after {
            @include arrow(right);
            left: calc(100% - 33px);
        }
        >select {
            appearance: none;
            border: 1px solid #cfdbe7;
            border-radius: 3px;
            padding: 12px 15px;
            width: 100%;
        }
        &.selected {
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            >select {
                background-color: rgb(242, 246, 249);
            }
        }
    }
}

// Loan to value
.ltv {
    border-radius: 4px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, .2);
    color: $white;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    &--wrapper {
        margin: 40px 0;
    }
    &--progress {
        background-color: $color-primary;
        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, .2);
        font-weight: 700;
        min-width: 170px;
        padding: 7px 10px;
        position: relative;
        transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
        .static {
            font-weight: 300;
            left: 15px;
            position: absolute;
            top: 8px;
        }
    }
}

.termlengthcontainer {
    position: relative;
    display: inline-block;
    margin-bottom: 22px;
    p {
        bottom: -45px;
        position: absolute;
        text-align: center;
        width: 100%;
    }
    input {
        margin: 0 -4px;
        text-align: center;
        width: 60px;
        padding: 10px 15px;
        border: 1px solid #cfdbe7;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
    }
}

@media (max-width: 470px) {
    #investmentPropertyDiv {
        .btn {
            height: 74px;
            }
        }
  }
