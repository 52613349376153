    .banner {
      &__overlay {
        background: linear-gradient(to left, #f7f8fc, #f7f8fc) no-repeat 0px 305px;
        border-bottom: 1px solid $botticelli;

        @media screen and (max-width: 1024px) {
          background: none;
          border: none;
        }

        &:before {
          height: 1px;
          display: block;
          width: 100%;
          background: $botticelli;
          border-top: none;
          content: '';
          top: 284px;
          position: relative;

          @media screen and (max-width: 1024px) {
            background: none;
          }
        }
      }

      .editorialblock {
        margin: 0 40px 0 40px;
      }
    }

    .col-3 {
      .editorialblock {
        @media screen and (max-width: 980px) {
          margin: 0px -10px;
        }

        .box {
          border: 1px solid $botticelli;
          border-radius: 3px;

          h3 {
            font-size: 22px;
          }
        }
      }
    }

    .intent-calc-wrapper {
      border-radius: 4px;
      border-top: 4px solid $mango-tango;
      box-shadow: 0px 0px 5px 2px rgba(33, 33, 33, .1);
      color: $color-deep;
      margin-bottom: 20px;
      padding: 35px;
      background: $white;

      @include breakpoint(767) {
        padding: 35px 15px;
      }

      .input--with-image {
        position: relative;

        &:before {
          content: '\f153';
          background-color: #A8B6C1;
          border-radius: 4px 0 0 4px;
          color: $white;
          font-family: 'fontawesome'; //font-weight: 700;
          height: 100%;
          left: 0;
          line-height: 50px;
          position: absolute;
          text-align: center;
          top: 0;
          width: 40px;
          font-size: 19px;
          font-weight: normal;
        }

        input {
          border-radius: 3px;
          font-weight: 700;
          padding-left: 50px;
          width: 265px;

          @media screen and (max-width: 595px) {
            width: 100%;
          }
        }
      }

      .termlengthcontainer {
        position: relative;
        display: inline-block;
        margin-bottom: 22px;

        p {
          bottom: -45px;
          position: absolute;
          text-align: center;
          width: 100%;
        }

        input {
          margin: 0 -4px;
          text-align: center;
          width: 60px;
          padding: 10px 15px;
          border: 1px solid #cfdbe7;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }

      .term {
        &-button {
          background-color: $mango-tango;
          color: $white;
          font-size: 28px;
          font-weight: 700;
          height: 50px;
          position: relative;
          top: 4px;
          width: 40px;
        }

        &-minus {
          border-radius: 3px 0 0 3px;
        }

        &-plus {
          border-radius: 0 3px 3px 0;
        }
      }

      .btn-nochev {}

      .btn-group--inline {
        display: block;
        margin-right: 0;

        &:not(:last-of-type) {
          margin-bottom: 25px;
        }

        [type=number]:not(#termLengthInput) {
          width: 100%;
        }
      }

      .ApplicantOne,
      .ApplicantTwo {
        border-radius: 3px;
        text-align: left;
        background: #F7F8FC;
        padding: 35px;
        margin: 0 0 40px 0;

        @include breakpoint(767) {
          margin: 0 0 40px 0;
          border-radius: 0px;
          padding: 35px 1.25em;
        }

        h4 {
          margin-bottom: 30px;
          color: #ec7403;
        }
      }
    }

    .intent-calc-results-wrapper,
    .FTBinformation {
      border-radius: 4px;
      box-shadow: 0px 0px 5px 2px rgba(33, 33, 33, .1);
      color: $color-deep;
      margin-bottom: 20px;
      padding: 35px;
      background-color: #FFFFFF;

      @include breakpoint(767) {
        padding: 35px 15px;
      }
    }

    .fa-percent:before {
      content: "%";
      font-family: "Open Sans";
      font-weight: 900;
    }

    .intent-calc {

      .currency:after,
      .btn-group:after,
      .chart__row:after {
        content: "";
        display: table;
        clear: both;
      }

      .currency--EUR:before,
      .currency--STG:before,
      .currency--PCT:before {
        content: '\f153';
        background-color: #A8B6C1;
        border-radius: 4px 0 0 4px;
        color: $white;
        font-family: 'fontawesome'; //font-weight: 700;
        height: 100%;
        left: 0;
        line-height: 50px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 40px;
        font-size: 19px;
        font-weight: normal;
      }

      font-size: 16px;
      line-height: 1.5;
      color: #6f6f6f;
      font-weight: 100;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #0E2B69;
      }

      a {
        text-decoration: none;
        color: #0E2B69;
        font-weight: 700;
        -webkit-transition: color 0.2s ease-out;
        -moz-transition: color 0.2s ease-out;
        -o-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out;

        &:hover {
          color: #68a2da;
        }
      }

      h1 {
        font-size: 2.4375em;
      }

      h2 {
        font-size: 2.125em;
        margin: 0;
      }

      h3 {
        font-size: 1.6875em;
        line-height: 1.65;
        margin: 0;
      }

      h4 {
        font-size: 1.4375em;
        margin: 0;
      }

      h5 {
        font-size: 1.1875em;
        margin: 0;
      }

      h6 {
        font-size: 1em;
        margin: 0 0 8px 0;
      }

      p {
        font-size: 0.9375em;
        line-height: 1.5;
        margin: 0.5em 0 1em;
      }

      img {
        border: 0;
      }

      ul {
        list-style: none;
        padding-left: 0.625em;
        font-weight: 300;

        li {
          padding-left: 0.9375em;
          position: relative;
          font-size: 0.875em;
          margin-bottom: 0.9375em;

          li:before {
            content: "";
            background-color: #e7ecf1;
            width: 6px;
            display: block;
            height: 6px;
            position: absolute;
            left: -8px;
            top: 10px;
            border-radius: 50%;
          }

          &:before {
            content: "";
            background-color: #EC7403;
            width: 8px;
            display: block;
            height: 8px;
            position: absolute;
            left: -10px;
            top: 6px;
            border-radius: 50%;
          }
        }

        ul {
          margin: 5px 0 20px;
        }
      }

      hr {
        border-color: transparent;
        background-color: #ffffff;
        height: 0px;
        display: block;
        margin: 20px 0;
        border-top: 0;
      }

      section {
        padding: 1.25em;
        position: relative;
        float: none;
        margin: 20px 0px;

        &:last-of-type {
          border-bottom: 0;
        }

        @include breakpoint(767) {
          padding: 1.25em 10px;
        }
      }

      .btn-group {
        margin-bottom: 15px;

        &--inline {
          display: inline-block;
          margin-right: 15px;

          >.termlengthcontainer {
            position: relative;

            p {
              bottom: -45px;
              position: absolute;
              text-align: center;
              width: 100%;
            }
          }
        }

        [type="number"],
        [type="tel"] {
          border: 1px solid #CFDBE7;
          height: 50px; // padding: 10px 15px;

          &:not(#termLengthInput) {
            border-radius: 3px;
            font-weight: 700; // padding-left: 50px;
            // width: 220px;
          }

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            appearance: none;
          }
        }

        #termLengthInput {
          margin: 0 -4px;
          text-align: center;
          width: 60px;

          @include breakpoint(767) {
            width: calc(100% - 82px);
          }
        }

        // The plus and minus buttons
        .term {
          &-button {
            background-color: $mango-tango;
            color: $white;
            font-size: 28px;
            font-weight: 700;
            height: 50px;
            position: relative;
            top: 4px;
            width: 40px;
          }

          &-minus {
            border-radius: 3px 0 0 3px;
          }

          &-plus {
            border-radius: 0 3px 3px 0;
          }
        }

        &--full {
          button:not(.term-button) {}
        }

        @include breakpoint(767) {
          &.btns-2 {
            .btn {
              width: 50%;
            }
          }

          .btn-group--inline {
            display: block;
            margin-right: 15px;

            &:not(:last-of-type) {
              margin-bottom: 25px;
            }

            [type=number]:not(#termLengthInput) {
              width: 100%;
            }
          }
        }

        @include breakpoint(767) {
          &.btns-many {
            margin: 30px 0 0 0;
          }
        }
      }
    }

    .intent-calc__results {
      border-radius: 3px;
      text-align: left;
      background: #F7F8FC;
      padding: 35px;

      p {
        margin: 10px 0 30px 0;
      }

      h2 {
        small {
          display: block;
          font-size: 18px;
          line-height: 1;
          margin-bottom: 10px;
          font-weight: 500;
        }
      }

      @include breakpoint(767) {
        margin: -1.25em -10px;
        border-radius: 0px;
        padding: 1.25em;
      }

      input#val_display,
      input#dep_display,
      .input {
        background: none;
        border: none;
        color: palette(gull, light);
        font-size: 32px;
        font-weight: 700;
        line-height: 1em;
        font-family: museo-sans-rounded, sans-serif;
        margin-top: -9px;
        overflow: visible;
        height: 64px; // padding: 0 0 0 10px;

        &:before {
          display: none;
        }

        // &:after {
        //     content: "€";
        //     background-color: transparent;
        //     border-radius: 4px 0 0 4px;
        //     color: palette(gull, light);
        //     font-family: museo-sans-rounded, sans-serif;
        //     height: 100%;
        //     left: -10px;
        //     line-height: 1em;
        //     position: absolute;
        //     text-align: center;
        //     top: 7px;
        //     width: 40px;
        //     font-size: 32px;
        //     font-weight: 700;
        //     @include breakpoint(650) {
        //         font-size: 36px;
        //     }
        // }
        @include breakpoint(650) {
          font-size: 36px;
        }
      }
    }

    .intent-calc__results--white {
      background: #ffffff;
      padding: 0.9375em 0 0.625em;

      h2,
      h3,
      h4 {
        color: #EC7403;
      }
    }

    .intent-calc__calculate {
      background-color: #e7ecf1;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background-image: url(/Calculators/img/calc-blue-strip.png);
      background-image: linear-gradient(bottom, #6699cc 50%, transparent 50%);
      background-image: -o-linear-gradient(bottom, #6699cc 50%, transparent 50%);
      background-image: -moz-linear-gradient(bottom, #6699cc 50%, transparent 50%);
      background-image: -webkit-linear-gradient(bottom, #6699cc 50%, transparent 50%);
      background-image: -ms-linear-gradient(bottom, #6699cc 50%, transparent 50%);
    }

    .intent-calc {
      .terms {
        color: #0E2B69;
        font-weight: 700;

        p {
          font-size: 0.875em;
        }
      }

      .bkg-col--orange {
        background-color: #EC7403;
      }

      .bkg-col--red {
        background-color: #dc3135;
      }

      .bkg-col--lt-blue {
        background-color: #89b6e2;
      }

      .bkg-col--dk-blue {
        background-color: #6699cc;
      }

      .bkg-col--deep-blue {
        background-color: #0E2B69;
      }

      .bkg-col--steel {
        background-color: #9ca4b6;
      }

      .bkg-col--gull {
        background-color: #e7ecf1;
      }

      .bkg-col--grey {
        background-color: #6f6f6f;
      }

      .bkg-col--mystic,
      .bkg-col--blue-grey {
        background-color: #e7ecf1;
      }

      .bkg-col--white {
        background-color: #fefefe;
      }

      .bkg-col--black {
        background-color: #0b0b0b;
      }

      .bkg-col--dk-blue {
        p {
          text-align: center;
          color: #ffffff;
          font-weight: 300;
        }

        .intent-calc__info {
          p {
            text-align: left;
            font-size: 15px;

            a {
              color: #f3c392;

              &:hover {
                color: #fdb36c;
              }
            }
          }

          li {
            color: #ffffff;
            font-size: 15px;
          }
        }

        .intent-calc__results--white p {
          color: #6f6f6f;
          padding: 0 10px;

          &.txt--sml {
            font-size: 15px;
            margin: 10px 0;
          }

          &.txt--deep-blue {
            color: #0E2B69;
          }

          &.txt--orange {
            color: #EC7403;
          }
        }

        label {
          color: #ffffff;
          display: block;
          margin: 5px 0;
        }
      }

      .bkg-col--mystic hr {
        background-color: #cdd8e2;
      }

      * {
        -webkit-box-sizing: border-box;
        /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;
        /* Firefox, other Gecko */
        box-sizing: border-box;
        /* Opera/IE 8+ */
      }

      label {
        font-size: 0.875em;

        a {
          margin-top: 20px;
          border-bottom: 1px solid #85add6;
          color: #ffffff;
          margin-bottom: 10px;
          padding-bottom: 10px;
          display: block;
          font-weight: 300;

          &:hover {
            color: #ffffff;
          }
        }

        .toggle-btn {
          background: url("/Calculators/img/icon-down-arrow-white.svg") no-repeat 100% 8px transparent;
          background-size: 13px;
        }

        .toggle-open {
          background: url("/Calculators/img/icon-up-arrow-white.svg") no-repeat 100% 8px transparent;
          background-size: 13px;
          border-bottom: none;
        }

        &.input-validation-error {
          color: #dc3135 !important;
        }
      }

      input {
        border: 1px solid #c2d6eb;
        color: $black;
        font-size: 16px;
        padding: 0.9375em 0 0.75em 0.9375em;
        background: #ffffff;
        border-radius: 3px;
        width: 265px;
        margin-bottom: 10px;
        height: 50px;
        font-weight: 700;

        &.input-validation-error {
          border-color: #dc3135 !important;
        }

        @media screen and (max-width: 595px) {
          width: 100%;
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        width: 100%;

        &.input-validation-error {
          border-color: #dc3135 !important;
        }
      }

      .dropdown-leg {
        position: relative;
        display: block;
        overflow: hidden;
        cursor: pointer;
        width: 100%;

        &::after {
          content: ' ';
          position: absolute;
          right: 1px;
          top: 0;
          width: 40px;
          height: 40px;
          display: block;
          background: url("/Calculators/img/icon-down-arrow.svg") no-repeat 4px 17px #fff;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-down-arrow.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-down-arrow.svg', sizingMethod='scale')";
          background-size: 15px 9px;
          pointer-events: none;
          margin-top: 30px;
        }

        select {
          border: 1px solid #c2d6eb;
          background: white;
          border-radius: 3px;
          padding: 0.9375em 0 0.75em 0.9375em;
          width: 100%;
          color: #6699cc;
          font-size: 1.125em;
          cursor: pointer;

          &::-ms-expand {
            display: none;
          }
        }

        &:-moz-any(select),
        &.ie9 select {
          width: 110%;
        }

        select:focus {
          outline: 0;
        }
      }

      .dropdown {
        height: 55px;
        display: block;
        overflow: hidden;
        width: 100%;
        border-radius: 3px;
        border: 1px solid #c2d6eb;
        background-color: #ffffff;
        position: relative;
        background-image: url("/Calculators/img/icon-down-arrow.svg");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 95% center;
        cursor: pointer;

        select {
          padding: 17px 35px 17px 15px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: transparent;
          border: none;
          outline: none;
          width: 110%;
          color: #6699cc;
          font-size: 18px;

          &::-ms-expand {
            display: none;
          }
        }
      }
    }

    /*.intent-calc .dropdown {
  position: relative;
  display: block;
  overflow: hidden;
  cursor: pointer;
  width: 100%; }
.intent-calc .dropdown::after {
  content: ' ';
  position: absolute;
  right: 2px;
  bottom: 7px;
  width: 40px;
  height: 40px;
  display: block;
  background: url("/Calculators/img/icon-down-arrow.svg") no-repeat 4px 17px #fff;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Calculators/img/icons/icon-down-arrow.svg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icons/icon-down-arrow.svg', sizingMethod='scale')";
  background-size: 15px 9px;
  pointer-events: none;
  margin-top: 30px; }
.intent-calc .dropdown select {
  border: 1px solid #c2d6eb;
  background: white;
  border-radius: 3px;
  padding: 14px 14px 11px;
  width: 100%;
  color: #6699cc;
  font-size: 18px;
  cursor: pointer;
  height: 55px; }
.intent-calc .dropdown select::-ms-expand {
  display: none; }
.intent-calc .dropdown:-moz-any(select) {
  width: 110%; }
.intent-calc .dropdown.ie9 select {
  width: 110%; }
.intent-calc .dropdown select:focus {
  outline: 0; }*/

    .intent-calc__calculate {
      width: 100%;
      text-align: center;

      a {
        display: inline-block;

        img {
          width: 65px;
          height: 65px;
          display: block;
          margin: 0 auto;
          -webkit-transition: -webkit-transform .4s ease-in-out;
          transition: transform .4s ease-in-out;

          &:hover {
            -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
          }
        }
      }
    }

    .intent-calc {
      .input {
        margin-bottom: 20px;

        @include breakpoint(768) {
          margin-bottom: 20px;
        }
      }

      .question-text {
        margin-bottom: 5px;
        display: inline-block;

        &:empty {
          display: none;
        }
      }

      .inline-results {
        padding: 45px 0 45px 0;
        border-bottom: 1px solid #d5dfe8;
        margin-bottom: 40px;

        p {
          @include breakpoint(1109) {
            margin-top: 45px;
          }
        }
      }

      .inline {
        display: inline-block;
        color: #89b6e2;
        margin: 25px 0 0 0;

        &:last-of-type+p {
          margin: 45px 0 0 0;
        }

        .total,
        .equal,
        .cashback,
        .ampersand {
          font-size: em(32px);
          font-weight: 700;
          line-height: 1em;
          font-family: museo-sans-rounded, sans-serif;
        }

        .equal,
        .ampersand {
          padding: 0 10px;
        }

        .yearly-cashback {
          font-size: em(19px);
          font-weight: bold;
        }
      }

      .btn-group {
        margin-bottom: 15px;
      }

      .btn-group--inline {
        display: inline-block;
        margin-right: 15px;

        @include breakpoint(767) {
          width: 100%;
        }
      }

      .payment-results {
        width: calc(50% - 0px);
        float: left;

        @media screen and (max-width: 650px) {
          width: 100%;
        }

        h4.total,
        h4.MonthlyPayment {
          font-size: em(56px);
          color: $cornflower;
          line-height: 1em;
          margin-bottom: 25px;

          @media screen and (max-width: 650px) {
            font-size: 2.25em;
          }
        }
      }

      li {
        font-size: 18px;
        color: #0E2B69;
        line-height: 1.25;
        font-weight: 500;
        position: relative;

        &.question {
          padding-left: 25px;
          background: url(/Calculators/img/icon-help.svg) no-repeat right center transparent;
          background-size: 18px;
          cursor: pointer;
          display: inline-block;
        }
      }

      .radio-btns,
      .checkboxes {
        border: 1px solid transparent;
      }

      .radio-btns label,
      .checkboxes label {
        color: #6f6f6f;
        font-size: 1em;
        margin-top: 0;
        margin-bottom: 0.75em;
        font-weight: 300;
        display: block;
        margin-left: 45px;
      }

      .currency--EUR {
        &:before {}

        input {}
      }

      .currency--STG {
        &:before {
          content: "\00a3";
          float: left;
        }

        input {
          border-radius: 0 3px 3px 0;
          float: left;
          min-height: 46px;
          width: 80%;
        }
      }

      .currency--PCT {
        &:before {
          content: "%";
          font-family: 'open sans';
          float: left;
        }

        input {
          border-radius: 0 3px 3px 0;
          float: left;
          min-height: 46px;
          width: 80%;
        }
      }

      .input-split {
        margin-bottom: 10px;
        display: table;
        height: 100%;
      }

      .input-split--centered input {
        text-align: center;
        padding-left: 0;
      }

      .input-split {
        .text {
          display: table-cell;
          height: 100%;
          vertical-align: middle;
          font-size: 18px;
          margin: 0;
          padding: 0;
          line-height: 1.25;
          color: #0E2B69;
          font-weight: 500;
          padding-left: 10px;
        }

        >input {
          display: table-cell;
          margin-bottom: 0;
        }

        .currency {
          display: table-cell;
        }

        .currency--STG:before,
        .currency--PCT:before,
        .currency--EUR:before {
          width: 25%;
        }

        .currency--STG input,
        .currency--PCT input,
        .currency--EUR input {
          width: 75%;
          margin-bottom: 0;
        }
      }

      .input-split--half {

        >input,
        .currency {
          width: 100%;
        }

        .text {
          width: 50%;
        }
      }

      .input-split--three-qtr {

        >input,
        .currency {
          width: 65%;
        }

        .text {
          width: 35%;
        }
      }

      .estimated-results {
        ul {
          padding-left: 0;
          margin-bottom: 40px;

          li {
            padding-left: 0;
            font-weight: bold;
            color: #0E2B69;
            margin: 0;
            padding: 0;
            line-height: 25px;

            &.question {
              background: url(/Calculators/img/icon-help.svg) no-repeat right 3px transparent;
              background-size: 18px;
              cursor: pointer;
              padding-right: 25px;
            }

            &:before {
              display: none;
            }

            .amount {
              font-weight: bold;
              color: $black;
            }
          }
        }
      }

      .text {
        min-width: 170px;
        display: inline-block;

        @media screen and (max-width: 338px) {
          min-width: 130px;
        }
      }

      .select-container {
        margin-bottom: 25px;
        position: relative;

        &:before {
          @include arrow(left);
          left: calc(100% - 40px);
        }

        &:after {
          @include arrow(right);
          left: calc(100% - 33px);
        }

        >select {
          appearance: none;
          border: 1px solid #cfdbe7;
          border-radius: 3px;
          padding: 12px 15px;
          width: 100%;
        }

        &.selected {
          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }

          >select {
            background-color: rgb(242, 246, 249);
          }
        }
      }
    }

    .intent-calc__results.message.start {
      text-align: center;
      font-weight: bold;
    }

    .ie9 .intent-calc .dropdown {
      background-image: none;
    }

    .intent-calc {
      .btn-sml {
        border-bottom: 3px solid #8c8c8c;
        color: #ffffff;
        background-color: #d6d6d6;
        box-shadow: inset 0 0 0 0 #d6d6d6;
        border-radius: 3px;
        display: inline-block;
        text-decoration: none;
        text-align: left;
        -webkit-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
        margin-right: 16px;
        font-weight: 700;
      }

      .btn-sml:hover {
        color: #ffffff;
      }

      .btn:hover,
      .btn-sml:hover {
        box-shadow: none;
        color: $color-deep;
      }

      .btn-sml:after {
        font-family: FontAwesome;
        float: right;
        content: "\f105";
        margin-left: 10px;
        margin-top: -1px;
      }

      .btn:last-child,
      .btn-sml:last-child {
        margin-right: 0;
      }

      .btn.btn-raised,
      .btn-sml.btn-raised {
        margin-bottom: 20px;
      }

      .btn .info,
      .btn-sml .info {
        font-size: 0.8125em;
        font-weight: 100;
      }

      .btn {
        // padding: 13px 20px 13px 20px;
        font-size: 16px;

        &.btn-ghost--frameless {
          padding-bottom: 12px;
          box-shadow: none;
        }
      }

      .btn-right {
        float: right;
      }

      .btn-left {
        float: left;
      }

      .btn.btn-ghost--highlight {
        padding-top: 12px;
        padding-bottom: 11px;
      }

      .btn-sml {
        padding: 11px 18px 8px;
        font-size: 14px;

        &.btn-ghost--frameless {
          padding-bottom: 11px;
          box-shadow: none;
        }

        &.btn-ghost--highlight {
          padding-top: 8px;
          padding-bottom: 10px;
        }
      }

      .btn-form {
        padding: 0 10px;
        height: 2.1875em;
      }

      .btn-ghost {
        background: none;
        border: 2px solid #d6d6d6;
        color: #d6d6d6;
        box-shadow: inset 0 0 0 0 transparent;
        padding: 13px 20px 9px;
      }

      .btn-ghost--frameless {
        border-width: 0;
      }

      .btn-ghost:hover {
        color: #ffffff;
        box-shadow: inset 0 -100px 0 0 #d6d6d6;
      }

      .btn-nochev {
        text-align: center;

        &:after {
          content: '';
        }
      }

      .btn-revchev:after {
        float: left;
        content: "\f104";
        margin-right: 10px;
        margin-left: 0;
        margin-top: -1px;
      }

      .btn-half-width {
        width: 50%;
      }

      .btn-group {}

      .btn-orange {
        background-color: #EC7403;
        border-color: #b45a06;
        color: #ffffff;
        box-shadow: inset 0 0 0 0 #EC7403;
        transition: all .8s ease;

        &:hover {
          box-shadow: inset 0 -100px 0 0 #b45a06;
          color: #ffffff;
        }

        &.btn-ghost {
          background: transparent;
          border-color: #EC7403;
          color: #EC7403;

          &:hover {
            box-shadow: inset 0 -100px 0 0 #EC7403;
            color: #ffffff;
          }

          &.btn-sml {
            padding: 9px 18px 7px;
          }
        }

        &.btn-txt-link {
          background-color: transparent;
          border-color: transparent;
          color: #EC7403;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: #EC7403;
          }
        }
      }

      .btn-lt-blue {
        background-color: #89b6e2;
        border-color: #6699cc;
        color: #ffffff;
        box-shadow: inset 0 0 0 0 #89b6e2;

        &:hover {
          box-shadow: inset 0 -100px 0 0 #6699cc;
        }

        &.btn-ghost {
          background: transparent;
          border-color: #89b6e2;
          color: #89b6e2;

          &:hover {
            box-shadow: inset 0 -100px 0 0 #89b6e2;
            color: #ffffff;
          }

          &.btn-sml {
            padding: 9px 18px 7px;
          }
        }

        &.btn-txt-link {
          background-color: transparent;
          border-color: transparent;
          color: #89b6e2;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: #89b6e2;
          }
        }
      }

      .btn-dk-blue {
        background-color: #6699cc;
        border-color: #4e79a4;
        color: #ffffff;
        box-shadow: inset 0 0 0 0 #6699cc;

        &:hover {
          box-shadow: inset 0 -100px 0 0 #4e79a4;
        }

        &.btn-ghost {
          background: transparent;
          border-color: #6699cc;
          color: #6699cc;

          &:hover {
            box-shadow: inset 0 -100px 0 0 #6699cc;
            color: #ffffff;
          }

          &.btn-sml {
            padding: 9px 18px 7px;
          }
        }

        &.btn-txt-link {
          background-color: transparent;
          border-color: transparent;
          color: #6699cc;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: #6699cc;
          }
        }
      }

      .btn-deep-blue {
        background-color: #0E2B69;
        border-color: #091c45;
        color: #ffffff;
        box-shadow: inset 0 0 0 0 #0E2B69;

        &:hover {
          box-shadow: inset 0 -100px 0 0 #091c45;
        }

        &.btn-ghost {
          background: transparent;
          border-color: #0E2B69;
          color: #0E2B69;

          &:hover {
            box-shadow: inset 0 -100px 0 0 #0E2B69;
            color: #ffffff;
          }

          &.btn-sml {
            padding: 9px 18px 7px;
          }
        }

        &.btn-txt-link {
          background-color: transparent;
          border-color: transparent;
          color: #0E2B69;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: #0E2B69;
          }
        }
      }

      .btn-grey-blue {
        background-color: #e7ecf1;
        border-color: #737b8d;
        color: #ffffff;
        box-shadow: inset 0 0 0 0 #e7ecf1;

        &:hover {
          box-shadow: inset 0 -100px 0 0 #737b8d;
        }

        &.btn-ghost {
          background: transparent;
          border-color: #e7ecf1;
          color: #e7ecf1;

          &:hover {
            box-shadow: inset 0 -100px 0 0 #e7ecf1;
            color: #ffffff;
          }

          &.btn-sml {
            padding: 9px 18px 7px;
          }
        }

        &.btn-txt-link {
          background-color: transparent;
          border-color: transparent;
          color: #e7ecf1;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: #e7ecf1;
          }
        }
      }

      .btn-white {
        background-color: #ffffff;
        border-color: #cccccc;
        color: #EC7403;
        box-shadow: inset 0 0 0 0 #ffffff;

        &:hover {
          box-shadow: inset 0 -100px 0 0 #cccccc;
        }

        &.btn-ghost {
          background: transparent;
          border-color: #ffffff;
          color: #ffffff;

          &:hover {
            box-shadow: inset 0 -100px 0 0 #ffffff;
            color: #EC7403;
          }

          &.btn-sml {
            padding: 9px 18px 7px;
          }
        }

        &.btn-txt-link {
          background-color: transparent;
          border-color: transparent;
          color: #ffffff;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
            border-color: transparent;
            color: #ffffff;
          }
        }
      }

      .load_btn img {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        background: transparent;

        &.loading {
          -moz-animation: rotating 0.7s linear infinite;
          -webkit-animation: rotating 0.7s linear infinite;
          -o-animation: rotating 0.7s linear infinite;
          animation: rotating 0.7s linear infinite;
        }
      }
    }

    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(360deg);
      }

      to {
        -webkit-transform: rotate(0deg);
      }
    }

    @-moz-keyframes rotating {
      from {
        -moz-transform: rotate(360deg);
      }

      to {
        -moz-transform: rotate(0deg);
      }
    }

    @-o-keyframes rotating {
      from {
        -moz-transform: rotate(360deg);
      }

      to {
        -moz-transform: rotate(0deg);
      }
    }

    .btn-stacked {

      .btn,
      .btn-sml {
        display: block;
        margin-bottom: 1em;
      }

      .btn:last-child,
      .btn-sml:last-child {
        margin-bottom: 0;
      }
    }

    .btns-full-width {

      .btn,
      .btn-sml {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
      }
    }

    .intent-calc {

      .btn.btn-calc,
        {
        background-color: #fff;
        border: 1px solid #cfdbe7;
        border-radius: 0;
        color: #0e2b69;
        float: left;
        margin: 0 0 25px;
        min-width: 130px;
        outline: none;
        position: relative;
        text-align: center;
        transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 1;
        padding-right: 20px !important;
        padding-left: 20px !important;
        display: block;

        &:after {
          content: "";
        }

        &:first-of-type {
          float: left;
          border-radius: 3px 0 0 3px;
        }

        &:nth-of-type(2) {
          float: left;
          border-left: none !important;
        }

        &:last-of-type {
          float: left;
          border-left: none !important;
          border-radius: 0 3px 3px 0;
        }

        &:hover {
          box-shadow: none;
          color: #0e2b69;
        }

        &.active {
          background: #e9eef2;
          background: -moz-linear-gradient(top, #e9eef2 0%, #f5f8fa 100%);
          background: -webkit-linear-gradient(top, #e9eef2 0%, #f5f8fa 100%);
          background: linear-gradient(to bottom, #e9eef2 0%, #f5f8fa 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9eef2', endColorstr='#f5f8fa', GradientType=0);
          color: #ec7403;
          position: relative;
          border: 1px solid #cfdbe7;

          &:hover {
            box-shadow: none;
            color: #ec7403;
          }

          &:after {
            content: "";
            top: 14px;
            font-family: FontAwesome;
            position: absolute;
            right: 15px;
          }

          &:before {
            content: "";
            background-color: #f2f6f9;
            border-right: 1px solid #cfdbe7;
            border-bottom: 1px solid #cfdbe7;
            border-color: #cfdbe7;
            bottom: -10px;
            height: 20px;
            left: calc(50% - 10px);
            opacity: 0;
            position: absolute;
            transform: rotate(45deg);
            width: 20px;
            z-index: -1;
            opacity: 1;
          }
        }
      }

      .results-download {
        cursor: pointer;

        @media screen and (max-width: 595px) {
          margin-top: 35px;
        }
      }

      .chart {
        margin: 20px;
        font-size: 12px;
      }

      .chart__row {
        margin-bottom: 10px;
      }

      .chart__value {
        width: 33%;
        float: left;

        p {
          font-size: 12px;
          text-align: right;
          color: #EC7403 !important;
          line-height: 1.5;
          padding: 3px 0 0 0 !important;
          margin: 0;

          span {
            display: block;
            color: #8e8e8e !important;
          }
        }
      }

      .chart__bar {
        width: 63%;
        float: right;
      }

      .chart__bar--val-1 {
        display: block;
        width: 1%;
      }

      .chart__bar--val-2 {
        display: block;
        width: 2%;
      }

      .chart__bar--val-3 {
        display: block;
        width: 3%;
      }

      .chart__bar--val-4 {
        display: block;
        width: 4%;
      }

      .chart__bar--val-5 {
        display: block;
        width: 5%;
      }

      .chart__bar--val-6 {
        display: block;
        width: 6%;
      }

      .chart__bar--val-7 {
        display: block;
        width: 7%;
      }

      .chart__bar--val-8 {
        display: block;
        width: 8%;
      }

      .chart__bar--val-9 {
        display: block;
        width: 9%;
      }

      .chart__bar--val-10 {
        display: block;
        width: 10%;
      }

      .chart__bar--val-11 {
        display: block;
        width: 11%;
      }

      .chart__bar--val-12 {
        display: block;
        width: 12%;
      }

      .chart__bar--val-13 {
        display: block;
        width: 13%;
      }

      .chart__bar--val-14 {
        display: block;
        width: 14%;
      }

      .chart__bar--val-15 {
        display: block;
        width: 15%;
      }

      .chart__bar--val-16 {
        display: block;
        width: 16%;
      }

      .chart__bar--val-17 {
        display: block;
        width: 17%;
      }

      .chart__bar--val-18 {
        display: block;
        width: 18%;
      }

      .chart__bar--val-19 {
        display: block;
        width: 19%;
      }

      .chart__bar--val-20 {
        display: block;
        width: 20%;
      }

      .chart__bar--val-21 {
        display: block;
        width: 21%;
      }

      .chart__bar--val-22 {
        display: block;
        width: 22%;
      }

      .chart__bar--val-23 {
        display: block;
        width: 23%;
      }

      .chart__bar--val-24 {
        display: block;
        width: 24%;
      }

      .chart__bar--val-25 {
        display: block;
        width: 25%;
      }

      .chart__bar--val-26 {
        display: block;
        width: 26%;
      }

      .chart__bar--val-27 {
        display: block;
        width: 27%;
      }

      .chart__bar--val-28 {
        display: block;
        width: 28%;
      }

      .chart__bar--val-29 {
        display: block;
        width: 29%;
      }

      .chart__bar--val-30 {
        display: block;
        width: 30%;
      }

      .chart__bar--val-31 {
        display: block;
        width: 31%;
      }

      .chart__bar--val-32 {
        display: block;
        width: 32%;
      }

      .chart__bar--val-33 {
        display: block;
        width: 33%;
      }

      .chart__bar--val-34 {
        display: block;
        width: 34%;
      }

      .chart__bar--val-35 {
        display: block;
        width: 35%;
      }

      .chart__bar--val-36 {
        display: block;
        width: 36%;
      }

      .chart__bar--val-37 {
        display: block;
        width: 37%;
      }

      .chart__bar--val-38 {
        display: block;
        width: 38%;
      }

      .chart__bar--val-39 {
        display: block;
        width: 39%;
      }

      .chart__bar--val-40 {
        display: block;
        width: 40%;
      }

      .chart__bar--val-41 {
        display: block;
        width: 41%;
      }

      .chart__bar--val-42 {
        display: block;
        width: 42%;
      }

      .chart__bar--val-43 {
        display: block;
        width: 43%;
      }

      .chart__bar--val-44 {
        display: block;
        width: 44%;
      }

      .chart__bar--val-45 {
        display: block;
        width: 45%;
      }

      .chart__bar--val-46 {
        display: block;
        width: 46%;
      }

      .chart__bar--val-47 {
        display: block;
        width: 47%;
      }

      .chart__bar--val-48 {
        display: block;
        width: 48%;
      }

      .chart__bar--val-49 {
        display: block;
        width: 49%;
      }

      .chart__bar--val-50 {
        display: block;
        width: 50%;
      }

      .chart__bar--val-51 {
        display: block;
        width: 51%;
      }

      .chart__bar--val-52 {
        display: block;
        width: 52%;
      }

      .chart__bar--val-53 {
        display: block;
        width: 53%;
      }

      .chart__bar--val-54 {
        display: block;
        width: 54%;
      }

      .chart__bar--val-55 {
        display: block;
        width: 55%;
      }

      .chart__bar--val-56 {
        display: block;
        width: 56%;
      }

      .chart__bar--val-57 {
        display: block;
        width: 57%;
      }

      .chart__bar--val-58 {
        display: block;
        width: 58%;
      }

      .chart__bar--val-59 {
        display: block;
        width: 59%;
      }

      .chart__bar--val-60 {
        display: block;
        width: 60%;
      }

      .chart__bar--val-61 {
        display: block;
        width: 61%;
      }

      .chart__bar--val-62 {
        display: block;
        width: 62%;
      }

      .chart__bar--val-63 {
        display: block;
        width: 63%;
      }

      .chart__bar--val-64 {
        display: block;
        width: 64%;
      }

      .chart__bar--val-65 {
        display: block;
        width: 65%;
      }

      .chart__bar--val-66 {
        display: block;
        width: 66%;
      }

      .chart__bar--val-67 {
        display: block;
        width: 67%;
      }

      .chart__bar--val-68 {
        display: block;
        width: 68%;
      }

      .chart__bar--val-69 {
        display: block;
        width: 69%;
      }

      .chart__bar--val-70 {
        display: block;
        width: 70%;
      }

      .chart__bar--val-71 {
        display: block;
        width: 71%;
      }

      .chart__bar--val-72 {
        display: block;
        width: 72%;
      }

      .chart__bar--val-73 {
        display: block;
        width: 73%;
      }

      .chart__bar--val-74 {
        display: block;
        width: 74%;
      }

      .chart__bar--val-75 {
        display: block;
        width: 75%;
      }

      .chart__bar--val-76 {
        display: block;
        width: 76%;
      }

      .chart__bar--val-77 {
        display: block;
        width: 77%;
      }

      .chart__bar--val-78 {
        display: block;
        width: 78%;
      }

      .chart__bar--val-79 {
        display: block;
        width: 79%;
      }

      .chart__bar--val-80 {
        display: block;
        width: 80%;
      }

      .chart__bar--val-81 {
        display: block;
        width: 81%;
      }

      .chart__bar--val-82 {
        display: block;
        width: 82%;
      }

      .chart__bar--val-83 {
        display: block;
        width: 83%;
      }

      .chart__bar--val-84 {
        display: block;
        width: 84%;
      }

      .chart__bar--val-85 {
        display: block;
        width: 85%;
      }

      .chart__bar--val-86 {
        display: block;
        width: 86%;
      }

      .chart__bar--val-87 {
        display: block;
        width: 87%;
      }

      .chart__bar--val-88 {
        display: block;
        width: 88%;
      }

      .chart__bar--val-89 {
        display: block;
        width: 89%;
      }

      .chart__bar--val-90 {
        display: block;
        width: 90%;
      }

      .chart__bar--val-91 {
        display: block;
        width: 91%;
      }

      .chart__bar--val-92 {
        display: block;
        width: 92%;
      }

      .chart__bar--val-93 {
        display: block;
        width: 93%;
      }

      .chart__bar--val-94 {
        display: block;
        width: 94%;
      }

      .chart__bar--val-95 {
        display: block;
        width: 95%;
      }

      .chart__bar--val-96 {
        display: block;
        width: 96%;
      }

      .chart__bar--val-97 {
        display: block;
        width: 97%;
      }

      .chart__bar--val-98 {
        display: block;
        width: 98%;
      }

      .chart__bar--val-99 {
        display: block;
        width: 99%;
      }

      .chart__bar--val-100 {
        display: block;
        width: 100%;
      }

      .ico--house h2 {
        padding-left: 62px;
        line-height: 42px;
      }

      .ico--house h4 {
        padding-left: 75px;
        line-height: 1.25;
        background-size: 50px;
        padding-top: 20px;
      }

      .ico--coins {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-coins.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-coins.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-coins.svg', sizingMethod='scale')";
          background-size: 60px 58px;
          min-height: 55px;
        }
      }

      .ico--piggy {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-piggy.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-piggy.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-piggy.svg', sizingMethod='scale')";
          background-size: 58px 58px;
          min-height: 55px;
          background-position-y: -3px;
        }
      }

      .ico--house {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-house.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house.svg', sizingMethod='scale')";
          background-size: 60px 60px;
          min-height: 55px;
        }
      }

      .ico--houseQuestion {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-house-question.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/img/icon-house-question.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/img/icon-house-question.svg', sizingMethod='scale')";
          background-size: 250px 250px;
          min-height: 65px;
        }
      }

      .ico--housePercent {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-house-percent.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house-percent.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-house-percent.svg', sizingMethod='scale')";
          background-size: 250px 250px;
          min-height: 65px;
        }
      }

      .ico--car {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-car.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-car.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-car.svg', sizingMethod='scale')";
          background-size: 61px 28px;
          min-height: 55px;
          background-position-y: 9px;
        }
      }

      .ico--shield {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-shield.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-shield.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-shield.svg', sizingMethod='scale')";
          background-size: 44px 58px;
          min-height: 59px;
          background-position-x: 8px;
        }
      }

      .ico--handshake {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-handshake.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-handshake.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-handshake.svg', sizingMethod='scale')";
          background-size: 60px 35px;
          background-position-y: 5px;
          min-height: 55px;
        }
      }

      .ico--credit-card {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-credit-card.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-credit-card.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-credit-card.svg', sizingMethod='scale')";
          background-size: 64px 64px;
          background-position-y: -7px;
          min-height: 55px;
        }
      }

      .ico--wallet {

        h2,
        h3,
        h4,
        h5 {
          background: transparent url(/Calculators/img/icon-wallet-orange.svg) no-repeat left top;
          filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-wallet-orange.svg', sizingMethod='scale');
          -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Calculators/img/icon-wallet-orange.svg', sizingMethod='scale')";
          min-height: 55px;
          background-size: 80px 80px;
        }
      }

      .flag--EUR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/EUR.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--GBP .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/GBP.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--USD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/USD.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--JPY .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/JPY.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--AUD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/AUD.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--TRY .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/TRY.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--CAD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/CAD.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--CZK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/CZK.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--HUF .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/HUF.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--HKD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/HKD.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--LVL .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/LVL.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--NZD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/NZD.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--NOK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/NOK.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--ZAR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/ZAR.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--SAR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/SAR.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--CHF .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/CHF.png) no-repeat center top transparent;
        background-size: 50px;
      }

      .flag--JPY .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/JPY.png) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list {
        padding: 0 10px;

        li {
          padding-left: 50px;
          text-align: left;
          min-height: 30px;

          &:not(:last-child) {
            border-bottom: 1px solid #e7ecf1;
            padding-bottom: 5px;
            margin-bottom: 15px;
          }

          .code {
            color: #0E2B69;
            font-weight: 500;
          }

          h4 {
            font-size: 18px;
            color: #6699cc;
          }

          &:before {
            display: none;
          }
        }
      }

      .flag--AED .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/AED.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.AED {
        background: url(/Calculators/img/flags/AED.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--AUD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/AUD.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.AUD {
        background: url(/Calculators/img/flags/AUD.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--BGN .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/BGN.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.BGN {
        background: url(/Calculators/img/flags/BGN.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--CAD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/CAD.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.CAD {
        background: url(/Calculators/img/flags/CAD.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--CHF .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/CHF.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.CHF {
        background: url(/Calculators/img/flags/CHF.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--CZK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/CZK.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.CZK {
        background: url(/Calculators/img/flags/CZK.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--DKK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/DKK.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.DKK {
        background: url(/Calculators/img/flags/DKK.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--EGP .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/EGP.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.EGP {
        background: url(/Calculators/img/flags/EGP.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--EUR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/EUR.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.EUR {
        background: url(/Calculators/img/flags/EUR.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--GBP .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/GBP.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.GBP {
        background: url(/Calculators/img/flags/GBP.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--HKD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/HKD.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.HKD {
        background: url(/Calculators/img/flags/HKD.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--HRK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/HRK.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.HRK {
        background: url(/Calculators/img/flags/HRK.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--HUF .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/HUF.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.HUF {
        background: url(/Calculators/img/flags/HUF.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--ILS .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/ILS.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.ILS {
        background: url(/Calculators/img/flags/ILS.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--JPY .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/JPY.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.JPY {
        background: url(/Calculators/img/flags/JPY.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--LVL .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/LVL.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.LVL {
        background: url(/Calculators/img/flags/LVL.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--MYR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/MYR.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.MYR {
        background: url(/Calculators/img/flags/MYR.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--NOK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/NOK.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.NOK {
        background: url(/Calculators/img/flags/NOK.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--NZD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/NZD.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.NZD {
        background: url(/Calculators/img/flags/NZD.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--PLN .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/PLN.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.PLN {
        background: url(/Calculators/img/flags/PLN.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--SAR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/SAR.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.SAR {
        background: url(/Calculators/img/flags/SAR.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--SEK .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/SEK.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.SEK {
        background: url(/Calculators/img/flags/SEK.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--SGD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/SGD.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.SGD {
        background: url(/Calculators/img/flags/SGD.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--THB .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/THB.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.THB {
        background: url(/Calculators/img/flags/THB.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--TRY .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/TRY.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.TRY {
        background: url(/Calculators/img/flags/TRY.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--USD .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/USD.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.USD {
        background: url(/Calculators/img/flags/USD.svg) no-repeat left top transparent;
        background-size: 40px;
      }

      .flag--ZAR .flag-icon {
        height: 40px;
        background: url(/Calculators/img/flags/ZAR.svg) no-repeat center top transparent;
        background-size: 50px;
      }

      ul.popular-currency-list li.ZAR {
        background: url(/Calculators/img/flags/ZAR.svg) no-repeat left top transparent;
        background-size: 40px;
      }
    }

    p.question {
      position: relative;

      .tooltip {
        display: none;
        width: 250px;
        background-color: #FFFFFF;
        color: #0E2B69;
        font-size: 14px;
        line-height: 1.3;
        padding: 0.25em 1.25em 1.25em 1.25em;
        margin-left: auto;
        margin-right: auto;
        border-radius: 3px;
        position: absolute;
        z-index: 100;
        border-radius: 6px;
        box-shadow: 4px 6px 17px #888888;
        left: auto;
        right: -14px;
        margin-bottom: 20px;
        bottom: 50%;

        &.instruct {
          .tooltiphead {
            display: block;
            font-size: 18px;
            padding-bottom: 10px;
            padding-top: 10px;
          }

          a {
            background-color: #EC7403;
            border-color: #B45A06;
            color: #FFF;
            border-radius: 4px;
            box-shadow: 1px 3px 0px #B45A06;
            display: block;
            text-align: center;
            padding: 10px 10px 10px 10px;
            margin: 10px 0px 5px 0px;
          }

          p {
            margin-bottom: 5px;
          }

          &.hidden {
            display: none;
          }
        }

        &.minMax {
          margin-left: auto;
          margin-right: auto;
          top: -150px;
          right: 0;
        }

        &:after {
          right: 15px;
          bottom: -15px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: transparent;
          border-top-color: #ffffff;
          border-width: 8px;
          margin-top: -8px;
        }
      }

      &.half {
        display: inline-block;
        width: 43%;
        margin-top: 14px;
        background-position-x: 51px;

        .tooltip {
          left: 0;
          bottom: 50%;
          margin-left: auto;
          margin-right: auto;

          &:after {
            left: 52px;
          }
        }
      }
    }

    /*# sourceMappingURL=intent-calc.css.map */

    .modal_bg {
      background: #000;
      opacity: 0.5;
      position: fixed;
      z-index: 100;
      width: 100%;
      height: 100%;
    }

    .modal_tooltip {
      background: #FFF;
      padding: 20px;
      opacity: 1;
      position: fixed;
      left: 50%;
      top: 130px;
      z-index: 150;
      box-sizing: border-box;

      p {
        font-size: 16px;
        line-height: 1.2;
        margin-top: 0;
      }

      .btn {
        width: 100%;
      }
    }

    .tooltip {
      display: none;
      width: 250px;
      background-color: #FFFFFF;
      color: #0E2B69;
      font-size: 14px;
      line-height: 1.3;
      padding: 0.25em 1.25em 1.25em 1.25em;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      border-radius: 3px;
      position: absolute;
      bottom: 50%;
      z-index: 100;
      border-radius: 6px;
      box-shadow: 4px 6px 17px #888888;

      &.instruct {
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;
        right: -13px;

        .tooltiphead {
          display: block;
          font-size: 18px;
          padding-bottom: 10px;
          padding-top: 10px;
        }

        a,
        span a {
          background-color: transparent;
          border-color: transparent;
          color: #89b6e2;
          border-radius: 0;
          box-shadow: 0 0 0 transparent;
          display: inline;
          text-align: left;
          padding: 0;
          margin: 0;
        }

        a.toolbutton {
          background-color: #EC7403;
          border-color: #B45A06;
          color: #FFF;
          border-radius: 4px;
          box-shadow: 1px 3px 0px #B45A06;
          display: block;
          text-align: center;
          padding: 10px 10px 10px 10px;
          margin: 10px 0px 5px 0px;
        }

        p {
          margin-bottom: 5px;
        }

        &.hidden {
          display: none;
        }
      }

      &.minMax {
        margin-left: auto;
        margin-right: auto;
        top: -150px;
        right: 0;
      }

      &:after {
        right: 15px;
        bottom: -15px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: #ffffff;
        border-width: 8px;
        margin-top: -8px;
      }
    }

    .intent-calc {
      .slide_wrap {
        margin-top: 45px;
        position: relative;
        z-index: 0;

        &:before,
        &:after {
          color: #9ca4b6;
          font-size: 14px;
          width: 4px;
          height: 20px;
          position: absolute;
          bottom: -30px;
        }

        .ui-slider-horizontal {
          height: 0.5em;
        }

        .ui-widget-content {
          border: 0;
          background: #EC7403;
          z-index: 10;
          cursor: pointer;
        }

        .ui-widget-header {
          background: #9ca4b6;
        }

        .marker {
          width: 2px;
          height: 29px;
          content: "";
          background-color: #9ca4b6;
          position: absolute;
          top: -10px;
          z-index: 5;
          border-radius: 50;
        }
      }

      .ui-state-default {
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        -o-border-radius: 50px;
        background: #ffffff;
        border-radius: 50px;
        height: 22px;
        width: 22px;
        margin-left: -12px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
        border: 1px solid #CCC;
        top: -8px;
        cursor: pointer;
        outline: none;
      }

      .ui-state-hover,
      .ui-state-active {
        background: #478dd2;
        border: 1px solid #CCC;
        outline: none;
      }
    }

    .loan-calc {
      &.intent-calc {
        border: 0;
      }

      .star {
        color: #EC7403;
      }

      section {
        border: 1px solid #c2d6eb;

        &:first-child {
          border-bottom: 0;
          border-top-right-radius: 3px;
          border-top-left-radius: 3px;
        }

        &:last-of-type {
          border-top: 0;
          border: 1px solid #c2d6eb;
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &.intent-calc__tabs {
          *zoom: 1;
          width: 100%;
          padding: 0;
          border: 0;
          position: relative;
          z-index: 0;

          &:before {
            content: " ";
            display: block !important;
          }

          &:after {
            content: " ";
            display: block !important;
            clear: both;
          }
        }
      }

      .bkg-col--mystic.flash,
      .intent-calc__calculate.flash {
        border: 0;
        border-left: 1px solid #6f6f6f;
        border-right: 1px solid #6f6f6f;
      }

      .bkg-col--dk-blue.flash {
        border: 0;
        border-left: 1px solid #6f6f6f;
        border-right: 1px solid #6f6f6f;
        border-bottom: 1px solid #6f6f6f;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .tab {
        width: 50%;
        height: 100px;
        display: inline-block;
        margin: 0;
        float: left;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        position: relative;
        z-index: 10;

        &.inactive {
          background: #b3c3d3;
          position: relative;
          z-index: 5;
          overflow: hidden;

          &.tab-left {
            border-top: 1px solid rgba(111, 111, 111, 0.5);
            border-left: 1px solid rgba(111, 111, 111, 0.5);
            border-bottom: 1px solid #6f6f6f;
            border-right: 0;
          }

          &.tab-right {
            border-top: 1px solid rgba(111, 111, 111, 0.5);
            border-left: 0;
            border-bottom: 1px solid #6f6f6f;
            border-right: 1px solid rgba(111, 111, 111, 0.5);
          }
        }

        &.tab-left,
        &.tab-right {
          border-top: 1px solid #6f6f6f;
          border-right: 1px solid #6f6f6f;
          border-left: 1px solid #6f6f6f;
          border-bottom: 0;
        }
      }

      .intent-calc__inputs {
        .tooltip {
          display: none;
          width: 250px;
          background-color: #FFFFFF;
          color: #0E2B69;
          font-size: 14px;
          line-height: 1.3;
          padding: 0.25em 1.25em 1.25em 1.25em;
          margin-left: auto;
          margin-right: auto;
          border-radius: 3px;
          position: absolute;
          z-index: 100;
          border-radius: 6px;
          box-shadow: 4px 6px 17px #888888;
          left: auto;
          right: -14px;

          &.instruct {
            .tooltiphead {
              display: block;
              font-size: 18px;
              padding-bottom: 10px;
              padding-top: 10px;
            }

            a {
              background-color: #EC7403;
              border-color: #B45A06;
              color: #FFF;
              border-radius: 4px;
              box-shadow: 1px 3px 0px #B45A06;
              display: block;
              text-align: center;
              padding: 10px 10px 10px 10px;
              margin: 10px 0px 5px 0px;
            }

            p {
              margin-bottom: 5px;
            }

            &.hidden {
              display: none;
            }
          }

          &.minMax {
            margin-left: auto;
            margin-right: auto;
            top: -150px;
            right: 0;
          }

          &:after {
            right: 15px;
            bottom: -15px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-top-color: #ffffff;
            border-width: 8px;
            margin-top: -8px;
          }
        }

        &.hidden {
          display: none;
        }

        &.dropdown-leg:after {
          margin-top: 4px;
          top: 1px;
          bottom: 0px;
          right: 2px;
          width: 30px;
          background-position-x: 8px;
        }

        &.input_pay,
        &.input_loan {
          position: relative;
        }
      }

      input.noMargin {
        margin-bottom: 0px;
      }

      .input-split {
        width: 100%;
      }

      .input-split--half .text {
        padding-left: 0px;
      }

      .currency input.highlight {
        border-radius: 3px;
        border: 2px solid #EC7403;
      }

      p.question {
        position: relative;

        .tooltip {
          display: none;
          width: 250px;
          background-color: #FFFFFF;
          color: #0E2B69;
          font-size: 14px;
          line-height: 1.3;
          padding: 0.25em 1.25em 1.25em 1.25em;
          margin-left: auto;
          margin-right: auto;
          border-radius: 3px;
          position: absolute;
          z-index: 100;
          border-radius: 6px;
          box-shadow: 4px 6px 17px #888888;
          left: auto;
          right: -14px;

          &.instruct {
            .tooltiphead {
              display: block;
              font-size: 18px;
              padding-bottom: 10px;
              padding-top: 10px;
            }

            a {
              background-color: #EC7403;
              border-color: #B45A06;
              color: #FFF;
              border-radius: 4px;
              box-shadow: 1px 3px 0px #B45A06;
              display: block;
              text-align: center;
              padding: 10px 10px 10px 10px;
              margin: 10px 0px 5px 0px;
            }

            p {
              margin-bottom: 5px;
            }

            &.hidden {
              display: none;
            }
          }

          &.minMax {
            margin-left: auto;
            margin-right: auto;
            top: -150px;
            right: 0;
          }

          &:after {
            right: 15px;
            bottom: -15px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-top-color: #ffffff;
            border-width: 8px;
            margin-top: -8px;
          }
        }
      }

      .ico--car h4 {
        padding-top: 13px;
      }

      .ico--piggy h4 {
        padding-top: 7px;
      }

      .ico--handshake h4 {
        padding-top: 10px;
      }

      .ico--shield h4 {
        padding-top: 12px;
      }

      #year_label {
        display: block;
        width: 100%;
      }

      p.question.half {
        display: inline-block;
        width: 43%;
        margin-top: 14px;
        background-position-x: 51px;

        .tooltip {
          left: 0;
          bottom: 50%;
          margin-left: auto;
          margin-right: auto;

          &:after {
            left: 52px;
          }
        }
      }

      span#year_wrap {
        width: 50%;
        display: inline-block;
        float: right;

        &:after {
          width: 50%;
          background: #b3c3d3;
          border-radius: 0 3px 3px 0;
          color: #ffffff;
          font-size: 1.125em;
          font-weight: 500;
          position: relative;
          text-align: center;
          content: "years";
          float: right;
          height: 55px;
          line-height: 55px;
        }

        input {
          width: 50%;
          border-radius: 3px 0 0 3px;
        }
      }

      .slide_wrap {
        margin-top: 45px;
        position: relative;

        &:before,
        &:after {
          color: #9ca4b6;
          font-size: 14px;
          width: 4px;
          height: 20px;
          position: absolute;
          bottom: -30px;
        }

        &:before {
          content: "1";
          left: 1%;
          margin-left: -3px;
        }

        &:after {
          content: "5";
          right: 1%;
        }

        .ui-slider-horizontal {
          height: 0.5em;
        }

        .ui-widget-content {
          border: 0;
          background: #EC7403;
          z-index: 10;
          cursor: pointer;
        }

        .ui-widget-header {
          background: #9ca4b6;
        }

        .marker {
          width: 2px;
          height: 29px;
          content: "";
          background-color: #9ca4b6;
          position: absolute;
          top: -10px;
          z-index: 5;
          border-radius: 50;
        }
      }

      .ui-state-default {
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        -o-border-radius: 50px;
        background: #ffffff;
        border-radius: 50px;
        height: 22px;
        width: 22px;
        margin-left: -12px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
        border: 1px solid #CCC;
        top: -8px;
        cursor: pointer;
        outline: none;
      }

      .ui-state-hover,
      .ui-state-active {
        background: #478dd2;
        border: 1px solid #CCC;
        outline: none;
      }

      .load_btn img.loading {
        -moz-animation: rotating 0.7s linear infinite;
        -webkit-animation: rotating 0.7s linear infinite;
        -o-animation: rotating 0.7s linear infinite;
        animation: rotating 0.7s linear infinite;
      }

      .bkg-col--dk-blue.flash {
        padding-top: 5px;
      }

      .intent-calc__results {
        margin-top: 0px;

        &.hidden {
          display: none;
        }

        h2 {
          display: inline-block;
          font-size: 2.625em;
          font-weight: 400;
          text-shadow: 2px 2px 2px #6f6f6f;
          margin: 0;
          padding-top: 10px;
        }

        #h2_sub {
          color: #ffffff;
          display: block;
          position: relative;
          margin: -8px 0 10px;
        }

        .switch {
          color: #ffffff;
          font-weight: 100;
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: #e7ecf1;
          }

          span {
            position: relative;

            &:before {
              content: "";
              color: #FFF;
              height: 17px;
              width: 30px;
              background: transparent url(/Calculators/img/icon-pen.svg) no-repeat center center;
              position: absolute;
              left: -32px;
              top: 2px;
            }
          }
        }
      }

      .btn-group {
        margin-top: 25px;
      }

      .alt_loan_links {
        *zoom: 1;
        width: 100%;

        &:before {
          content: " ";
          display: block !important;
        }

        &:after {
          content: " ";
          display: block !important;
          clear: both;
        }

        a {
          color: #FFFFFF;
          text-decoration: underline;
          font-weight: 100;
          padding: 0px 10px 10px 10px;

          &.alt_left {
            float: left;
            padding-left: 1.5%;
            padding-right: 0;
          }

          &.alt_right {
            float: right;
            padding-right: 1.5%;
            padding-left: 0;
          }

          &:hover {
            color: #e7ecf1;
          }
        }
      }
    }

    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(360deg);
      }

      to {
        -webkit-transform: rotate(0deg);
      }
    }

    @-moz-keyframes rotating {
      from {
        -moz-transform: rotate(360deg);
      }

      to {
        -moz-transform: rotate(0deg);
      }
    }

    @-o-keyframes rotating {
      from {
        -moz-transform: rotate(360deg);
      }

      to {
        -moz-transform: rotate(0deg);
      }
    }

    #ccard-calc {
      .intent-calc__inputs .tooltip {
        display: none;
        width: 250px;
        background-color: #FFFFFF;
        color: #0E2B69;
        font-size: 14px;
        line-height: 1.3;
        padding: 0.25em 1.25em 1.25em 1.25em;
        margin-left: auto;
        margin-right: auto;
        border-radius: 3px;
        position: absolute;
        z-index: 100;
        border-radius: 6px;
        box-shadow: 4px 6px 17px #888888;
        left: auto;
        right: -14px;
        cursor: initial;

        &.instruct {
          .tooltiphead {
            display: block;
            font-size: 18px;
            padding-bottom: 10px;
            padding-top: 10px;
          }

          a,
          span a {
            background-color: transparent;
            border-color: transparent;
            color: #89b6e2;
            border-radius: 0;
            box-shadow: 0 0 0 transparent;
            display: inline;
            text-align: left;
            padding: 0;
            margin: 0;
            cursor: pointer;
          }

          a.toolbutton {
            background-color: #EC7403;
            border-color: #B45A06;
            color: #FFF;
            border-radius: 4px;
            box-shadow: 1px 3px 0px #B45A06;
            display: block;
            text-align: center;
            padding: 10px 10px 10px 10px;
            margin: 10px 0px 5px 0px;
            cursor: pointer;
          }

          p {
            margin-bottom: 5px;
          }

          &.hidden {
            display: none;
          }
        }

        &.minMax {
          margin-left: auto;
          margin-right: auto;
          top: -150px;
          right: 0;
        }

        &:after {
          right: 15px;
          bottom: -15px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: transparent;
          border-top-color: #ffffff;
          border-width: 8px;
          margin-top: -8px;
        }
      }

      .star {
        color: #EC7403;
      }

      .dropdown {
        select.input-validation-error {
          border-color: #dc3135 !important;
        }

        &:after {
          margin-top: 8px !important;
        }
      }

      .message.start {
        margin: 5px 0px 10px;
      }

      #result_area {
        padding-top: 8px;
      }

      .message.hide,
      .information.hide {
        display: none;
      }
    }

    /*#data_calc.intent-calc .intent-calc__inputs p.question .tooltip {
  display: none;
  width: 250px;
  background-color: #FFFFFF;
  color: #0E2B69;
  font-size: 14px;
  line-height: 1.3;
  padding: 0.25em 1.25em 1.25em 1.25em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 4px 6px 17px #888888;
  left: auto;
  right: -14px;
  cursor: initial; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.instruct .tooltiphead {
    display: block;
    font-size: 18px;
    padding-bottom: 10px;
    padding-top: 10px; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.instruct a, #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.instruct span a {
    background-color: transparent;
    border-color: transparent;
    color: #89b6e2;
    border-radius: 0;
    box-shadow: 0 0 0 transparent;
    display: inline;
    text-align: left;
    padding: 0;
    margin: 0;
    cursor: pointer; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.instruct a.toolbutton {
    background-color: #EC7403;
    border-color: #B45A06;
    color: #FFF;
    border-radius: 4px;
    box-shadow: 1px 3px 0px #B45A06;
    display: block;
    text-align: center;
    padding: 10px 10px 10px 10px;
    margin: 10px 0px 5px 0px;
    cursor: pointer; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.instruct p {
    margin-bottom: 5px; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.instruct.hidden {
    display: none; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip.minMax {
    margin-left: auto;
    margin-right: auto;
    top: -150px;
    right: 0; }
  #data_calc.intent-calc .intent-calc__inputs p.question .tooltip:after {
    right: 15px;
    bottom: -15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #ffffff;
    border-width: 8px;
    margin-top: -8px; }*/

    #data_calc.intent-calc .load_btn img.loading {
      -moz-animation: rotating 0.7s linear infinite;
      -webkit-animation: rotating 0.7s linear infinite;
      -o-animation: rotating 0.7s linear infinite;
      animation: rotating 0.7s linear infinite;
    }

    #mortgage_calc {
      .dropdown-leg:after {
        margin-top: 10px;
      }

      .slide_wrap {
        margin-top: 20px;
      }

      .message.hide,
      .information.hide {
        display: none;
      }
    }

    #explore-calc {
      .intent-calc__inputs {
        *zoom: 1;
        width: 100%;

        &:before {
          content: " ";
          display: block !important;
        }

        &:after {
          content: " ";
          display: block !important;
          clear: both;
        }
      }

      .input-split {
        width: 26%;
        display: inline-block;
      }

      .dropdown {
        width: 70%;
        display: inline-block;
        float: right;
      }

      .input_section {
        float: left;
        *zoom: 1;
        width: 100%;

        &:before {
          content: " ";
          display: block !important;
        }

        &:after {
          content: " ";
          display: block !important;
          clear: both;
        }

        .partner_label {
          width: 50%;
          float: left;

          span {
            display: block;
          }
        }

        label {
          width: 50%;
          float: right;
          display: block;

          span.currency {
            &:before {
              width: 40%;
              font-size: 1.75em;
              font-weight: 200;
            }

            input {
              width: 60%;
            }
          }
        }
      }

      .input-split--half {
        width: 48%;
      }

      .expandTopaz_btn {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        border-radius: 3px;
        position: relative;
        background-image: url("/Calculators/img/icon-down-arrow.svg");
        background-image: url("/Calculators/img/btnArrowDown.jpg");
        background-size: 42px;
        background-repeat: no-repeat;
        height: 36px;
        width: 42px;
        position: absolute;
        top: -6px;
        right: 0px;
      }

      .expandable__topaz p {
        font-weight: 300;
      }
    }

    .intent-calc {

      .btn:after,
      .btn-sml:after {
        margin: 0;
      }
    }

    // REMOVED FOR THE NEW EFFECT ON THE BUTTONS
    #explore-calc .boxes-topaz__container {
      text-align: center;
      height: 50px;
      margin: 30px 0;
    }

    #explore-calc .arrow_box {
      padding: 10px 29px;
      margin: 0 5px;
      background-color: white;
      font-weight: bold;
      color: #82B7E3;
      cursor: pointer;
      position: relative;
      background: #ffffff;
      border: 3px solid #82B7E3;
    }

    #explore-calc .arrow_box.selected {
      border: 3px solid #F37300;
      color: #F37300;
    }

    #explore-calc .arrow_box:after,
    .arrow_box:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    #explore-calc .arrow_box:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 15px;
      margin-left: -15px;
    }

    #explore-calc .arrow_box:before {
      border-color: rgba(130, 183, 227, 0);
      border-top-color: #82B7E3;
      border-width: 20px;
      margin-left: -20px;
    }

    #explore-calc .arrow_box.selected:before {
      border-top-color: #F37300;
    }

    #explore-calc .expandable__topaz .input-split .currency--EUR {
      &:before {
        width: 40%;
        font-weight: 100;
        font-size: 25px;
      }

      input {
        width: 60%;
      }
    }

    p.subsection {
      font-weight: 300;
    }

    input[type=number] {

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
      }

      -moz-appearance: textfield;
      appearance: textfield;
      margin: 0;
    }

    .children {
      .children-input {
        text-align: center;
        width: 74px;
        float: left;

        @media screen and (max-width: 595px) {
          // width: 32%;
          margin: 0 15px 0 0;

          &:nth-last-child() {
            margin-right: 0px;
          }
        }

        .child-age {
          font-size: 14px;
        }

        .number {
          margin: 1px;
          width: 98% !important;
          background: #e9eef2;
          background: -moz-linear-gradient(top, #e9eef2 0%, #f5f8fa 100%);
          background: -webkit-linear-gradient(top, #e9eef2 0%, #f5f8fa 100%);
          background: linear-gradient(to bottom, #e9eef2 0%, #f5f8fa 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9eef2', endColorstr='#f5f8fa', GradientType=0);

          &:focus {}
        }
      }

      .ui-state-default {
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -o-border-radius: 0px;
        border-radius: 0px;
        height: 26px;
        width: 16px;
        margin-left: 0px;
        box-shadow: none;
        border: none;
        top: 3px;
        right: 3px;
        cursor: pointer;
        outline: none;
      }

      .ui-spinner-up {
        margin-top: 0;
      }

      .ui-spinner-down {
        margin-top: 20px;
      }

      .ui-state-default,
      .ui-widget-content .ui-state-default,
      .ui-widget-header .ui-state-default {
        border: 0px solid #d3d3d3;
        background-image: none;
        background: transparent;
        font-weight: 400;
        color: #000;
      }

      .ui-widget-content {
        border: none;
      }
    }

    .input--with-image {
      position: relative;

      &:before {
        content: '\f153';
        background-color: #A8B6C1;
        border-radius: 4px 0 0 4px;
        color: $white;
        font-family: 'fontawesome'; //font-weight: 700;
        height: 100%;
        left: 0;
        line-height: 50px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 40px;
        font-size: 19px;
        font-weight: normal;
      }

      input {
        border-radius: 3px;
        font-weight: 700;
        padding-left: 50px;
        width: 265px;

        @media screen and (max-width: 595px) {
          width: 100%;
        }
      }
    }

    .intent-calc__results {
      .btn {
        margin-right: 15px;
      }

      .btn-group--inline,
      .btn {
        width: calc(50% - 15px);

        @media screen and (max-width: 595px) {
          width: 100%;
        }

        input {
          width: 100%;
        }

        &:last-child {
          margin-right: 0;
          width: calc(50% - 5px);

          @media screen and (max-width: 595px) {
            width: 100%;
          }
        }
      }
    }

    .btn-arrange {
      width: calc(50% - 15px) !important;

      @media screen and (max-width: 595px) {
        width: 100% !important;
      }
    }

    .intent-calc {
      .load_btn {
        width: calc(50% - 15px);

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    // Removed as part of PMP-318
    // .app-form .hint a:after {
    //     @media screen and (max-width: 768px) {
    //         left: 165px;
    //     }
    // }

    .callBackHintSpan {
      .app-form .hint a:after {
        @media screen and (max-width: 768px) {
          left: auto;
        }
      }
    }

    .tooltip.instruct ul li {
      font-size: 14px;
    }

    // HANNAH'S STYLES
    .hidden {
      display: none !important;
    }

    .appear {
      display: block !important;
    }

    .hide {
      display: none !important;
    }

    input:read-only {
      /* opacity: 0.8; */
      color: rgba(128, 128, 128, 0.6);
    }

    /* Resposnisive styles */

    .payment-results-wrapper {
      @media screen and (max-width: 650px) {
        .btn-group--inline {
          margin-bottom: 0px !important;
        }

        .intent-calc .input {
          margin-bottom: 0px;
        }
      }
    }

    @include breakpoint(1109) {

      .inline.ampersand-wrapper,
      .ampersand {
        display: none !important;
      }

      .total,
      .equal {
        font-size: 3.5em !important;
      }

      .inline.total-wrapper,
      .inline.equal-wrapper {
        width: auto;
      }
    }
