.ptsb-calc .currency--EUR:before,
.ptsb-calc .currency--STG:before,
.ptsb-calc .currency--PCT:before {
    line-height: 30px;
}

.ptsb-calc .ico--wallet h2,
.ptsb-calc .ico--wallet h3,
.ptsb-calc .ico--wallet h4,
.ptsb-calc .ico--wallet h5 {
    background-size: 70px 70px;
}

.ptsb-calc .ico--wallet h4 {
    padding-left: 90px !important;
}