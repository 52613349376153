
.exit-popup {
  display: none !important;
  border-top: 6px solid $color-accent;
  border-radius: 5px;
  background-color: $white;
  display: block;
  position: fixed;
  height: auto !important;
  max-width: 40%;
  padding: 20px;
  z-index: 1000 !important;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.5);


  &.fade-in {
    display: block !important;
  }

  .btn {
    width: 150px;
    padding-top: 12px;
    border-radius: 2px;
  }

  h4 {
    line-height: 1.5;
    padding-left: 44px;
    position: relative;
    font-size: 27px;
    margin-bottom: 20px;
    font-weight: 700;

    &:before {
      content: "";
      background-image: url(/Content/img/icons/icon-information--orange.svg);
      background-size: 100% 100%;
      position: relative;
      display: inline-block;
      left: 0;
      position: absolute;
      top: 0;
      width: 36px;
      height: 36px;
    }
  }
  p{
    color: #4E4E4E;
  }
}

.btn {
  &.cancel-link {
    text-align: left;
    position: relative;

    &:after {
      content: url('../img/icons/icon-cancel--white.svg');
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }

  &.continue-btn {
    text-align: left;
    position: relative;

    &:after {
      content: url('../img/icons/icon-proceed--white.svg');
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }
}


//Wrapper to center align pop-up & apply background
.exit-popup-wrap {
  display: none !important;
  align-items: center;
  background-color: rgba(0,0,0,.75);
  justify-content: center;
  left: 0;
  position: fixed;
  height: 100%;
  top: 0;
  width: 100%;
  &.fade-in{
    display: flex !important;
  }
}
