
// Block: Application Step Block
.applicationstepblock {

  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(33,33,33,.1);
  font-family: museo-sans-rounded, sans-serif;
  margin: 60px 10px 25px 10px;
  position: relative;
  text-align: left;

  &:before {
    content: '';
    background-image: url('../img/orange-arrow.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 142px;
    left: 50%;
    position: absolute;
    top: -70px;
    transform: translateX(-50%);
    width: 179px;
  }

  &--content {
    color: #111;
    padding: 25px 22px;
    width: 100%;

    p { 
      color: $madison;
      font-size: 15px;
      font-weight: 700;
    }

    h5.icon {
      color: $mango-tango;
      &:before {
        display: block;
        height: 65px;
        left: 50%;
        top: -64px;
        transform: translateX(-50%);
        width: 65px;
      }
    }

    ul {
      padding-left: 20px;
    }

    li {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  @media all and (min-width: 769px) {
    &.col- {
      &4 { width: calc(33.333333% - 20px); }
    }
  }

  @media all and (min-width: 981px) {
    &.col- {
      &3 { 
        margin-left: 15px;
        margin-right: 15px;
        width: calc(25% - 30px); 
      }
    }
  }

  @include breakpoint(980) {
    &.col- {
      &3 { width: calc(50% - 20px); }
    }   
  }

  @include breakpoint(767) {
    &[class*='col-'] { width: calc(100% - 20px); }
  }

}