.breadcrumbs {
    @include respond-to(medium) {
        display: table;
        width: 100%;
        margin: 0 auto;
        .nav-horiz {
            padding-left: 0;
            float: none;
            margin-top: 0!important;
            margin-bottom: em(16px);
            display: none; // Hide breadcrumbs on tablet
        }
        .col-6:first-child {
            display: table-footer-group;
            float: none !important;
        }
        .col-6:last-child {
            display: table-header-group;
            float: none !important;
        }
        + hr {
            display: none;
        }
        
    }
    @include respond-to(small) {
        .btn-sml, .btn {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
        .btn-revchev::after{
            text-align: left !important;
        }
    }
}