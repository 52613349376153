    .form {
        input {
            &[type="text"] {
                @extend %input;
                width: 100%;
                padding: 5px 10px;
                margin-right: 5px;
                @include respond-to(medium) {
                    width: 70%;
                }
                
                
            }
            &[type="submit"] {
                border: none;
            }
            &:focus {
                outline: none !important;
                box-shadow: none;
            }
        }
        
    }
    
    .app-form {
        &.topbar__orange {
            display: block;
        }
        input[readonly] {
            background-color: #f6f6f6;
            color: #c2c8ce;
        }
        .toggled-input {
            border: 1px solid transparent;
        }
        .app-form__fieldset {
            h4 { display: block;}
            margin-bottom: em(70px);
            &:last-of-type {
                margin-bottom: em(40px);
            }
            &--reduce{
                margin-bottom: em(20px);
            }
        }
        div[class*=col-].center-block {
            float: none;
        }
        h4 {
            color: palette(orange);
            text-transform: uppercase;
            border-bottom: 2px solid palette(orange);
            padding-bottom: em(8px);
            margin-bottom: em(20px);
        }
        h5 {
            margin-bottom: em(20px);
        }
        label {
            color: palette(deep-blue);
            font-weight: $font-weight--medium;
            font-size: em(18px);
            display: block;
            margin-top: em(20px);
            margin-bottom: em(5px);
            font-weight: 700;
        }
        .radio-btns,
        .checkboxes {
            text-align: left;
            label {
                color: palette(grey);
                font-size: em(16px);
                margin-top: 0;
                margin-bottom: em(12px);
                font-weight: $font-weight--light;
                margin-left: 45px;
            }
                        border: 1px solid transparent;

        }
        .checkboxes {
            margin-top: em(15px);
            &__opt-in {
                margin-top: em(16px);
                @extend %clearfix;
                input, div {
                    float: left;
                }
                p {
                    float: left;
                    width: 80%;
                    font-size:  em(15px);
                    margin: -5px 0 0;
                }
            }
            &__important {
                border: 1px solid palette(steel);
                border-radius: 3px;
                padding: em(19px) em(16px) em(16px);
                margin: em(32px) 0;
                label {
                    margin-bottom: 0;
                    color: palette(orange);
                }
                .field-validation-error {
                    margin-left: 25px;
                    margin-top: 10px;
                    margin-bottom: 0;
                }
                   
            }
        }
        input[type=text],
        input[type=tel],
        input[type=number]{
            width: 100%;
            border-radius: 3px;
            border: 1px solid #D0DDE8;
            padding: 10px 15px;
            @include respond-to(x-small) {
               padding: 10px 5px; 
            }
        }
        
        .input {
            position: relative;
            &--long {
                max-width: 75%;
            }
            &--medium {
                max-width: 50%;
            }
            &--small {
                max-width: 30%;
                text-align: center;
                margin-right: 10px;
            }
            &--tiny {
                max-width: 20%;
                text-align: center;
                margin-right: 10px;
            }
            
        }

        
        .currency {
            @extend  %clearfix;
        
            &--EUR {
                &:before {
                    content: "\20AC";
                    @extend  %currency;
                    float: left;
                }
                input[type=text] {
                    border-radius: 0 3px 3px 0;
                    float: left;
                    min-height: 46px;
                }
            }
            &--STG {
                &:before {
                    content: "\00a3";
                    @extend  %currency;
                    float: left;
                }
                input[type=text] {
                    border-radius: 0 3px 3px 0;
                    float: left;
                    min-height: 46px;
                }
            }
        }
        .dropdown {
            display: block;
            width: 75%;
            border-radius: 3px;
            border: 1px solid #D0DDE8;
            background-color: #f8fafc;
            position: relative;
            background-image: url(../img/icons/icon-down-arrow.svg);
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: 95% center;
            overflow: hidden;
            @include respond-to(x-small) {
               width: 100%;
            }
            select {
                padding: 10px 15px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                border: none;
                outline: none;
                width: 115%;
                cursor: pointer;
            }
            select::-ms-expand {
                display: none;
            }
        }
        .hint {
            font-size: em(14px);
            color: palette(gull);
            margin-top: 7px;
            display: block;
            margin-bottom: 10px;
            position: relative;
            a {
                display: inline-block;
                position: relative;
                 padding-right: 25px;
                 color: palette(lt-blue);
            font-weight: $font-weight--light;
            @include respond-to(x-small) {
              position: static;
            }
                   
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    background: url('../img/info.svg') no-repeat 50% 50% palette(dk-blue);
                    right: 0;
                    width: 19px;
                    height: 19px;
                    top: 0;
                    border-radius: 50%;
                }
                 &:hover {
                color: palette(lt-blue, dark);
            }
            }
            &__tip {
                position: absolute;
                left: 100%;
                margin-left: 25px;
                width: 250px;
                background-color: palette(deep-blue);
                padding: em(20px);
                color: $white;
                line-height: 1.3;
                border-radius: 3px;
                top: -25px;
                display: none;
                    z-index: 1;
                 &:after {
                    right: 100%;
                    top: 35px;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color:  transparent;
                    border-right-color: palette(deep-blue);
                    border-width: 8px;
                    margin-top: -8px;
                }
                @media all and (max-width: 480px) { 
                    right: -10px;
                    left: auto;
                    top: auto;
                    bottom: 160%;
                    &:after {
                        right: 11px;
                        top: auto;
                        bottom: -15px;
                        border-right-color: transparent;
                        border-top-color: palette(deep-blue);
                    }
                }
                p {
                    margin: 0;
                }
            }
        }
        .btn-group {
            border-top: 2px solid #EC7403;
            padding-top: em(30px);
            margin-top: em(-30px);
        }
        .btn-split .btn{
            &:last-of-type {
                float: right;
                width: 47%;
            }
            &:first-of-type {
                float: left;
                width: 47%;
            }
        }

        span.field-validation-error {
            display: block;
            color: palette(red);
            font-size: em(15px);
            margin-bottom: em(4px);
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                background: url(../img/exclamation.svg) no-repeat 50% 50% palette(red);
                left: 0;
                width: 19px;
                height: 19px;
                top: 2px;
                border-radius: 50%;
                margin-left: -26px;
            }
        }
       
        .input-validation-error {
            border-color:  palette(red) !important;
        }
        .col-7 {
            @include respond-to(medium) {
                width: 60% !important;                
            }           
            @include respond-to(small) {
                width: 100% !important;                
            }
        }

    }
         .intro-text .app-form p {
            font-size: 16px;
        }
    
    .progress-bar {
        padding: 0 10px;
        margin-top: -25px;
    }

.form-progress {
  display: table;
  margin: 0 auto em(37px);
  padding: 0;
  border: 1px solid #cfdce7;
  border-radius: 5px;
  background: $white;

  li {
    font-size: 17px;
    display: table-cell;
    text-align: center;
    border-left: 1px solid #cfdce7;
    position: relative;
    padding: 10px 25px 9px 45px;
    color: palette(deep-blue);

    @include respond-to(x-small) {
      padding-left: 25px;
    }

    &:after {
      content: "";
      width: 32px;
      height: 32px;
      background: white;
      display: block;
      position: absolute;
      right: 0;
      -ms-transform: rotate(-45deg);
      /* IE 9 */
      -webkit-transform: rotate(-45deg);
      /* Chrome, Safari, Opera */
      transform: rotate(-45deg);
      top: 6px;
      right: -16px;
      z-index: 1;
      border-right: 1px solid #cfdce7;
      border-bottom: 1px solid #cfdce7;

      @include respond-to(x-small) {
        display: none;
      }
    }

    &:first-child {
      border-left: none;
      border-radius: 5px 0 0 5px;
      padding-left: 25px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;

      &:after {
        display: none;
      }
    }
  }

  .current {
    color: palette(orange);
    font-weight: $font-weight--bold;
    background: #fbfcfd; /* Old browsers */
    background: -moz-linear-gradient(top, #fbfcfd 0%, #ecf0f4 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fbfcfd), color-stop(100%,#ecf0f4)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* IE10+ */
    background: linear-gradient(to bottom, #fbfcfd 0%,#ecf0f4 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfd', endColorstr='#ecf0f4',GradientType=0 ); /* IE6-9
            */
    .form-progress__desc {
      @include respond-to(medium) {
        display: inline-block;
      }

      @include respond-to(small) {
        display: none;
      }
    }
  }

  .current:after, .complete:after {
    background: rgb(236,240,244);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZWNmMGY0IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmNmZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(45deg, rgba(236,240,244,1) 0%, rgba(251,252,253,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(236,240,244,1)), color-stop(100%,rgba(251,252,253,1)));
    background: -webkit-linear-gradient(45deg, rgba(236,240,244,1) 0%,rgba(251,252,253,1) 100%);
    background: -o-linear-gradient(45deg, rgba(236,240,244,1) 0%,rgba(251,252,253,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(236,240,244,1) 0%,rgba(251,252,253,1) 100%);
    background: linear-gradient(45deg, rgba(236,240,244,1) 0%,rgba(251,252,253,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecf0f4', endColorstr='#fbfcfd',GradientType=1 );
  }

  .complete {
    color: palette(green);
    font-weight: $font-weight--bold;
    background: #fbfcfd; /* Old browsers */
    background: -moz-linear-gradient(top, #fbfcfd 0%, #ecf0f4 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fbfcfd), color-stop(100%,#ecf0f4)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* IE10+ */
    background: linear-gradient(to bottom, #fbfcfd 0%,#ecf0f4 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfd', endColorstr='#ecf0f4',GradientType=0 ); /* IE6-9 */
    .form-progress__count {
      background-color: palette(green);
      text-indent: -9999px;
      width: 23px;
      display: inline-block;
      height: 23px;
      border-radius: 50%;
      background-image: url('../img/tick.svg');
      background-color: #91c73d;
      position: relative;
      top: 5px;
    }
  }

  &__desc {
    @include respond-to(medium) {
      display: none;
    }
  }
}
