* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


/* ===========================
ClearFix rule 
-for containers with floated child elements
=========================== */

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}


/* ===========================
Row rule 
- Negative Margin Row, for nesting columns within other columns preventing double margins.
=========================== */

.row {
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
}


/* ===========================
Setup Columns 
- Floating all columns left. 
=========================== */

div[class*=col-],
section[class*=col-],
li[class*=col-],
a[class*=col-] {
    float: left;
    position: relative;
    padding: 0px 10px;
}

div[class*=span-],
section[class*=span-],
li[class*=span-],
a[class*=span-] {
    float: left;
    position: relative;
    padding: 0px 10px;
}

.base {
    margin: auto;
    position: relative;
    width: 95%;
    max-width: 1170px;
    &--narrow {
            max-width: 672px;
        }
    @include respond-to(x-small) {
        max-width: 92%;
    } 
}

        

.col-12,
.span-12 {
    width: 100%;
}

.col-11,
.span-11 {
    width: 91.66666667%;
}

.col-10,
.span-10 {
    width: 83.33333333%;
}

.col-9,
.span-9 {
    width: 75%;
}

.col-8,
.span-8 {
    width: 66.66666667%;
}

.col-7,
.span-7 {
    width: 58.33333333%;
}

.col-6,
.span-6 {
    width: 50%;
}

.col-5,
.span-5 {
    width: 41.66666667%;
}

.col-4,
.span-4 {
    width: 33.33333333%;
}

.col-3,
.span-3 {
    width: 25%;
}

.col-2,
.span-2 {
    width: 16.66666667%;
}

.col-1,
span-1 {
    width: 8.33333333%;
}

.col-offset-12 {
  margin-left: 100%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-offset-6 {
  margin-left: 50%;
    @include respond-to(medium) {
        margin-left: 0;
    }
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
.col-offset-0 {
  margin-left: 0%;
}

@include respond-to(medium) {
    .col-1,
    .span-1,
    .col-2,
    .span-2,
    .col-3,
    .span-3,
    .col-4,
    .span-4,
    .col-5,
    .span-5,
    .col-6,
    .span-6,
    .col-7,
    .span-7,
    .col-8,
    .span-8,
    .col-9,
    .span-9,
    .col-10,
    .span-10,
    .col-11,
    .span-12,
    .col-12,
    .span-12 {
        width: 100%;
    }
}



.container-grey {
    .col-6 {
        @include respond-to(medium) {
            width: 100%;
        }
       
    }
    .col-3 {
        @include respond-to(medium) {
            width: 50%;
        }
        
    }
}

