header {
    position: relative;
    @include respond-to(small) {
        border-bottom: 2px solid palette(deep-blue);
    }
    .form {
        padding-top: 10px;
        float: right;
    }
    .btn,
    .search-txt {
        float: left;

    }
    .btn {
        font-family: FontAwesome;
    }
    input[type="text"].search-txt {
        width: 230px;
        @include transition ;
        @include respond-to(medium) {
            width: 0px;
            padding: 0 !important;
            border: 0;
        }
        &.expanded {
           width: 230px;
            padding: 10px !important;;
            border: 1px solid #cfcfcf;
            
        }
    }
    .logo-row {
        @include respond-to(large) {
            .col-6,
            .span-6 {
                width: 50%;
            }
        }
    }
    .site-header {
        display: table;
        height: 100%;
        width: 100%;
        &__cell {
            display: table-cell;
            height: 100%;
            vertical-align: middle;
             @include respond-to(small) {
                 /* even split header cells below 606px */
                 &:first-of-type, &:last-of-type {
                 width: 25%;
                 }
             }
            &--logo {
                text-align: center;
                img {
                    max-width: 170px;
                    width: 100%;
                    
                    @include respond-to(small) {
                        /* Center Logo below 606px */
                        margin: auto;
                        padding-right: 10px;
                    }
                }
            }
             &--logo-large {
                text-align: center;
                img {
                    max-width: 273px;
                    width: 100%;
                    
                    @include respond-to(small) {
                        /* Center Logo below 606px */
                        margin: auto;
                        padding-right: 10px;
                    }
                }
            }
            &--mob-nav-btn {
                display: none;
                @include respond-to(small) {
                    /* Hide Hamburger above 606px */
                    display: table-cell;
                }
            }
        }
    }
    .site-search,
    .mob-open24 {
        float: right;
    }
    .mob-open24 {
        float: right;
         @include respond-to(x-small) {
             a.btn-sml {
                 padding: 8px 10px 6px;
             }
         }
    }
    .site-search {
        @include respond-to(small) {
            /* Hide Site Search below 606px */
            display: none;
        }
        &.form {
            margin-left: 10px;
            padding-top: 0;
            input[type="text"] {
                padding: 10px;
                height: auto;
            }
        }
    }
    .mob-open24 {
        display: block;
        @include respond-to(large) {
            /* Hide Open 24 above 768px */
            display: block;
        }
    }
    .search-btn {
        padding: 13px 13px 14px;
        height: auto;
        color: $white;
    }
}

.header-type--narrow {
    border-bottom: 1px solid palette(gull, light);
    background: rgb(245,248,250);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y1ZjhmYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOWVlZjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(245,248,250,1) 0%, rgba(233,238,241,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(245,248,250,1)), color-stop(100%,rgba(233,238,241,1)));
    background: -webkit-linear-gradient(top,  rgba(245,248,250,1) 0%,rgba(233,238,241,1) 100%);
    background: -o-linear-gradient(top,  rgba(245,248,250,1) 0%,rgba(233,238,241,1) 100%);
    background: -ms-linear-gradient(top,  rgba(245,248,250,1) 0%,rgba(233,238,241,1) 100%);
    background: linear-gradient(to bottom,  rgba(245,248,250,1) 0%,rgba(233,238,241,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f8fa', endColorstr='#e9eef1',GradientType=0 );
    .logo-row {
        .btn-sml {
            margin-top: 10px;
        }
    }
    .narrow-logo {
         @include respond-to(small) {
             width: 70%;
             img {
                 margin-left: 10px;
             }
        }
    }
    .narrow-home-btn,
    .narrow-open24-btn {
        @include respond-to(medium) {
            width: 25%;
        }
    }

    .narrow-home-btn {
        @include respond-to(small) {

            .btn:after, .btn-sml:after {
                display: none;
            }
            .btn-revchev:after {
                display: block;
            }
        }
    }
    .narrow-open24-btn {
        @include respond-to(medium) {
            i {
                display: none;
            }
        }
        @include respond-to(small) {
            .login-txt {
                display: none;
            }
            i {
                display: block;
                margin: 2px 0;
            }
        }
    }
}

.mobile-open-24 {
  display: none;
  @include breakpoint(1024){
    display: block;
  }
}

.desktop-open-24 {
  @include breakpoint(1024) {
    display: none;
  }
}
