/* iCheck plugin Square skin, orange
----------------------------------- */
.icheckbox_square-orange,
.iradio_square-orange {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    background: url(../img/orange.png) no-repeat;
    border: none;
    cursor: pointer;
    margin-top: -5px;
    margin-right: 16px;
    margin-left: -45px;

    &.checked + span { color: $mango-tango; }
}

.icheckbox_square-orange {
    background-position: 0 0;
}
    .icheckbox_square-orange.hover {
        background-position: -32px 0;
    }
    .icheckbox_square-orange.checked {
        background-position: -64px 0;
    }
    .icheckbox_square-orange.disabled {
        background-position: -96px 0;
        cursor: default;
    }
    .icheckbox_square-orange.checked.disabled {
        background-position: -128px 0;
    }

.iradio_square-orange {
    background-position: -160px 0;
}
    .iradio_square-orange.hover {
        background-position: -192px 0;
    }
    .iradio_square-orange.checked {
        background-position: -224px 0;
    }
    .iradio_square-orange.disabled {
        background-position: -256px 0;
        cursor: default;
    }
    .iradio_square-orange.checked.disabled {
        background-position: -288px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_square-orange,
    .iradio_square-orange {
        background-image: url(../img/orange@2x.png);
        -webkit-background-size: 320px 30px;
        background-size: 320px 30px;
    }
}