.bookappointmentblock {
  margin-bottom: 20px;
  text-align: center;

  &--outer {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;

    @include breakpoint(767) {
      display: block;
      // margin-top: -200px;
    }
  }

  &--inner {
    background-color: #F7F8FA;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    overflow: hidden;
    padding: 70px 0 110px 0;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      background-image: url('../img/offers-block-image2.png');
      background-position: center;
      background-repeat: no-repeat;
      bottom: -160px;
      height: 462px;
      right: 0;
      position: absolute;
      width: 446px;
      z-index: -1;
    }

    .btn {
      margin: 25px 0;

      +p+h3 {
        color: grey;
      }
    }
  }

  &--focus {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 2px rgba(33, 33, 33, .1);
    display: inline-block;
    margin: 0 15px;
    min-height: 162px;
    position: relative;
    padding: 30px 45px;
    transform: translateY(-130px);
    vertical-align: top;
    width: 42%;
    z-index: 2;
    flex: 1;

    @include breakpoint(767) {}

    h5[class*=ico-] {

      >*,
      &:before {
        margin: 0 !important;
      }

      &:before {
        height: 54px;
        margin-bottom: 10px !important;
        width: 54px;
      }
    }
  }

  @include breakpoint(767) {
    background-color: #F7F8FA;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    margin-left: -10px;
    margin-right: -10px;
    overflow: hidden;
    padding: 40px 20px 20px 20px;
    position: relative;
    z-index: 1;

    &--inner {
      border: none;
      border-radius: 0;
      padding: 0;

      .btn {
        width: 100%;
      }
    }

    &--focus {
      transform: none;
      margin: 20px 0 0 0;
      min-height: 0;
      padding: 30px 30px 30px 20px;
      width: 100%;

      h5[class*=ico-] {
        padding-left: 90px;
        position: relative;
        text-align: left;

        &:before {
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-45%);
        }

        &:after {
          content: '';
          background-color: #CFDCE7;
          height: 100%;
          left: 70px;
          position: absolute;
          top: 0;
          width: 2px;
        }
      }

      h5.ico-search:before {
        height: 40px;
        width: 40px;
      }
    }
  }

  .or-text {
    font-size: 36px;
    font-weight: bold;
  }
}
