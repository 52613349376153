.cookie-policy {
    bottom: auto;
    position: relative;
    background: palette(deep-blue, dark);
    color: $white;
    width: 100%;
    padding: 5px 0 0;
    z-index: 5;
    @include respond-to(medium) {
        bottom:0px;
        position: fixed;
        div.col-9 {
            width: 70%;
            p {
                font-size: 11px;
                letter-spacing: 0px;
                line-height: 1;
                a {
                    color: palette(orange);
                    text-decoration: underline;
                }
            }
        }
        div.col-3 {
            width: 30%;
            float: right;
        }
    }
    .btn-sml {
        float: right;
        display: inline-block;
        margin-top: 5px;
    }
    @include respond-to(medium) {
        display: block;
    }
    &--mobile {
        display: none !important;
        @include respond-to(medium) {
            display: block !important;
        }
    }
    /* &--top {
        bottom: auto;
        position: relative;
        display: block;
        @include respond-to(medium) {
            display: none;
        }
    } */
    /* &--bottom {
        div.col-9 {
            width: 70%;
            p {
                font-size: 11px;
                letter-spacing: 0px;
                line-height: 1;
                a {
                    color: palette(orange);
                    text-decoration: underline;
                }
            }
        }
        div.col-3 {
            width: 30%;
            float: right;
        }
    } */
}