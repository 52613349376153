// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$ftr-bkg-name: 'ftr-bkg';
$ftr-bkg-x: 592px;
$ftr-bkg-y: 0px;
$ftr-bkg-offset-x: -592px;
$ftr-bkg-offset-y: 0px;
$ftr-bkg-width: 1px;
$ftr-bkg-height: 405px;
$ftr-bkg-total-width: 593px;
$ftr-bkg-total-height: 429px;
$ftr-bkg-image: '/Content/img/sprite/sprite-ptsb.png';
$ftr-bkg: (592px, 0px, -592px, 0px, 1px, 405px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'ftr-bkg', );
$icon-become-name: 'icon_become';
$icon-become-x: 477px;
$icon-become-y: 151px;
$icon-become-offset-x: -477px;
$icon-become-offset-y: -151px;
$icon-become-width: 53px;
$icon-become-height: 44px;
$icon-become-total-width: 593px;
$icon-become-total-height: 429px;
$icon-become-image: '/Content/img/sprite/sprite-ptsb.png';
$icon-become: (477px, 151px, -477px, -151px, 53px, 44px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'icon_become', );
$icon-help-name: 'icon_help';
$icon-help-x: 477px;
$icon-help-y: 96px;
$icon-help-offset-x: -477px;
$icon-help-offset-y: -96px;
$icon-help-width: 55px;
$icon-help-height: 55px;
$icon-help-total-width: 593px;
$icon-help-total-height: 429px;
$icon-help-image: '/Content/img/sprite/sprite-ptsb.png';
$icon-help: (477px, 96px, -477px, -96px, 55px, 55px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'icon_help', );
$icon-life-changes-name: 'icon_life-changes';
$icon-life-changes-x: 532px;
$icon-life-changes-y: 96px;
$icon-life-changes-offset-x: -532px;
$icon-life-changes-offset-y: -96px;
$icon-life-changes-width: 51px;
$icon-life-changes-height: 55px;
$icon-life-changes-total-width: 593px;
$icon-life-changes-total-height: 429px;
$icon-life-changes-image: '/Content/img/sprite/sprite-ptsb.png';
$icon-life-changes: (532px, 96px, -532px, -96px, 51px, 55px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'icon_life-changes', );
$nav-bkg-name: 'nav-bkg';
$nav-bkg-x: 583px;
$nav-bkg-y: 96px;
$nav-bkg-offset-x: -583px;
$nav-bkg-offset-y: -96px;
$nav-bkg-width: 1px;
$nav-bkg-height: 42px;
$nav-bkg-total-width: 593px;
$nav-bkg-total-height: 429px;
$nav-bkg-image: '/Content/img/sprite/sprite-ptsb.png';
$nav-bkg: (583px, 96px, -583px, -96px, 1px, 42px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'nav-bkg', );
$offers-block-image-name: 'offers-block-image';
$offers-block-image-x: 0px;
$offers-block-image-y: 0px;
$offers-block-image-offset-x: 0px;
$offers-block-image-offset-y: 0px;
$offers-block-image-width: 477px;
$offers-block-image-height: 429px;
$offers-block-image-total-width: 593px;
$offers-block-image-total-height: 429px;
$offers-block-image-image: '/Content/img/sprite/sprite-ptsb.png';
$offers-block-image: (0px, 0px, 0px, 0px, 477px, 429px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'offers-block-image', );
$twitter-bird-name: 'twitter-bird';
$twitter-bird-x: 477px;
$twitter-bird-y: 0px;
$twitter-bird-offset-x: -477px;
$twitter-bird-offset-y: 0px;
$twitter-bird-width: 115px;
$twitter-bird-height: 96px;
$twitter-bird-total-width: 593px;
$twitter-bird-total-height: 429px;
$twitter-bird-image: '/Content/img/sprite/sprite-ptsb.png';
$twitter-bird: (477px, 0px, -477px, 0px, 115px, 96px, 593px, 429px, '/Content/img/sprite/sprite-ptsb.png', 'twitter-bird', );
$spritesheet-width: 593px;
$spritesheet-height: 429px;
$spritesheet-image: '/Content/img/sprite/sprite-ptsb.png';
$spritesheet-sprites: ($ftr-bkg, $icon-become, $icon-help, $icon-life-changes, $nav-bkg, $offers-block-image, $twitter-bird, );
$spritesheet: (593px, 429px, '/Content/img/sprite/sprite-ptsb.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
