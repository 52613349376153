
// Block: Steps Block

$steps: 5;

.stepcontainerblock {

    &--header { font-size: 18px; }

    // Editorial block following the stepcontainer block
    + .editorialblock .box {
        border: none;
        margin-bottom: 30px;
        padding: 20px 0;      

        .warning--grey {
            border-color: black;
            color: black;
            margin: 0;
            padding: 15px;
            text-align: center;
        }
    }

    .buttons {
        margin-top: 17px;
    }

    @include breakpoint(767) {
        .btn { width: 100%; }
    }

}


.stepitemblock {
    background-color: #F7F8FA;
    border: 1px solid #D5DFE9;
    border-radius: 3px;
    margin-bottom: 25px;
    padding: 30px 15px;
    position: relative;
    @media all and (min-width: 769px) { padding: 45px; }

    &--last .stepitemblock--inner:before {
        background: black;
        background: linear-gradient(to bottom, #FCE3CD, transparent);
        height: 300px;
    }

    // The label for the circular number
    &:before {
        content: 'Step';
        color: $mango-tango;
        font-weight: 700;
        left: -100px;
        position: absolute;
        top: 52px;
        @include breakpoint(768) {
            display: block;
            left: 0;
            margin-bottom: 15px;
            position: relative;
            top: 0;
        }
    }

    // The circular number
    &:after {
        align-items: center;
        background-color: $mango-tango;
        border-radius: 50%;
        box-shadow: 0 0px 0px 5px $white;
        color: $white;
        display: flex;
        font-size: 20px;
        font-weight: 700;
        height: 38px;
        justify-content: center;
        left: -55px;
        position: absolute;
        top: 45px;
        width: 38px;
        @include breakpoint(768) {
            box-shadow: none;
            height: 28px;
            left: 55px;
            top: 28px;
            width: 28px;
        }
    }

    // For each instance of this block,
    // increase the number
    @for $i from 1 to $steps {
        &:nth-child(#{$i}):after {
            content: '#{$i}';
        }
    }

    ul,
    ol,
    p {
        &:last-of-type { margin-bottom: 0; }
    }

    // The step ribbon
    &--inner {
        position: relative;
        &:before {
            content: '';
            background: #FCE3CD;
            height: 200%;
            position: absolute;
            left: -83px;
            top: 45px;
            width: 3px;
            @include breakpoint(768) {
                content: none;
            }
        }
    }
}

.buttons {
    @include breakpoint(767) {
        .btn { width: 100%; }
    }
}