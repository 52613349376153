
// Block: Offers Block
.offersblock {
    border-radius: 3px;
    margin-bottom: 25px;
    overflow: hidden;

    > div {
        border: 1px solid #D5DFE9;
        border-radius: 3px;
        box-shadow: 0px 0px 5px 2px rgba(33,33,33,.1);
    }

    * { color: $white; }

    [class*="ico-"] {
        padding-bottom: 25px;
        > h4 {
            line-height: 1.2;
            margin: 0;
            padding-left: 50px;
            position: relative;
            &::before {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &--content {
        background: linear-gradient($color-primary, #4C7BA9);
        border-radius: 3px 3px 0 0;
        padding: 30px 25px;
        position: relative;
        &:before {
            content: '';
            background-image: url('../img/offers-block-image.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            bottom: 0;
            height: 255px;
            right: 0;
            position: absolute;
            width: 100%;
        }
    }

    .offer {
        border-top: 3px solid rgba(255,255,255,.25);
        color: $white;
        padding: 25px 0;

        ul { 
            margin: 10px 0 0 0; 
            li:not(:last-child) { margin-bottom: 10px; }
        }
    }

    .buttons {
        background-color: $white;
        border-radius: 0 0 3px 3px;
        padding: 30px 25px;
        text-align: center;
    }

    .btn {
        &:not(:last-child) { margin-bottom: 15px; }

        .col-3 &,
        .col-4 & { width: 100%; }
    }

}