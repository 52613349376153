.takeOver-wrapper{
    position: absolute;
    top: 0;
    left: 0;
   
    color: $white;
    z-index:1000;
    width: 100%;
    display: none;
    height: 100vh;
    @include breakpoint(1024){
        height: 100%;
        .base{
            width: 100%;
            top: -24px;
            max-width: none;
        }
    }
    &.show{
        display: block;
    }
    .takeOver-contain{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: none;
        @include breakpoint(1024){
            width: 95%;
            background-color: $white;
            width: 90%;
            background-color: #fff;
            border-radius: 10px;
            left: 0;
            right: 0;
            margin: auto;
            margin-top: 20px;
        }
        &.show{display: block;}
        .close{
            position: absolute;
            top: 84px;
            right: 20%;
            cursor: pointer;
            @include breakpoint(1024){
                top: 20px;
                right: 20px;
            }
        }
        .takeover-content{
            width: 350px;
            float: left;
            margin-top: 100px;
            @include breakpoint(1024){
                width: 90%;
                float: none;
                margin: 0 auto;
            }
        }
        .takeOver-logo{    
            width: 140px;
            margin-top: -35px;
            &-desktop{
                @include breakpoint(1024){
                    display: none;
                }
            }
            &-mobile{
                display: none;
                @include breakpoint(1024){
                    display: block;
                    margin-top: 0;
                    position: absolute;
                    top: 0;
                }
                @include breakpoint(600){
                    width: 100px;
                }
            }
        }
       .col-6{
           width: 50% !important;
            @include breakpoint(1024){
                width: 100% !important;
            }
        }
        .upload-image{
            display: block;
            width: 400px;
            float: right;
            margin-top: 60px;
            position: relative;
                 @include breakpoint(1024){
                    width: auto;
                    max-height: 500px;
                    float: none;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    bottom:-35px;
            }
            @include breakpoint(600){
                max-height: 400px;
            }
            @include breakpoint(500){
                max-height: 350px;
            }

        }
       
       .btn-white{
            box-shadow: none;
            border: none;
            color: $mango-tango;
            margin-top: 20px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            @include breakpoint(1024){
                background-color: $mango-tango;
                color: $white;
                margin-bottom: 20px;
                }
            }
            h1{
                color: $white;
                text-transform: uppercase;
                line-height: 1;
                font-size: 2.2rem;
                @include breakpoint(1024){
                    color: $black;
                }
            }
            .editorial-area{
                @include breakpoint(1024){
                    color: $black;
                }
            }
        }
        #bodymovin{
            @include breakpoint(1024){
                display: none !important;
            }
            > div{
               // overflow: visible !important;
                width: 100% !important;
                height: 100vh !important;
                position: absolute;
                top: 0px;
                left: 0px;
                display: block;
                transform-origin: 0px 0px 0px;
                backface-visibility: visible;
                transform-style: flat;
                overflow: hidden;
                transform: none !important;
                @include breakpoint-up(1500){
                    width: 130% !important;
                    overflow: visible !important;
                }
            }
        }
    }

    .takeOver-lock{
        position: relative;
    }

    .takeOver-overlay{
        background-color: rgba(0,0,0,0.8);
        height: 100%;
        display: none;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        position: absolute;
        &.active{
            display: block;
        }
    }


    .top-area{
        height: auto;
        display: block;
        
        margin-bottom: 50px;
        @include breakpoint(1024){
            background-image: url('../img/modal-pattern-only.png');
            background-repeat: no-repeat;
            background-position-y: bottom;
            background-position-x: center;
            background-size: cover;
        }
    }

    .takeOver-wrapper #bodymovin > div > div > div > div { display: table !important; }  


