.error-page {
    h1 {
        margin: 0;
                @include respond-to(medium) {
            text-align: center;
        }
    }
    h3 {
        line-height: 1.2;
        font-size: 21px;
        @include respond-to(medium) {
            text-align: center;
        }
    }
    .error-img {
        border-right: 2px solid palette(orange);
        padding-right: 34px;
        margin-top: 10px;
        @include respond-to(medium) {
            border-bottom: 2px solid #EC7403;border-right: 0;
            padding-right: 0;
            padding-bottom: 30px;
            margin: 10px auto 12px;
            display: block;
        }
    }
    .logo-img {
        margin: 60px 0 30px 3px;
        @include respond-to(medium) {
            margin: 60px auto 30px;
            display: block;
        }
    }
}

.maintain{
    width:50%;
    @include respond-to(large){
        width:90%;
    }
    margin:auto;
    text-align: center;
    .logo{
        width:250px;
        margin: auto;
        margin-bottom:50px;
        img{
            width:100%;
        }
    }
    ul{
        list-style: none;
        margin-top: 20px;
        padding-top:20px;
        border-top:1px dotted #ccc;
        margin-bottom: 20px;
        padding-bottom:20px;
        border-bottom:1px dotted #ccc;
        li{
            margin-bottom: 5px;
        }
    }
}

.dsp-table{
    display: table;
    width:100%;
    height:100%;
}

.dsp-cell{
    display: table-cell;
    height:100%;
    vertical-align: middle;
}