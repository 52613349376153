.branch-finder {
    &-map {
        position: relative;

        @include respond-to(medium) {
            margin-bottom: em(40px);
        }
    }

    &-listView {
        margin: em(40px) 0;
    }

    &-list {
        table {
            margin-bottom: em(24px);
        }

        table tbody tr {
            background-color: transparent;
        }

        table tbody td {
            padding: 2px 0;
            border: 0;
            @include respond-to(small){
                font-size: 16px;    
            }
            &:nth-child(odd) {
                font-weight: $font-weight--bold;
            }
        }

        &__address,
        &__contact,
        &__hours {
            float: left;

            p {
                margin-bottom: em(24px);
            }
        }

        &__address {
            padding-right: 20px;
            width: 20%;

            @include respond-to(large) {
                width: 50%;
            }

            @include respond-to(x-small) {
                width: 100%;
                float: none;
            }

            table tbody td:first-child {
                font-weight: $font-weight--light;
            }
        }

        &__contact {
            padding-right: 20px;
            width: 30%;

            @include respond-to(large) {
                width: 50%;
            }

            @include respond-to(x-small) {
                width: 100%;
            }
        }

        &__hours {
            width: 50%;
            @extend %clearfix;

            @include respond-to(large) {
                width: 100%;
            }

            .tbl-col {
                width: 50%;
                float: left;

                @include respond-to(small) {
                    width: 100%;
                    float: left;

                    table {
                        margin-bottom: 0;
                    }

                    td {
                        width: 50%;
                    }
                }
            }
        }

        &__branch {
            border-bottom: 1px solid palette(grey, xx-light);
            margin-bottom: em(24px);
            padding-bottom: em(24px);

            h6 {
                color: palette(orange);
                margin-bottom: em(8px);
            }

            .btn-group {
                text-align: right;
            }
        }

        h4 {
            border-bottom: 1px solid palette(grey, xx-light);
            margin-bottom: em(24px);
            padding-bottom: 16px;
        }

        &-nav {
            display: table;
            width: 33%;
            margin: 0;
            padding: 0;
            border: 1px solid #cfdce7;
            border-radius: 5px;
            position: relative;
            z-index: 1;

            @include respond-to(medium) {
                width: 100%;
            }

            li {
                font-size: 17px;
                display: table-cell;
                text-align: center;
                border-left: 1px solid #cfdce7;
                position: relative;

                &:first-child {
                    border-left: none;

                    a {
                        border-radius: 5px 0 0 5px;
                    }
                }

                &:last-child a {
                    border-radius: 0 5px 5px 0;
                }

                a {
                    display: block;
                    padding: 10px 0 9px;
                    color: palette(deep-blue);

                    .count {
                        font-size: em(14px);
                        color: palette(gull);
                    }
                }
            }

            .current a {
                color: palette(orange);
                font-weight: $font-weight--bold;
                background: #fbfcfd; /* Old browsers */
                background: -moz-linear-gradient(top, #fbfcfd 0%, #ecf0f4 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fbfcfd), color-stop(100%,#ecf0f4)); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top, #fbfcfd 0%,#ecf0f4 100%); /* IE10+ */
                background: linear-gradient(to bottom, #fbfcfd 0%,#ecf0f4 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfd', endColorstr='#ecf0f4',GradientType=0 ); /* IE6-9 */
                .count {
                    font-weight: $font-weight--light;
                }

                &:after,
                &:before {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba(236, 240, 244, 0);
                    border-top-color: #ecf0f4;
                    border-width: 16px 18px;
                    margin-left: -18px;
                }

                &:before {
                    border-color: rgba(207, 220, 231, 0);
                    border-top-color: #cfdce7;
                    border-width: 17px 20px;
                    margin-left: -20px;
                }
            }
        }

        .mob-expand {
            @extend %clearfix;

            &--title {
                color: palette(deep-blue);
                display: none;
                position: relative;

                @include respond-to(x-small) {
                    display: block;
                    cursor: pointer;
                    margin-bottom: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 1px;
                    bottom: 7px;
                    width: 40px;
                    height: 40px;
                    display: block;
                    background: url('/Content/img/icons/icon-down-arrow--deep-blue.svg') no-repeat 100% 100% transparent;
                    background-size: 15px 9px;
                    pointer-events: none;
                    margin-top: 30px;
                }
            }

            @include respond-to(x-small) {
                padding: 20px;
                background-color: palette(white, dark);
                border-radius: 3px;
                margin-bottom: 10px;
                border: 1px solid palette(white, x-dark);

                .branch-finder-list__contact,
                .branch-finder-list__hours {
                    display: none;
                }
            }

            &.expanded {

                h6 {
                    margin-bottom: 16px;

                    &::after {
                        background-image: url('/Content/img/icons/icon-up-arrow--deep-blue.svg');
                    }
                }
            }

            &.expanded .branch-finder-list__contact,
            &.expanded .branch-finder-list__hours {
                display: block;
            }

            &.expanded .branch-finder-list__hours p {
                margin-bottom: 0;
            }
        }
    }
}

.branch-finder-list-btns {
    @extend %clearfix;

    .btn {
        width: 48%;
        margin-right: 2%;
        float: left;

        &:last-child {
            margin-right: 0;
        }

        @include respond-to(x-small) {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .btn-group .btn-sml.btn-ghost--frameless {
        @include respond-to(x-small) {
            width: 100%;
        }
    }
}

.facility {
    background: $white;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #999;
    @extend %clearfix;

    button {
        background-color: transparent;
        padding: 15px 25px 15px 55px;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        color: palette(orange);
        float: left;

        &.active {
            background-color: palette(orange);
            color: white;

            svg {
                fill: #fff;
            }
        }

        &:first-of-type.active {
            border-radius: 5px 0 0 5px;
        }

        &:last-of-type.active {
            border-radius: 0 5px 5px 0;
        }

        svg {
            width: 26px;
            height: 24px;
            position: absolute;
            left: 20px;
            top: 13px;
            fill: palette(orange);
        }
    }
}

.branchInfo {
    hr {
        border-top: 0;
    }

    p {
        margin: 10px 0;
        font-size: 14px;
    }

    a {
        display: block;
        font-size: 15px;
        font-weight: 500;

        &:after {
            font-family: FontAwesome;
            float: right;
            content: "\f105";
            margin-left: 10px;
            margin-top: -1px;
        }
    }

    button[type="text"] {
        background-color: transparent;
        border: 0;
        padding-left: 0;
        color: palette(dk-blue, dark);

        &:hover {
            color: palette(dk-blue, x-dark);
        }
    }

    input.btn-sml.btn-dk-blue {
        border: 0;
    }

    h3 {
        font-size: 22px;
        line-height: 1.25;
    }

    h5 {
        font-size: 15px;
        line-height: 1.5;
    }
}

.search-branch {
    .form {
        margin-top: 10px;
    }

    input[type="text"] {
        min-height: 46px;
        float: left;
        background-color: #f8fafc;

        @include respond-to(medium) {
            width: 100%;
        }
    }

    .btn,
    .btn-sml {
        //float: right;
        margin-top: 20px;
    }


    @include respond-to(small) {
        display: block;
    }
}





.location-service{
    &:after{
        content:"\f05b";
        font-weight:normal;
    }
}

@include breakpoint(606px){
    .branch-finder-list{
        table tbody td{
            font-size: 16px;
        }
    }
}

.search-branch{
    hr{
        border: 0; 
        height: 1px; 
        background: #e7e7e7;
        margin-top:90px;
    }
    .location-service{
        padding-right: 35px !important;
    }
}