.product-details {
    left: 0;
    position: absolute;
    top: -177px;
    width: 100%;
    @include breakpoint(1092) {
        top: -217px;
    }
    @include breakpoint(767) {
        margin-bottom: -35px; // overflow: auto;
        top: -97px;
    }
    &--tabs {
        border: 1px solid #D5DFE9;
        border-radius: 3px;
        display: table;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%; // @include breakpoint(767) {
        //   border: none;
        //   margin: 0;
        //   padding-right: 60px;
        //   text-align: left;
        // }
        li {
            background: $white;
            display: table-cell;
            list-style: none;
            max-width: 0;
            padding: 15px;
            position: relative;
            width: 20%;
            @include breakpoint(767) {
                padding: 15px 1px;
            }
            &:not(:last-child) {
                border-right: 1px solid #D5DFE9;
            }
            &:not(.selected) h5 {
                font-weight: 300;
            }
            &.selected {
                background: linear-gradient(to bottom, #fff, #DCE1E7);
                &:after {
                    content: '';
                    background: $mango-tango;
                    bottom: 0;
                    height: 6px;
                    left: 10px;
                    position: absolute;
                    width: calc(100% - 25px);
                    @include breakpoint(767) {
                        .tabs-fixed & {
                            background: $color-primary;
                        }
                    }
                    background: transparent;
                    border-left: 40px solid transparent;
                    border-right: 40px solid transparent;
                    border-top: 40px solid #DCE1E7;
                    bottom: -40px;
                    height: auto;
                    left: calc(50% - 40px);
                    width: auto;
                    @include breakpoint(767) {
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-top: 15px solid #DCE1E7;
                        bottom: -15px;
                        height: auto;
                        left: calc(50% - 15px);
                    }
                }
                h5 {
                    color: $mango-tango;
                    @include breakpoint(767) {
                        font-size: 12px;
                        .tabs-fixed & {
                            color: $white !important;
                        }
                    }
                    &:before {
                        opacity: 1;
                    }
                }
            }
            .desktop-text:before {
                display: block;
                height: 40px;
                margin: 0 auto 8px auto;
                opacity: .5;
                width: 40px;
            }
        }
        .mobile-text {
            color: $madison;
            font-size: 12px;
            &:before {
                display: block;
                height: 27px;
                margin: 0 auto 8px auto;
                opacity: .5;
                width: 27px;
            }
            @media all and (min-width: 768px) {
                display: none;
            }
        }
    }
    +div {
        margin-top: 120px;
        @include breakpoint(1092) {
            margin-top: 140px;
        }
        @include breakpoint(767) {
            margin-top: 30px;
        }
    }
    &.tabs-fixed {
        left: 0;
        max-width: 1170px;
        pointer-events: all;
        position: fixed;
        top: 0;
        touch-action: manipulation;
        width: 100%;
        z-index: 500;
        @include breakpoint(767) {
            border: none;
            margin: 0;
            padding-right: 27px;
            text-align: left;
            background-color: #3b6597;
            padding-left: 27px;
        }
        @media all and (min-width: 768px) {
            left: 50%;
            transform: translateX(-50%);
        }
        .product-details--tabs {
            border: none;
            li {
                background: $white;
                display: table-cell;
                list-style: none;
                max-width: 0;
                padding: 15px;
                position: relative;
                @include breakpoint(767) {
                    background: transparent;
                    margin-right: 15px;
                    max-width: none;
                    padding: 15px 5px 15px 5px;
                    white-space: nowrap;
                    border: none;
                }
                h5 {
                    color: #89b6e2;
                    font-size: 12px;
                }
                &.selected {
                    &:after {
                        content: "";
                        background: #ec7403;
                        bottom: 10px;
                        height: 6px;
                        position: absolute;
                        width: 36px;
                        border-left: none;
                        border-right: none;
                        border-top: none;
                        margin: 0 auto 0px;
                        /* padding-right: 12px; */
                        max-width: 36px;
                        @include breakpoint(767) {
                            .tabs-fixed & {
                                background: $color-primary;
                            }
                        }
                        @media all and (min-width: 768px) {
                            background: transparent;
                            border-left: 40px solid transparent;
                            border-right: 40px solid transparent;
                            border-top: 40px solid #DCE1E7;
                            bottom: -40px;
                            height: auto;
                            left: calc(50% - 40px);
                            width: auto;
                        }
                    }
                }
            }
            .mobile-text {
                &:before {
                    display: block;
                    height: 27px;
                    margin: 0 auto 8px;
                    opacity: .5;
                    width: 27px;
                    visibility: hidden;
                    height: auto;
                }
            }
        }
    }
}

.clearfix {
    .product-details--sidebar {
        padding: 0;
    }
    .product-details--main {
        @media all and (min-width: 769px) {
            padding-right: 30px;
        }
        @media all and (min-width: 1280px) {
            padding-left: 50px;
            padding-right: 90px;
        }
    }
}

@include breakpoint(767) {
    .desktop-text {
        display: none;
    }
    .product-details--main {
        margin-top: 85px;
    }
}

.product-details--main {}