.search-guidance {
    .form {
        margin-top: 10px;
    }
    input[type="text"] {
        min-height: 46px;
        float: left;
        background-color: #f8fafc;
            //max-width: 410px; //removed to aid responsive on 'tablet guidence advice' page
        width: 74%; // for responsive version of guidence button
        @include respond-to(small) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .btn {
        float: right;
        width: 23%; // for responsive version of guidence button 
        text-align: center;
        padding-right: 20px;
        @include respond-to(small) {
            width: 100%;
        }
    }
}