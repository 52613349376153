.steps {
    padding: em(10px) 0;
    margin: 0 0 em(16px) 0;
    @extend %clearfix;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        width: 100%;
        background-color: #88b6e2;
        li {
            width: 33.333333%;
            float: left;
            padding: 1.25em 1.25em 1.25em 5.25em;
            position: relative;
            @include respond-to(small) {
                width: 100%;
            }
            &:before {
                display: none;
            }
            h5 {
                line-height: 1.4;
            }
            .lbl {
                font-family: $font-family--block;
                font-size: em(80px);
                font-weight: 900;
                position: absolute;
                left: 16px;
                top: -10px;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    &__1 {
        background-color: #dfeaf4;
        border-right: 2px solid $white;
        @include respond-to(small) {
            border-right: 0;
        }
        &:after {
            content: "";
            display: block;
            background-color: #dfeaf4;
            border-top: 2px solid #ffffff;
            border-right: 2px solid #ffffff;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            position: absolute;
            right: -9px;
            z-index: 1;
            top: 46%;
            @include respond-to(small) {
                display: none;
            }
        }
        p {
            color: palette(dk-blue, x-dark);
        }
        .lbl {
            color: #c4d8eb;
        }
    }
    &__2 {
        background-color: #c9dcef;
        border-right: 2px solid $white;
        @include respond-to(small) {
            border-right: 0;
        }
        &:after {
            content: "";
            display: block;
            background-color: #c9dcef;
            border-top: 2px solid #ffffff;
            border-right: 2px solid #ffffff;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            position: absolute;
            right: -9px;
            z-index: 1;
            top: 46%;
            @include respond-to(small) {
                display: none;
            }
        }
        p {
            color: palette(dk-blue, x-dark);
        }
        .lbl {
            color: #8ebae3;
        }
    }
    &__3 {
        background-color: #88b6e2;
        p {
            color: $white;
        }
        .lbl {
            color: #c9dcef;
        }
    }
    &-vertical {
        margin: 20px 0;
        ol {
            counter-reset: item;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        .counter:before {
            content: counter(item) "  ";
            counter-increment: item;
            display: inline-block;
            font-family: $font-family--block;
            font-size: em(80px);
            line-height: 75px;
            font-weight: 900;
            position: absolute;
            left: 10px;
            top: 30px;
            color: #b8d3ee;
        }
        li {
            display: block;
            color: palette(dk-blue);
            font-size: 20px;
            font-weight: 500;
            position: relative;
            padding-left: 80px;
            background-color: #82abd7;
            padding-top: 30px;
            padding-bottom: 30px;
            &:not(:last-child) {
                border-bottom: 2px solid $white;
            }
            span {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
            }
            a {
                display: none;
            }
            h5 {
                font-size: 19px;
            }
            p {
                font-size: 16px;
                margin-top: 5px;
                font-weight: 300;
                color: $white;
                padding-right: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &:after,
            &:before {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:after {
                border-color: rgba(130, 171, 215, 0);
                border-top-color: #82abd7;
                border-width: 15px;
                margin-left: -15px;
                z-index: 2;
            }
            &:before {
                border-color: rgba(255, 255, 255, 0);
                border-top-color: #ffffff;
                border-width: 18px;
                margin-left: -18px;
                z-index: 1;
            }
            &:last-child {
                &:before,
                &:after {
                    display: none;
                }
            }
            &:first-child {
                background-color: #e0eaf4;
                &:after {
                    border-top-color: #e0eaf4;
                }
                p {
                    color: palette(dk-blue, dark);
                }
                .counter:before {
                    color: #a5c3e2;
                }
            }
            &:nth-child(2) {
                background-color: #d2e1f0;
                &:after {
                    border-top-color: #d2e1f0;
                }
                p {
                    color: palette(dk-blue, dark);
                }
                .counter:before {
                    color: #a5c3e2;
                }
            }
            &:nth-child(3) {
                background-color: #bcd2e9;
                &:after {
                    border-top-color: #bcd2e9;
                }
                p {
                    color: palette(dk-blue, dark);
                }
                .counter:before {
                    color: #90b5dc;
                }
            }
            &:nth-child(4) {
                background-color: #a5c3e2;
                &:after {
                    border-top-color: #a5c3e2;
                }
                p {
                    color: $white
                }
                .counter:before {
                    color: #c7dcf1;
                }
            }
            &:nth-child(5) {
                background-color: #90b5dc;
                &:after {
                    border-top-color: #90b5dc;
                }
                p {
                    color: $white
                }
            }
        }
    }
}

body #TsAndCsInfo {
    text-align: left !important;
}