// Block: Mortgage Results Block
.mortgageresultsblock {
  margin-top: 60px;

  > h3 {
    margin-bottom: 10px;
  }

  &--criteria {
    border: 1px solid #D5DFE8;
    border-radius: 4px;
    color: $color-deep;
    font-size: 17px;
    margin-bottom: 25px;
    padding: 10px 15px;

    > div {
      display: inline-block;
    }

    &-item {
      margin-right: 30px;

      .mortgageresultsblock & p {
        margin: 0;

        span {
          color: $mango-tango;
          font-weight: 700;
        }
      }
    }
    ul {
        padding-left: 0;
        >li {
            list-style: none;
            background-image: url('../img/white-tick-list.png');
            background-position: left center;
            background-repeat: no-repeat;
            padding: 7px 0 5px 35px;
        }
    }
  }
}

// The table
.mortgage-results {
  border-collapse: separate;
  box-shadow: 0 0 5px 2px rgba(33,33,33,.1);
  text-align: left;

  th {
    border-bottom: 1px solid #dbdfe9;
    border-right: 1px solid $white;
    color: $mango-tango;
    font-size: 14px;
    padding: 30px 10px;
  }

  tbody {

    tr {
      &:nth-child(2n) {
        background: $white;
      }

      &:not(:first-of-type) td:nth-of-type(even):not(:last-child) {
        background: #f8f9fb;
      }
    }

    td {
      border: none;
      border-bottom: 1px solid #dbdfe9;
      padding: 15px 10px;
      max-width: 154px;
    }
  }

  td {
    color: $color-deep;
  }

  .row-selected > td > .moreInfoAnchor {
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .moreInfoRow {
    display: none;

    .mortgageratecalculatorblock & {
      background-color: $color-primary;
    }

    > td {
      padding: 30px;
      position: relative;

      > *:first-child {
        margin: 0;
      }

      > p {
        &:last-of-type:not(:only-of-type) {
          margin-bottom: 0;
        }
      }

      &:before {
        content: '';
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-top: 12px solid white;
        position: absolute;
        right: 66px;
        top: 0;
      }
    }

    > *,
    > td > * {
      color: $white;
    }
  }

  .row-selected {
    > td {
      background-color: $white !important;
      border-bottom: none;
      border-top: 2px solid $color-primary;

      &:first-child {
        border-left: 2px solid $color-primary;
      }

      &:last-child {
        border-right: 2px solid $color-primary;
      }
    }
  }
}

.moreInfoAnchor {
  color: $color-secondary;
  display: inline-block;
  margin-right: 10px;
  position: relative;

  &:before {
    @include arrow(left);
    left: calc(100% + 10px);
  }

  &:after {
    @include arrow(right);
    left: calc(100% + 14px);
  }

  &:before,
  &:after {
    background-color: $color-secondary;
    height: 8px;
    top: 8px;
    transition: .25s ease-in-out;
    width: 2px;
  }
}

.mortgage-results-desktop,
.mortgageresultsblock--criteria {
  @include breakpoint(768) {
    display: none !important;
  }
}

.mortgage-results-mobile {
  display: none;

  @include breakpoint(767) {
    display: block;
  }

  .mrm {
    border-radius: 4px;
    box-shadow: 0 0 5px 2px rgba(33,33,33,.1);
    margin-bottom: 20px;

    &-header {
      background-color: #F8F9FB;
      padding: 15px 45px 15px 15px;
      position: relative;

      .moreInfoAnchor {
        font-size: 0;
        position: absolute;
        right: 24px;
        top: calc(50% - 8px);

        &:before,
        &:after {
          height: 16px;
          top: 0;
        }

        &:before {
          left: 100%;
        }

        &:after {
          left: calc(100% + 11px);
        }

        &.toggle-active {
          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-columns {
      display: flex;
      flex-flow: row wrap;
      padding: 0 5px;

      > div {
        flex: 1;
        padding: 10px;

        &:not(:last-child) {
          border-right: 1px solid #F8F9FB;
          max-width: 25%;
        }

        &:last-child {
          text-align: right;
        }
      }

      @include breakpoint(500) {
        h5 {
          font-size: 15px;
        }
      }
    }

    &-moreinfo {
      background-color: $color-secondary;
      border-radius: 0 0 4px 4px;
      display: none;
      padding: 15px;

      > * {
        color: $white;
      }

      > *:first-child {
        margin: 0;
      }

      > p {
        &:last-of-type:not(:only-of-type) {
          margin-bottom: 0;
        }
      }

      .btn {
        width: 100%;
      }
    }
  }
}

    .small {
      color: $color-deep;
      font-size: 10px;
      font-weight: 700;
      height: 30px;
      line-height: 1;
      margin-bottom: 0;
    }  

@media only screen and (max-width: 768px) {
    .mortgage-results-desktop,
    .mortgageresultsblock--criteria {
        display: block;
    }
}

.mortgage-results-mobile {
    display: none;
    @include breakpoint(767) {
        display: block;
    }
    .mrm {
        border-radius: 4px;
        box-shadow: 0 0 5px 2px rgba(33, 33, 33, .1);
        margin-bottom: 20px;
        &-header {
            background-color: #F8F9FB;
            padding: 15px 45px 15px 15px;
            position: relative;
            .moreInfoAnchor {
                font-size: 0;
                position: absolute;
                right: 0px;
                top: calc(50% - 8px);
                height: 30px;
                width: 30px;
                background-color: transparent;
                &:before,
                &:after {
                    height: 16px;
                    top: 0;
                }
                &:before {
                    left: 6px;
                }
                &:after {
                    left: 17px;
                }
                &.toggle-active {
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        &-columns {
            display: flex;
            flex-flow: row wrap;
            padding: 0 5px;
            >div {
                flex: 1;
                padding: 10px;
                &:not(:last-child) {
                    border-right: 1px solid #F8F9FB;
                    max-width: 25%;
                }
                &:last-child {
                    text-align: right;
                }
            }
            @include breakpoint(500) {
                h5 {
                    font-size: 15px;
                }
            }
        }
        &-moreinfo {
            background-color: $color-secondary;
            border-radius: 0 0 4px 4px;
            display: none;
            padding: 15px;
            >* {
                color: $white;
            }
            >*:first-child {
                margin: 0;
            }
            >p {
                &:last-of-type:not(:only-of-type) {
                    margin-bottom: 0;
                }
            }
            .btn {
                width: 100%;
            }
        }
        .small {
            color: $color-deep;
            font-size: 10px;
            font-weight: 700;
            height: 30px;
            line-height: 1;
            margin-bottom: 0;
        }
  }
}