.btn-icons {
    &__icons {
        ul {
            margin: 0;
            padding: 0;
            @extend %clearfix;
        }
        li {
            list-style: none;
            float: left;
            width: 25%;
            text-align: center;
            padding: 0 !important;
            max-width: 130px;
            .col-3 & {
                @include respond-to(large) {
                    width: 50%;
                }
                @include respond-to(small) {
                    width: 25%;
                }
            }
            &:before {
                display: none !important;
            }
            img {
                display: block;
                float: none;
                margin: 0 auto;
                padding: 0 2px;
                max-width: 100%;
            }
            a {
                background: palette(deep-blue);
                display: block;
                border-radius: 3px;
                margin: 2px;
                padding: 5px 0;
                @include transition(background-color, 0.5s, ease-out);
                &:hover {
                    background: palette(deep-blue, dark);
                }
            }
            h5 {
                color: $white;
                font-size: 13px;
                font-weight: 300;
            }
        }
    }
}


/* 

.box ul.btn-icons {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        display: inline-block;
        text-align: center;
        text-indent: 0;
        padding-left: 0;
        margin-right: 5px;
        @include respond-to(small) {
            margin-right: 2px;
        }
        &:before {
            display: none;
        }
        &:last-child {
            margin-right: 0;
        }
        .col-3 & {
            float: left;
        }
        a {
            background: palette(deep-blue);
            display: block;
            @include border-radius(3px);
            @include transition(background-color, 0.5s, ease-out);
            padding: 10px 18px;
            margin-bottom: 10px;
            @include respond-to(large) {
                padding: 12px 10px 10px;
            }
            @include respond-to(large) {
                padding: 12px 5px 10px;
            }
            .col-4 &,
            .span-4 & {
                padding: 10px 12px;
            }
            .col-3 & {
                padding: 8px 2px;
                font-size: 12px;
                min-height: 65px;
            }
            &:hover {
                background: palette(deep-blue, dark);
            }
            h5 {
                color: $white;
                font-weight: $font-weight--medium;
                font-size: 15px;
                display: block;
                @include respond-to(small) {
                    font-weight: 300;
                    font-size: 12px;
                }
            }
        }
    }
    img {
        padding: 0;
        @include respond-to(small) {
            max-width: 48px;
        }
    }
}

.col-9,
.span-9,
.col-10,
.span-10,
.col-11,
.span-11,
.col-12,
.span-12 {
    .btn-icons__icons {
        float: right;
    }
    .btn-icons__info {
        float: left;
        width: 50%;
    }
} */

.title-bar {
    .nav-horiz {
        margin-top: em(10px);
    }
    h3 {
        line-height: 1.2;
    }
}