/* Ion.Calendar
// Version 2.0.1, build: 91
// © 2013 Denis Ineshin | IonDen.com
//
// Project page:    http://ionden.com/a/plugins/ion.calendar/
// GitHub page:     https://github.com/IonDen/ion.calendar
//
// Released under MIT licence:
// http://ionden.com/a/plugins/licence-en.html
// ===================================================================================================================*/

/* ic = ion.calendar prefix */
.ic__container,
.ic__week-head,
.ic__week-head td,
.ic__days,
.ic__days td,
.ic__header,
.ic__prev,
.ic__next,
.ic__datepicker {
    margin: 0; padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px; line-height: 13px;
    cursor: default;
    text-shadow: none !important;
}


.ic__container {
    position: relative;
    background: #fff;
}
    .ic__container table {
        width: 100%;
        border: 0;
        border-collapse: collapse !important;
        border-spacing: 0 !important;
    }


.ic__header {
    position: relative;
    background: #555;
    height: 35px;
}
    .ic__prev, .ic__next {
        position: absolute;
        top: 0;
        width: 7%; height: 35px;
        background-color: #555;
        cursor: pointer;
    }
    .ic__prev:hover, .ic__next:hover {
        background-color: #666;
    }
        .ic__prev div, .ic__next div {
            position: absolute;
            width: 0; height: 0;
            overflow: hidden;
            border: 5px solid transparent;
        }

    .ic__prev {
        left: 0;
    }
        .ic__prev div {
            top: 13px; left: 50%;
            margin-left: -7px;
            border-right-color: #fff;
        }
    .ic__next {
        right: 0;
    }
        .ic__next div {
            top: 13px; right: 50%;
            margin-right: -7px;
            border-left-color: #fff;
        }


    .ic__month {
        position: absolute;
        top: 6px; left: 9%;
        width: 50%;
    }
    .ic__year {
        position: absolute;
        top: 6px; right: 9%;
        width: 30%;
    }
    .ic__header select {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #000;
        padding: 2px;
        font-size: 14px;
        font-weight: bold;
    }
    .ic__header select:focus {
        outline: none;
    }



.ic__week-head td {
    width: 14%;
    vertical-align: top;
    text-align: center;
    padding: 10px 0 8px;

    border: 1px solid #ebebeb;
    color: #b5b5b5;
    font-size: 9px; line-height: 1.333;
    text-transform: uppercase;
}


.ic__days {
    margin-top: -1px;
}
    .ic__days td {
        width: 14%;
        vertical-align: top;
        text-align: center;
        padding: 10px 0 8px;

        border: 1px solid #ebebeb;
        color: #757575;
        font-size: 9px; line-height: 1.333;
        text-transform: uppercase;
    }
    .ic__days .ic__day {
        background: #fff;
        cursor: pointer;
    }
    .ic__days .ic__day:hover {
        background: #d35746;
        color: #fff;
    }
    .ic__days .ic__day-empty {
        background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAMAAAC6sdbXAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRF7u7u////KNOqmgAAABlJREFUeNpiYGRkZABCCGIA8UAEI4QACDAAAUoAFV5+ydgAAAAASUVORK5CYII=');
    }
    .ic__days .ic__day_state_current {
        background: #f4f4f4;
        color: #000;
    }
    .ic__days .ic__day_state_selected {
        background: #f4f4f4;
        color: #000;
    }


.ic__datepicker {
    position: absolute;
    top: -9999px; left: -9999px;
    width: 250px; padding: 10px;
    border-radius: 10px;
    background: #333;
    z-index: 9999;
}