.launch {
    &--app,
    &--android {
        border-bottom: 2px solid rgba(palette(deep-blue), 0.9);
        background: #eff4f8;
        padding: 20px;
        display: none;
        width: 100%;
        @include respond-to(small) {
            display: block;
        }
        .box,
        p,
        h5 {
            margin: 0;
        }
        .box {
            display: block;
            height: 100%;
            vertical-align: middle;
            position: relative;
            padding: 15px 20% 15px 15px;
            border-radius: 5px;
        }
        a:after {
            display: none;
        }
        .launch__close {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 20%;
            content: "Close";
            text-indent: -99999px;
            display: block;
            background: url('../img/icons/icon-cross--white.svg') no-repeat 50% 50% palette(deep-blue);
            max-width: 70px;
            border-radius: 0 5px 5px 0;
            cursor: pointer;
        }
        h5 {
            @include respond-to(x-small) {
                font-size: 16px;
            }
        }
        p {
            color: palette(deep-blue);
            font-weight: $font-weight--medium;
            padding-bottom: 0;
            &:after {
                border: 0;
            }
        }
        .solid--lt-blue:after {
            display: none;
        }
    }
    &--calc {
        padding: 20px;
        display: none;
        width: 100%;
        @include respond-to(small) {
            display: table;
        }
        .box,
        p,
        h5 {
            margin: 0;
        }
        .box {
            display: table-cell;
            height: 100%;
            vertical-align: middle;
            position: relative;
            padding: 15px 20% 15px 15px;
            border-radius: 5px;
            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 20%;
                content: "Close";
                text-indent: -99999px;
                display: block;
                background: url('../img/icon-right-arrow-white.svg') no-repeat 50% 50% transparent;
                max-width: 70px;
                border-radius: 0 5px 5px 0;
            }
        }
        h5 {
            @include respond-to(x-small) {
                font-size: 16px;
            }
        }
        p {
            font-weight: $font-weight--medium;
            padding-bottom: 0;
            &:after {
                border: 0;
            }
        }
    }
}

.launch__android__close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20%;
    content: "Close";
    text-indent: -99999px;
    display: block;
    background: url("../img/icons/icon-cross--white.svg") no-repeat 50% 50% #0E2B69;
    max-width: 70px;
    border-radius: 0 5px 5px 0;
    cursor:pointer;
}