nav {
  font-family: $font-family--secondary;
  border-top: 1px solid palette(steel);
  border-bottom: 3px solid palette(steel); //background: url('img/nav-bkg.gif') repeat-x top left #e9eef1;
  position: relative;
  z-index: 2;
  @include lgradient(180, #fff, #e9eef1);

  /*&:before{
        content:"";
        @include sprite($nav-bkg);
        position:absolute;
        top:0px; bottom:0px;
        background-repeat:repeat-x;
        background-size:100%;
    }*/
  &.horiz-nav {
    @include respond-to(small) {
      display: none;
    }
  }

  .horiz-nav__wrap>ul>li {
    text-align: center;

    @include respond-to(large) {
      font-size: 0.9em;
    }
  }

  ul {
    padding: 0;
    margin-top: -1px;
    margin-bottom: -3px;
    display: table;
    width: 100%;
    position: relative;
  }

  li {
    display: table-cell;
    font-size: em(16px);
    border-left: 1px solid palette(steel);

    @include respond-to(large) {
      &:nth-last-child(2) {
        border-right: 1px solid palette(steel);
      }
    }

    padding-top: 1px;
    border-top: 1px solid palette(steel);
    border-bottom: 3px solid palette(steel);
    @include transition;

    &:hover {
      background-color: #e2eaef;
      border-bottom-color: palette(orange);
      -moz-box-shadow: inset 0 0 3px #cecece;
      -webkit-box-shadow: inset 0 0 3px #cecece;
      box-shadow: inset 0 0 3px #cecece;

      a {
        color: palette(deep-blue, dark);
      }
    }

    &.active.parent {
      background-color: $white;
      border-bottom-color: $white;

      &:hover {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &.current-page {
      border-bottom: 3px solid $mango-tango;
    }

    a {
      text-decoration: none;
      color: palette(deep-blue);
      font-weight: $font-weight--medium;
      display: block;
      padding: 6px 8px 5px;
      line-height: 2;
    }

    &:last-of-type {
      border-right: 1px solid palette(steel);
    }
  }

  .parent>a:after {
    font-family: FontAwesome;
    content: "\f078";
    margin-left: em(16px);
    font-size: em(11px);
    color: palette(steel, dark);
    position: relative;
    top: -1px;
  }

  .action {
    border-left: 0;
    background-color: palette(orange);
    border-bottom: 3px solid palette(orange, x-dark);

    &:hover {
      background-color: palette(orange, light);
      border-bottom: 3px solid palette(orange, x-light);

      a {
        color: $white;
      }
    }

    a {
      color: $white;
    }

    .padlock-ico {
      @extend %padlock-ico;
    }

    @include respond-to(large) {
      display: none;
    }
  }

  .mega-dropdown {
    position: absolute;
    display: none;
    z-index: 3;
    background-color: white;
    left: 0;
    top: 48px;
    border-radius: 0 0 3px 3px;
    border-bottom: 6px solid palette(orange);
    color: palette(deep-blue);
    text-align: left;
    width: 100%;

    @include respond-to(large) {
      top: 44px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }

    &-cols {
      @extend %clearfix;
      padding: 20px 0;

      .ico {

        h6,
        ul,
        ul h6 {
          @include respond-to(large) {
            background: none;
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
    }

    .bulletin-bar {
      @extend %clearfix;
      border-top: 1px solid #d4d8dd;
      background: #e9ecf1;
      padding: 10px 0;
      display: table;
      height: 100%;
      width: 100%;

      div[class*=mm-col-] {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        float: none;
      }

      p {
        margin-bottom: 0;
        font-weight: 300;
        font-size: em(14px);

        strong {
          color: palette(lt-blue);
          font-weight: 600;
        }
      }

      .mm-col-bdr {
        border-color: #d4d8dd;
      }
    }

    h6 {
      a {
        font-weight: $font-weight--bold;
        padding-left: 0;
      }
    }

    p {
      a {
        display: inline-block;
        padding: 0;
        line-height: inherit;
      }
    }

    li {
      display: block;
      float: left;
      width: 100%;
      border: 0;

      &:hover {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
      }

      a {
        padding: 8px 8px 8px 0;
        color: palette(grey);
        font-size: em(15px);
        line-height: 1.4;

        &:hover {
          color: palette(grey, x-dark);
        }
      }
    }

    /* Mega Menu Columns  */
    .mm-col-bdr {
      border-right: 1px solid palette(grey, xx-light);
    }

    .mm-col-3 {
      width: 25%;
      padding: 0 30px !important;

      @include respond-to(large) {
        padding: 0 15px !important;
      }
    }

    .mm-col-4 {
      width: 33.33333%;
      padding: 0 30px !important;
    }

    .mm-col-5 {
      width: 41.66666%;
      padding: 0 30px !important;
    }

    .mm-col-6 {
      width: 50%;
      padding: 0 30px !important;
    }

    .mm-col-12 {
      width: 100%;
      padding: 0 30px !important;
    }
  }
}

.nav-blackout {
  background-color: rgba(0, 0, 0, 0.7); //background-color: rgba(14, 43, 105, 0.95);
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2;
  top: 47px;

  @include respond-to(large) {
    top: 44px;
  }

  bottom: 0;
  display: none;
}

.nav-horiz {
  float: right;
  margin: 0;
  font-size: em(15px);

  li {
    display: inline-block;

    a {
      display: block;
      color: palette(grey, light);
      margin-right: em(8px);
      padding-right: em(14px);
      border-right: 1px solid palette(grey, xx-light);
    }

    &:last-child a {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }

    &.current a {
      color: palette(orange);
      font-weight: 600;
    }
  }
}

.side-nav {
  ul {
    font-family: $font-family--secondary;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-weight: $font-weight--medium;
  }

  li {
    &.current a {
      color: palette(orange);
      font-weight: $font-weight--bold;

      &:hover {
        color: palette(orange, x-dark);
      }
    }

    a {
      display: block;
      padding: 5px 0 10px;
      color: palette(grey);

      &:hover {
        color: palette(grey, x-dark);
      }
    }
  }
}

.mobile-nav {
  visibility: hidden;

  @include respond-to(small) {
    visibility: visible;
  }

  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: rgba(palette(deep-blue), 0.95);
  background-position: 0;
  background-image: none;

  &__search {
    padding: 0 10px;
    position: relative;
    z-index: 2;

    input {
      text-align: center;
      width: 100%;
      margin: 10px auto;
      display: block;
      border: 0;
      padding: 10px;
      font-size: 18px;
      background-color: #fff;
      border-radius: 3px;
    }

    ::-webkit-input-placeholder::before {
      font-family: fontAwesome;
      content: '\f002';
      padding-right: 10px;
    }

    ::-moz-placeholder::before {
      font-family: fontAwesome;
      content: '\f002';
      padding-right: 10px;
    }

    /* firefox 19+ */
    :-ms-input-placeholder::before {
      font-family: fontAwesome;
      content: '\f002';
      padding-right: 10px;
    }

    /* ie */
    input:-moz-placeholder::before {
      font-family: fontAwesome;
      content: '\f002';
      padding-right: 10px;
    }
  }

  &__menu {
    border-top: 1px solid rgba(palette(gull, x-dark), 0.8);
    padding: 0 10px;
    display: block;
    position: relative;
    z-index: 2;

    li {
      display: block;
      border: 0;
      text-indent: 40px;

      &:hover {
        border: 0;
        box-shadow: none;
        background-color: transparent;

        a {
          color: $white;
          @include transition;

          &:hover {
            background-color: rgba(palette(gull, x-dark), 0.3);
          }
        }
      }

      &:nth-last-child(2) {
        border-right: 0;
      }

      &:last-of-type {
        border-right: 0;
      }

      a {
        color: $white;
        padding: 11px 8px 10px;
        position: relative;
      }

      &.parent {
        ul {
          display: none;
          margin-top: 1px;

          >li {
            background-color: rgba(palette(gull, x-dark), 0.3);
            border-bottom: 1px solid rgba(palette(deep-blue), 0.9);
            font-size: 0.95em;

            a:after {
              position: absolute;
              top: 8px;
              right: 10px;
              font-size: 1em;
              color: $white;
              content: "";
              width: 20px;
              height: 40px;
              background: url('../img/icon-right-arrow-white.svg') no-repeat right center transparent;
            }

            &.open>a {
              color: $cornflower;
            }

            &.open>a:after {
              background: url('../img/icon-up-arrow-white.svg') no-repeat right center transparent;
            }
          }
        }

        >a:after {
          position: absolute;
          top: 8px;
          right: 10px;
          font-size: 1em;
          color: $white;
          content: "";
          width: 20px;
          height: 40px;
          background: url('../img/icon-down-arrow-white.svg') no-repeat right center transparent;
        }

        &.open>a:after {
          background: url('../img/icon-up-arrow-white.svg') no-repeat right center transparent;
        }
      }
    }

    ul>li {
      border-bottom: 1px solid rgba(palette(gull, x-dark), 0.8);

      &:hover {
        border-bottom: 1px solid rgba(palette(gull, x-dark), 0.8);

        a:hover {
          background-color: transparent;
        }
      }
    }
  }

  .mob-blackout {
    background-color: rgba(14, 43, 105, 0.95);
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    display: none;
  }
}
