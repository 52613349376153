.img-btn-accordian {
    @include respond-to(small) {
        p,
        .img-btn-stack {
            display: none;
        }
        &.baseline--lt-grey:after {
            display: none;
        }
        &.box {
            border: 1px solid palette(grey, xx-light);
        }
        background: url('../img/icons/icon-down-arrow--deep-blue.svg') no-repeat 95% 35px transparent;
        &.accordian--open {
            background-image: url('../img/icons/icon-up-arrow--deep-blue.svg');
        }
        h3 {
            display: block;
        }
    }
}
.img-btn-stack {
    margin-bottom: em(32px);
    .img-block {
        float: left;
        width: 50%;
        padding-right: em(4px);
        @include respond-to(x-small) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 10px;
        }
        a {
            bottom: em(10px);
            left: em(10px);
            z-index: 2;
            font-size: em(26px);
            color: $white;
            width: 60%;
            line-height: 1.2;
            h5 {
                color: $white;
                font-size: 26px;
                font-weight: $font-weight--light;
                background: none;
                padding-left: 0;
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: 60%;
                z-index: 1;
                line-height: 1.2;
                 font-family: $font-family--primary ;
            }
        }
        .img-wrap {
            width: 100%;
            overflow: hidden;
            position: relative;
            border-radius: 3px;
            min-height: 228px;
            h1, h2, h3, h4, h5, h6 {
                &:before {
                    display: none;
                }
            }
            .scrim {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,011743+100&amp;0+0,0.8+100 */
                /* IE9 SVG, needs conditional override of 'filter' to 'none' */
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMTE3NDMiIHN0b3Atb3BhY2l0eT0iMC44Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
                background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(1,23,67,0.8) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(1,23,67,0.8))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(1,23,67,0.8) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(1,23,67,0.8) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(1,23,67,0.8) 100%); /* IE10+ */
                background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(1,23,67,0.8) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc011743',GradientType=0 ); /* IE6-8 */
            }
            img {
                z-index: -1;
                position: absolute;
                bottom: 0;
                left: 0;
                padding-bottom: 0;
            }
            &:after {
                font-family: FontAwesome;
                content: "\f105";
                margin-left: 10px;
                position: absolute;
                bottom: 0;
                right: 15px;
                font-size: 75px;
                line-height: 100px;
                color: $white;
                z-index: 1;
            }
        }
    }
    .btn-group {
        float: right;
        width: 50%;
        padding-left: em(4px);
        @include respond-to(x-small) {
            width: 100%;
            padding-left: 0;
        }
    }
    .btn {
        display: block;
        margin-right: 0;
        margin-bottom: em(8px);
        &:last-child {
            margin-bottom: 0;
        }
        &:after{
            top:auto;
        }
    }
}
.three-btns {
    .btn {
        padding: 24px 20px 22px;
    }
    &.img-btn-stack .img-block .img-wrap {
        min-height: 226px;
    }
}