.alert {
    display: table;
    height: 100%;
    width: 100%;
    position: relative;
    h4 {
        color: $color-accent;
        padding-right: 40px;
    }
    p {
        font-weight: $font-weight--medium;
        font-size: em(16px);
        margin: 0;
        padding-right: 50px;
    }
    .icon {
        display: table-cell;
        height: 100%;
        vertical-align: top;
        img{
            padding: 0 20px 0 0;
        }
    }
    .alert-close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: em(25px);
        @include transition(color, 0.2s, ease-out);
        i.fa {
            font-size: em(20px);
            color: palette(grey, x-light);
        }
        &:hover {
            i.fa {
                color: palette(grey);
            }
        }
    }
}

.thankyoupage__content-area .alertblock {
    position: relative;

    .blue-bkg {
        background: transparent;
        border: none;
    }

    .alert {
        border: 1px solid #e7e7e7;
        margin: 0 auto;
        max-width: 800px;

        .icon { width: 40px; }

        .alert-close { display: none !important; }
    }
}