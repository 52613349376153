@include breakpoint(767) {
    html,
    body {
        -webkit-overflow-scrolling: auto;
    }
}

body {
    font-size: $base__font-size;
    line-height: ($base__line/$base__font-size);
    font-family: $font-family--primary;
    color: palette(grey);
    font-weight: 100;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family--secondary;
    color: palette(deep-blue);
    a {
        color: palette(deep-blue);
    }
}


/*h1 {
    font-weight: $font-weight--light;
    font-size: em(42px);
    line-height: (46px / 42px);
    margin-bottom: em(70px, 42px);
  
} */

a {
    text-decoration: none;
    color: palette(dk-blue, dark);
    @include transition(color, 0.2s, ease-out);
    &:hover {
        color: palette(deep-blue, light);
    }
}

h1 {
    font-size: em(39px);
    .jumbo {
        font-size: em(48px);
    }
    @include respond-to(small) {
        font-size: em(35px);
    }
    @include respond-to(x-small) {
        font-size: em(32px);
    }
}

h2 {
    font-size: em(34px);
    margin: 0;
    @include respond-to(small) {
        font-size: em(31px);
    }
    @include respond-to(x-small) {
        font-size: em(28px);
    }
}

h3 {
    font-size: em(27px);
    @include respond-to(small) {
        font-size: em(24px);
    }
    @include respond-to(x-small) {
        font-size: em(22px);
    }
    line-height: 1.65;
    margin: 0;
    .base--narrow & {
        display: inline-block;
    }
}

h4 {
    font-size: em(23px);
    margin: 0;
    .col-3 & {
        margin: 6px 0 25px 0;
    }
    @include respond-to(small) {
        font-size: em(22px);
    }
    @include respond-to(x-small) {
        font-size: em(20px);
    }
}

h5 {
    font-size: em(19px);
    margin: 0;
}

h6 {
    font-size: em(16px);
    margin: 0;
}

p {
    font-size: em(16px);
    line-height: ($base__line/$base__font-size);
    margin: 0.5em 0 1em;
}

hr {
    border-color: transparent;
    background-color: palette(grey, xx-light);
    height: 0px;
    display: block;
    margin-top: 20px;
}

.overlay {
    margin-top: em(-48px);
    @include breakpoint(980) {
        margin-top: 15px;
    }
}

.grey-bkg {
    background: #eff4f8;
    border-color: palette(steel);
    border-style: solid;
    border-width: 1px 0;
}

.blue-bkg {
    background: #d8e2ed;
    border-color: palette(steel);
    border-style: solid;
    border-width: 1px 0;
}

.body-copy {
    h4 {
        line-height: 2;
        margin-bottom: 8px;
    }
    p {
        font-size: em(16px);
        word-wrap: break-word;
    }
    @extend %orange-bullets;
    .img {
        &--left {
            float: left;
            clear: both;
            margin: 5px 20px 15px 0;
        }
        &--right {
            float: right;
            clear: both;
            margin: 5px 0 15px 20px;
        }
    }
    .btn-group {
        margin-bottom: 15px;
    }
    iframe {
        width: 100% !important;
        height: 400px !important;
    }
    a.btn+a.btn {
        margin-top: 15px;
    }
}

ul {
    padding-left: 20px;
    .positive--list {
        list-style: none;
        padding-left: 0.5em;
        position: relative;
        &:before {
            content: "";
            background: url('../img/icons/icon-tick.svg') no-repeat left 0;
            display: block;
            position: absolute;
            width: 17px;
            height: 23px;
            left: -16px;
            top: 2px;
        }
    }
    .negative--list {
        list-style: none;
        padding-left: 0.5em;
        position: relative;
        &:before {
            content: "";
            background: url('../img/icons/icon-cross.svg') no-repeat left 0;
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            left: -17px;
            top: 2px;
        }
    }
}

ol {
    padding-left: 28px;
    list-style-type: decimal;
    ol {
        list-style-type: lower-alpha;
        margin: 5px 0 5px;
        ol {
            list-style-type: upper-roman;
            margin: 5px 0 5px;
        }
    }
}

.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

.pull-right {
    float: right !important;
}


/*
    Lists
    -----------
    The unpredictabliity of the HTML editor means
    that we wil have to repeat some styling here.
    This is to ensure that a user will get these
    working first time.
*/

// Styled as a li
ul {
    .positive--list-circle {
        list-style: none;
        position: relative;
    }
    .standard--list-orange {
        list-style: none;
        position: relative;
        &:before {
            content: '';
            background-color: $mango-tango;
            border-radius: 2px;
            height: 11px;
            left: -20px;
            position: absolute;
            top: 5px;
            width: 11px;
        }
    }
}

// Styled as a ul
@mixin standard-list($bgcolor) {
    list-style: none;
    margin-left: 5px;
    margin-bottom: 15px;
    position: relative;
    &:before {
        content: '';
        background-color: $bgcolor;
        border-radius: 2px;
        height: 11px;
        left: -25px;
        position: absolute;
        top: 5px;
        width: 11px;
    }
}

ul {
    &.standard--list-orange {
        li {
            @include standard-list($mango-tango);
        }
    }
    &.standard--list-white {
        li {
            @include standard-list($white);
            &:before {
                border-radius: 50%;
                height: 8px;
                top: 8px;
                width: 8px;
            }
        }
    }
    &.positive--list-circle {
        li {
            list-style: none;
            margin-left: 25px;
            margin-bottom: 25px;
            position: relative;
            &:before {
                content: '';
                background-image: url('../img/list-tick.png');
                border-radius: 2px;
                height: 35px;
                left: -45px;
                position: absolute;
                top: 2px;
                width: 35px;
            }
        }
    }
    &.positive--list-circle--blue {
        li {
            list-style: none;
            margin-left: 15px;
            margin-bottom: 10px;
            position: relative;
            &:before {
                content: '';
                background-image: url('../img/list-tick-blue.png');
                border-radius: 2px;
                height: 25px;
                left: -35px;
                position: absolute;
                top: 2px;
                width: 25px;
            }
        }
    }
}

.col-3,
.col-4 {
    .ctateaser,
    .editorialblock {
        .btn-lrg {
            width: 100%;
        }
        @include breakpoint(767) {
            .btn {
                width: 100%;
            }
        }
    }
}

.helpandguidanceblock a {
    text-decoration: underline;
}

// IE Dropwdown display
select::-ms-expand {
    display: none;
}