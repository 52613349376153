%box {
  @extend %orange-bullets;

  li {
    line-height: 1.75;
  }

  &.app-form li {
    font-size: 16px;
  }

  padding: 20px;
  border-radius: 3px;
  background-color: $white;

  p {
    margin-bottom: em(20px);

    /*
            Commented Out

            This is negatively impacting a lot of areas throughout
            the site.
        */

    // &:last-of-type {
    //     margin-bottom: 0;
    // }
  }

  &.box-stacked {
    margin-bottom: 20px;
  }

  img,
  .inset-vid {
    float: left;
    padding: 0 20px 20px 0;
  }

  @include respond-to(large) {
    margin-bottom: 20px;

    h2,
    h3,
    h4,
    h5 {
      display: inline-block;
    }

    .btn-group {
      max-width: 100%;
      margin: 0 auto;
    }

    &.unpadded,
    &.urgent-contact {
      border: none;
    }

    &.topbar__orange,
    &.topbar__orange--mobile {
      border-width: 6px 0 0 0;
    }
  }
}


.btn-group {

  .btn,
  .btn-sml {
    @include respond-to(x-small) {
      width: 100%;
      margin-bottom: 10px;
    }

    &.btn-ghost--frameless {
      @include respond-to(x-small) {
        width: auto;
      }
    }
  }
}

%topbar {
  border-top-color: palette(grey);
  border-top-style: solid;
  border-top-width: 6px;
  border-bottom-width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-top-width: 6px !important;
}

%baseline {
  &:after {
    border-bottom: 2px solid;
    display: block;
    margin-top: 20px;
  }
}

%solid {
  color: $white;
  position: relative;

  @include respond-to(large) {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: $white;
  }

  a {
    font-weight: 600;
    text-decoration: none;
    font-size: em(15px);
    display: block;

    @include respond-to(x-small) {
      font-weight: 300;
    }

    &:after {
      font-family: FontAwesome;
      float: right;
      content: "\f105";
      margin-left: 10px;
      margin-top: -1px;
      font-weight: 100;
      border: 1px solid $white;
      display: block;
      padding: 0px 8px 0px 9px;
      border-radius: 50%;

      @include respond-to(x-small) {
        display: none;
      }
    }
  }

  display: table;
  height: 100%;
  width: 100%;

  p {
    position: relative;
    margin-bottom: em(16px);
    padding-bottom: em(16px);

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 45%;
      /* or 100px */
      border-bottom: 2px solid;
    }

    @include respond-to(x-small) {
      display: none;
    }
  }

  .icon {
    display: table-cell;
    height: 100%;
    vertical-align: middle;

    img {
      margin-right: em(20px);

      @include respond-to(x-small) {
        padding: 0;
      }
    }

    a:after {
      display: none;
    }

    @include respond-to(large) {
      display: none;
    }
  }

  @include respond-to(x-small) {
    &:after {
      font-family: Fontawesome;
      content: "\f105";
      position: absolute;
      right: 20px;
      font-size: 25px;
      top: 28px;
    }
  }
}

%split-btn {
  &:last-of-type {
    float: right;
    width: 47.5%;
  }

  &:first-of-type {
    float: left;
    width: 48%;
  }
}

.shadow {
  @include box-shadow();

  &--feint {
    @include box-shadow(0, 0, 6px, $color: rgba(palette(grey), 0.3));
  }
}

.box {
  @extend %box;
  @extend %clearfix;

  @include respond-to(medium) {
    //border: 1px solid  // reqired for Tablet insurance landing on mobile
    border-width: 1px;
    border-style: solid;
    border-color: palette(grey, xx-light);
  }
}

.btn-split {
  .btn {
    @extend %split-btn;

    @include respond-to(x-small) {

      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.unpadded {
  padding: 0 0 em(16px) 0;
  position: relative;
  border-radius: 0;
  background: transparent;

  &--baseline {
    border-bottom: 2px solid palette(lt-blue, x-light);
    padding-bottom: 20px;
  }

  &--btn-right {

    h2,
    h3,
    h4,
    h5 {
      display: block;

      @include respond-to(large) {
        display: inline-block;
      }
    }

    p {
      float: left;
      width: 60%;
      margin-bottom: 0;

      @include respond-to(large) {
        width: 100%;
        float: none;
      }
    }

    .btn,
    .btn-sml {
      position: absolute;
      right: 0;
      bottom: em(34px);
      width: 35%;

      @include respond-to(large) {
        position: static;
        margin-top: 10px;
      }

      @include respond-to(x-small) {
        width: 100%;
      }
    }
  }

  .banner__blue & {

    h3,
    p {
      color: $white;
    }
  }

  @include respond-to(medium) {
    padding: 20px;

    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline-block;
    }
  }
}

.solid {
  &--deep-blue {
    @extend %solid;
    background-color: palette(deep-blue);

    p:after {
      border-color: palette(deep-blue, x-light);
    }
  }

  &--orange {
    @extend %solid;
    background-color: palette(orange);

    p:after {
      border-color: palette(orange, dark);
    }
  }

  &--lt-blue {
    @extend %solid;
    background-color: palette(lt-blue);

    p:after {
      border-color: palette(lt-blue, x-dark);
    }
  }

  &--dk-blue {
    @extend %solid;
    background-color: palette(dk-blue);

    p:after {
      border-color: palette(dk-blue, x-dark);
    }
  }
}

.baseline {
  &--deep-blue {
    @extend %baseline;

    &:after {
      border-color: palette(deep-blue, x-light);
    }
  }

  &--orange {
    @extend %baseline;

    &:after {
      border-color: palette(orange, x-light);
    }
  }

  &--lt-grey {
    @extend %baseline;

    &:after {
      border-color: palette(grey, xx-light);
    }
  }

  &--lt-blue {
    @extend %baseline;

    &:after {
      border-color: palette(lt-blue, x-light);
    }
  }
}

.box-2-cols {
  display: table;
  margin: -20px;

  &--aside {
    width: 30%;
    border-radius: 0 3px 3px 0;

    .btn,
    .btn-sml {
      display: block;
      margin: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--aside,
  &--main {
    display: table-cell;
    padding: 20px;

    @include respond-to(small) {
      display: block;
      width: 100%;
    }
  }

  &__va--middle {
    vertical-align: middle;
  }

  &__bg {
    &--dk-blue {
      background-color: palette(dk-blue);
    }

    &--lt-blue {
      background-color: #d7dfe6;
    }
  }

  hr {
    margin: 30px 0;
    border-bottom: 1px;
  }
}

.bkg-tint {
  &--light-grey {
    background: #f8fafc;
  }
}

.trans-bkg {
  &--orange {
    background: rgba(palette(orange), 0.7);

    @include respond-to(medium) {
      .box-bkg & {
        background: rgba(palette(orange), 0);
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      color: $white;
    }

    a:not(.btn):not(.btn-sml) {
      color: palette(deep-blue);

      &:hover {
        text-decoration: underline;
        color: palette(deep-blue, x-light);
      }
    }
  }

  &--lt-blue {
    background: rgba(palette(lt-blue), 0.7);

    @include respond-to(medium) {
      .box-bkg & {
        background: rgba(palette(lt-blue), 0);
      }
    }
  }

  &--dk-blue {
    background: rgba(palette(dk-blue), 0.7);

    @include respond-to(medium) {
      .box-bkg & {
        background: rgba(palette(dk-blue), 0);
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      color: $white;
    }

    a:not(.btn):not(.btn-sml) {
      color: palette(deep-blue);

      &:hover {
        text-decoration: underline;
        color: palette(deep-blue, x-light);
      }
    }
  }

  &--deep-blue {
    background: rgba(palette(deep-blue), 0.7);

    @include respond-to(medium) {
      .box-bkg & {
        background: rgba(palette(deep-blue), 0);
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      color: $white;
    }

    a:not(.btn):not(.btn-sml) {
      color: palette(orange, light);

      &:hover {
        text-decoration: underline;
        color: palette(orange, x-light);
      }
    }
  }

  &--white {
    background: rgba($white, 0.7);
    color: palette(grey, dark);

    @include respond-to(medium) {
      .box-bkg & {
        background: rgba($white, 0);
      }
    }
  }
}

.outline {
  border: 1px solid palette(grey, xx-light);
}

.topbar {
  &__orange {
    @extend %topbar;
    border-color: palette(orange) !important;
    display: block;

    @include respond-to(x-small) {
      display: none;
    }

    &.outline {
      border: 1px solid palette(grey, xx-light) !important;
      @extend %topbar;
      border-top-color: palette(orange);
      border-top-width: 6px;
    }

    &--light {
      @extend %topbar;
      border-color: palette(orange, light) !important;
    }

    &--x-light {
      @extend %topbar;
      border-color: palette(orange, x-light) !important;
    }

    &--xx-light {
      @extend %topbar;
      border-color: palette(orange, xx-light) !important;
    }

    h3 a {
      color: palette(deep-blue);
    }

    &--mobile {
      @extend %topbar;
      border-color: palette(orange);
      display: none;
      padding: 0;
      background: url('../img/icons/icon-right-arrow--orange.svg') no-repeat 95% 50% $white;

      @include respond-to(x-small) {
        display: block;
      }

      a {
        display: block;
        padding: 20px;
      }

      h3 {
        background-size: 35px !important;
        line-height: 1.1;
      }

      .lower-links {
        font-size: 14px;
        display: block;
        margin: 0;
        line-height: 1;
        font-weight: $font-weight--light;
        color: palette(grey);
        margin-top: 3px;
      }
    }
  }

  &__lt-blue {
    @extend %topbar;
    border-color: palette(lt-blue) !important;

    &.outline {
      border: 1px solid palette(grey, xx-light) !important;
      @extend %topbar;
      border-top-color: palette(lt-blue) !important;
      border-top-width: 6px;
    }
  }
}

.footnote {
  font-size: em(13px);
  color: palette(grey);
  font-weight: $font-weight--medium;
  border-top: 1px solid palette(grey, xx-light);
  margin-top: 24px;
  padding-top: 16px !important;
  padding-left: 0 !important;
  clear: both;
  background-image: none !important;

  a {
    font-weight: 600;
    color: palette(dk-blue);

    &:hover {
      color: palette(dk-blue, dark);
      text-decoration: underline;
    }
  }

  &:before {
    display: none !important;
  }
}

.box-bkg {
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;

  &__image {
    position: absolute;
    top: 0;
    right: 0;

    @include respond-to(medium) {
      display: none;
    }
  }

  &__image--tablet {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;

    @include respond-to(medium) {
      display: block;
    }
  }

  .box {
    margin: 20px 4px;
  }

  .col-7 {
    @include respond-to(large) {
      width: 75%;
    }

    @include respond-to(medium) {
      width: 100%;
      padding: 0;
      margin: 0;

      .box {
        margin: 0;
      }
    }
  }
}

// quick amend to ordering for print display
.amend-print-content {
  background: #d7dfe6;

  .amend-print-right {
    padding: 65px 15px;
    display: block;
    width: 100%;

    @media all and (min-width: 768px) {
      float: right;
      width: 25%;
    }
  }

  .amend-print-left {
    background-color: #f8fafc;
    display: block;

    @media all and (min-width: 768px) {
      float: left;
      width: 75%;
    }
  }
}

/*
    Commented Out

    This is negatively impacting a lot of areas throughout
    the site.
*/

// .box {
//     .btn-group {
//         margin-top: 25px;
//     }
// }
