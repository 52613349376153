.intent-to-lend-results {

    h1, h2, h3, h4, h5, h6 { font-family: $font-family--primary; }

    .intent-results-banner {
        min-height: 246px;
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        -webkit-print-color-adjust: exact;
        h1 {
            color: $white;
            margin: 85px 0 -20px 0;
            font-size: 82px;
            @media screen and (max-width: 692px) {
                margin: 85px 0 0 0;
                font-size: 2.1875em;
            }
        }
        h5 {
            color: $white;
            font-size: 24px;
            font-weight: normal;
        }
    }
    .print-email {
        background: $color-steel;
        margin-top: 50px;
        padding: 5px 0 5px 0;
        a {
            color: palette(deep-blue);
            font-size: 16px;
            font-weight: bold;
        }
        .ico--print {
            a {
                background: transparent url(/Content/img/icon-print-results.svg) no-repeat left top;
                filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Content/img/icon-print-results.svg', sizingMethod='scale');
                -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Content/img/icon-print-results.svg', sizingMethod='scale')";
                background-size: 20px 20px;
                min-height: 26px;
                padding-left: 27px;
                background-position-y: -1px;
                margin-right: 10px;
            }
        }
        .ico--email {
            a {
                background: transparent url(/Content/img/icon-email-results.svg) no-repeat left top;
                filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Content/img/icon-email-results.svg', sizingMethod='scale');
                -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Content/img/icon-email-results.svg', sizingMethod='scale')";
                background-size: 22px 22px;
                min-height: 22px;
                padding-left: 27px;
                background-position-y: -1px;
            }
        }
    }
    .pr-30 {
        padding-right: 30px;
        @media screen and (max-width: 980px) {
            padding: 0 10px;
        }
    }

    @for $i from 0 through 5 {
    $value: (100/10 * $i);
    .mb-#{$i} {
        margin-bottom: unquote($value + 'px') !important;
    }
}
    .pl-30 {
        padding-left: 30px;
        @media screen and (max-width: 980px) {
            padding-left: 10px;
        }
    }
    table {
        // &.tsb-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 30px;
        thead {
            th {
                text-align: left;
                width: 100%;
                h2 {
                    font-size: 28px;
                    color: palette(deep-blue);
                }
            }
            td {
                text-align: left;
                border: 0px;
                border-right: 0px solid #dbdfe9;
                border-bottom: 0px solid #6699cc;
                padding: 15px 0;
                background: $cornflower;
                color: $white;
                font-weight: $font-weight--bold;
                @include respond-to(small) {
                    font-size: 12px;
                    word-wrap: break-word;
                }
                &:first-child {
                    border-radius: 4px 0px 0px 0px;
                }
                &:last-child {
                    border-radius: 0px 4px 0px 0px;
                    border-right: 0px;
                }
            }
        }
        tbody {
            td {
                border-top: 1px solid #dbdfe9;
                padding: 10px 0px 10px 0px;
                border-left: none;
                border-right: none;
                font-weight: normal;
                color: palette(deep-blue);
                @include respond-to(small) {
                    font-size: 16px !important;
                    word-wrap: break-word;
                }
            }
            tr {
                &:first-child {
                    td {
                        padding-top: 40px;
                    }
                }
                &:nth-child(even) {
                    background: none;
                }
                td+td {
                    text-align: right;
                    border-right: none;
                    color: #ec7403;
                }
            }
        } // }
    }
    .cell-content {
        display: block; // width: 185px;
        @include respond-to(small) {
            width: 165px;
        }
    }
    .ico--house h4,
    .ico--about-you h4,
    .ico--children h4,
    {
        padding-left: 45px;
        line-height: 1.25;
        background-size: 50px;
        padding-top: 20px;
    }
    .ico--house {
        h4 {
            background: transparent url(/Content/img/icon-result-house.svg) no-repeat left top;
            filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Content/img/icon-house.svg', sizingMethod='scale');
            -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Content/img/icon-house.svg', sizingMethod='scale')";
            background-size: 35px 35px;
            min-height: 55px;
            background-position-y: 14px;
            -webkit-print-color-adjust: exact;
        }
    }
    .ico--about-you {
        h4 {
            background: transparent url(/Content/img/icon-result-about-you.svg) no-repeat left top;
            filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Content/img/icon-about-you.svg', sizingMethod='scale');
            -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Content/img/icon-about-you.svg', sizingMethod='scale')";
            background-size: 35px 60px;
            min-height: 55px;
            -webkit-print-color-adjust: exact;
        }
    }
    .ico--children {
        h4 {
            background: transparent url(/Content/img/icon-result-children.svg) no-repeat left top;
            filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Content/img/icon-children.svg', sizingMethod='scale');
            -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Content/img/icon-achildren.svg', sizingMethod='scale')";
            background-size: 35px 60px;
            min-height: 55px;
            -webkit-print-color-adjust: exact;
        }
    }
    .ico--borrow {
        h4 {
            padding-top: 20px;
            min-height: 55px;
        }
    }
    .ico--print {
        a {
            background: transparent url(/Content/img/icon-result-children.svg) no-repeat left top;
            filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/Content/img/icon-children.svg', sizingMethod='scale');
            -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Content/img/icon-achildren.svg', sizingMethod='scale')";
            background-size: 35px 60px;
            min-height: 55px;
        }
    }
    .additional-info-1 {
        .editorialblock {
            margin-top: 100px;
            p {
                color: palette(deep-blue);
            }
            ul {
                li {
                    color: $grey;
                    -webkit-print-color-adjust: exact;
                }
            }
        }
    }
    .additional-info-2-wrapper {
        background: #f9fafc;
        padding: 30px 0;
        .additional-info-2 {
            .box {
                background-color: transparent;
            }
            .editorialblock {
                p {
                    color: palette(deep-blue);
                }
                ul {
                    width: 50%;
                    float: left;
                    @media screen and (max-width: 980px) {
                        width: 100%;
                    }
                    li {
                        color: $grey;
                        -webkit-print-color-adjust: exact;
                    }
                }
            }
        }
    }
    .footer-info {
        background: #ddebf8;
        text-align: center;
        padding: 10px 0;
        p {
            color: palette(deep-blue);
            font-weight: bold;
            margin: 0;
        }
    }
}

/// PRINT STYLES

@media print {
    html, body {
        margin: 0 !important;
        padding: 0 !important;
    }
    .intent-to-lend-results .additional-info-1 .editorialblock {
        margin-top: 20px;
    }
    .intent-results-banner {
        min-height: 100px;
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        -webkit-print-color-adjust: economy;
        background-image: none !important;
        h1 {
            color: #000000 !important;
            margin: 0;
            font-size: 28px;
            width: 100%;
        }
        h5 {
            color: #000000 !important;
            font-size: 24px;
            font-weight: normal;
        }
    }
    .print-email {
        display: none;
    }
    .col-6,
    .span-6 {
        width: 50% !important;
    }
    td {
        font-size: 14px !important;
        width: 100%;
        .cell-content {
            display: block !important;
            width: 100% !important;
        }
    }
    .ico--house,
    .ico--about-you,
    .ico--children
    {
        background: none;
    }
    .ico--house h4,
    .ico--about-you h4,
    .ico--children h4
    {
        padding-left: 0px;
        line-height: 1.25;
        background-size: 50px;
        padding-top: 20px;
    }
}

.appointmentDetails {
    background-color: #DDEBF8;
    border-radius: 8px;
    padding: 30px;

    .appointmentDetails__content {
        font-size: 20px;
        font-weight: 600;
    }
}