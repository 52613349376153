
// Block: Detailed Icon List Block
.detailediconlistblock {
    border: 1px solid #D5DFE9;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 2px rgba(33,33,33,.1);
    margin-bottom: 25px;
    position: relative;

    > div p:last-of-type { margin-bottom: 0; }

    &--header {
        background: $white;
        border-radius: 3px 3px 0 0;
        font-size: 18px;
        padding: 30px 15px;
        @media all and (min-width: 768px) { padding: 45px; }
    }

    &--content {
        background: linear-gradient(180deg,#3b6597 40%,#062565);
        border-radius: 0 0 3px 3px;
        padding: 30px 15px;
        @media all and (min-width: 768px) { padding: 45px; }
        
        * { color: $white; }
    }

    .icon-with-content { 
        padding-bottom: 25px;
        position: relative;

        &:after {
            content: '';
            background-color: #6285AD;
            border-radius: 50px;
            bottom: 0;
            left: 0;
            height: 3px;
            position: absolute;
            width: 250px;
        }

        &:last-child,
        &:only-child { 
            padding-bottom: 0;
            &:after { content: none; }
        }

        &:not(:first-child) { padding-top: 25px; }

        h4 {
            line-height: 1.35;
            position: relative;
            padding-left: 75px;

            &:before {
                left: 0;
                height: 62px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 62px;
            }

            span {
                color: rgba(255, 255, 255, .875);
                display: block;
                font-size: 19px;
                font-weight: 300;
            }
        }
    }
}