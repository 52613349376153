
// Block: CTA Block with Image
.ctablockwithimage,
.productoptiondataitemblock {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 2px rgba(33,33,33,.1);
    display: table;
    font-size: initial;
    padding: 0 !important;

    &.col-12 {
        margin-left: 10px;
        width: calc(100% - 20px);

        .cta {
            &--image {
                border-radius: 3px 0 0 3px;
                @include breakpoint(767) {
                    border-radius: 3px 3px 0 0;
                }
            }

            &--content {
                border-radius: 0 3px 3px 0;
                .btn {
                    margin-bottom: 20px;
                }
                @include breakpoint(767) {
                    border-radius: 0 0 3px 3px;
                }
                @media (min-width: 981px) {
                    .btn {
                        min-width: 250px;
                    }
                }
            }
        }

    }

    .cta {

        // The image
        &--image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 3px 3px 0 0;
            display: table-cell;
            width: 50%;
        }

        // The content
        &--content {
            background-color: $white;
            border-radius: 0 0 3px 3px;
            display: table-cell;
            vertical-align: middle;
            width: 50%;

            @media all and (min-width:641px) { padding: 40px; }
            @media all and (min-width: 980px) { padding: 80px; }

            h2 {
                font-size: em(40px);
                padding-left: 50px;
                position: relative;

                &:before {
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                @include breakpoint(480) {
                    font-size: 28px;
                }
            }
        }

        // For mobile viewing
        @include breakpoint(767) {
            &--image {
                height: 280px;
            }

            &--image,
            &--content {
                display: block;
                width: 100%;
            }
        }

        @include breakpoint(640) {
            &--image {
                height: 140px;
            }

            &--content {
                padding: 20px;
            }
        }

    }

    // For multiple Call to Action blocks
    &[class*='col-']:not(.col-12) {
        display: block;
        margin: 0 10px;

        .cta {
            &--image {
                height: 140px;
            }

            &--content {
                padding: 20px;
                .btn-lrg {
                    width: 100%;
                }
            }

            &--image,
            &--content {
                display: block;
                width: 100%;
            }
            
        }

        // For mobile viewing
        @include breakpoint(767) {
            margin-bottom: 20px;
        }

    }

    @include breakpoint(767) {
        .btn { width: 100%; }
    }

}


// Two Call to Actions
.col-6.ctablockwithimage {
    width: calc(50% - 20px);

    @media all and (min-width: 768px) {
        .cta--image {
            height: 280px;
        }
    }

    @include breakpoint(640) {
        h2 { font-size: 20px; }
    }

    @include breakpoint(480) {
        margin-bottom: 20px;
        width: calc(100% - 20px);
    }
}

// Three Call to Actions
.col-4.ctablockwithimage {
    width: calc(33.33333333% - 20px);

    h2 { font-size: 20px; }

    @include breakpoint(767) {
        width: calc(50% - 20px);
    }

    @include breakpoint(480) {
        width: calc(100% - 20px);
    }
}

// Four Call to Actions
.col-3.ctablockwithimage {
    width: calc(25% - 20px);

    h2 { font-size: 20px; }

    @include breakpoint(920) {
        margin-bottom: 20px !important;
        width: calc(50% - 20px);
    }

    @include breakpoint(480) {
        width: calc(100% - 20px);
    }
}



.ctablockwithimage[class*='col-']:not(.col-12) {

    // Dirty code but it has to be done
    // due to important tags used elsewhere

    .btn {
        bottom: 20px;
        left: 15px;
        padding-left: 10px !important;
        padding-right: 10px !important;
        position: absolute;
        width: calc(100% - 30px) !important;
    }

    @include breakpoint(1249) {
        padding-bottom: 70px !important;
    }

    @media all and (min-width: 1250px) {
        padding-bottom: 50px !important;
    }
}