.faqaccordionblock {
    .row {
        margin: 0;
    }
    @include breakpoint(980) {
        margin-bottom: 20px;
    }
}

.engage-accordion {
    background-color: $white;
    border: 1px solid #D5DFE9;
    border-radius: 3px;
    font-size: initial;
    margin-bottom: 10px; // padding: 15px 45px;
    position: relative;
    width: 100%;
    @media (min-width: 981px) {
        max-width: calc(100% - 50px);
    } // The orange border found on the left of the accordion
    &:before {
        content: '';
        background-color: $mango-tango;
        border-radius: 0 15px 15px 0;
        height: calc(80% - 10px);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
    } // The wrapper
    &--wrapper {
        h4 {
            margin-bottom: 30px;
        }
    } // The header
    &--header {
        color: #505050;
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        padding-right: 30px;
        padding: 15px 30px;
        pointer-events: auto;

        @include breakpoint(768) {
            padding-right: 45px;
        }
    } // The spinning arrow
    &--arrow {
        position: absolute;
        right: 45px;
        top: 5px;
        &:before {
            @include arrow(left);
        }
        &:after {
            @include arrow(right);
        }

        @include breakpoint(768) {
            right: 25px;
        }
    } // The content (hidden by default)
    &--content {
        display: none;
        padding: 15px 30px;
    }
    &.accordion-selected {
        // The spinning arrow
        .engage-accordion--arrow {
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}